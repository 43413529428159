import { useCallback, useEffect, useState, useRef } from "react";
import { DroneWebSocket } from "../../class/Drone";
import { useDispatch, useSelector } from "react-redux";
import { setSocket, updateData } from "../../store/reducer/drone";
import { getDeliveryStatusAPI, getDroneListAPI, getEmergencyPointAPI, getOrderListAPI, getPackageListAPI, getLatestReport } from "../../api/skycook_apis";
import moment from "moment";
import { setDeliveryStatus, setOrderList, setForestFireReport, setFireReportWarning, setAccidentReportWarning } from "../../store/reducer/skycook";
import chimeBellFile from "../../audio/chime-bell.wav";
import FireReoprtVoice from "../../audio/fire_report_detect.mp3";
import AccidentReoprtVoice from "../../audio/accident_report_detect.mp3";

function ConnectServer({setSelectedDroneSN}) {
    const [chimeBell] = useState(new Audio(chimeBellFile));
    const [fireReportVoice] = useState(new Audio(FireReoprtVoice));
    const [accidentReoprtVoice] = useState(new Audio(AccidentReoprtVoice));

    const [timeOutStd, setTimeOutStd] = useState(false);
    const { vw, map, socket, orderList, forest_fire_report, delivery_status } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
        orderList: state.skycook.orderList,
        forest_fire_report: state.skycook.forest_fire_report,
        delivery_status:state.skycook.delivery_status,
    }));

    const { ws3d } = window;
    const { Cesium } = window;
    const dispatch = useDispatch();
    const prevReportTypeRef = useRef(null);

    // 지도 로딩 완료되었을 때 드론 연결
    vw.ws3dInitCallBack = function () {
        connectToMiddleServer();
    };

    const connectToMiddleServer = useCallback(() => {
        // let socket = new DroneWebSocket(`ws://192.168.2.48:8000/delivery_client`);
        let socket = new DroneWebSocket(`wss://${process.env.REACT_APP_API_URL_WEBSOCKET_SERVER}/3`);

        socket.onopen = () => {
            doSetBase(socket);
            dispatch(setSocket(socket));
            setTimeout(() => {
                doCameraMove(socket.getDronePosition());
            }, 1000);

            try {
                initSavedDrone(socket);
                getSafetyPoint(socket);
                getDeliveryStatus();
            } catch (e) {
                console.log(e);
            }
        };

        socket.addEventListener("message", function (event) {
            // updateData() 호출 시 T/F가 변경되며 데이터를 수신받았다는 스위치로 작동
            dispatch(updateData());
        });

        socket.onclose = () => {
            socket.setIsConnect(false);
            clearInterval(socket.getSocketInterval());
        };

        socket.onerror = (error) => {
            socket.setIsConnect(false);
            clearInterval(socket.getSocketInterval());
        };
    }, []);

    const initSavedDrone = useCallback(async (socket) => {
        try {
            const listRes = await getDroneListAPI();

            let drone_package = [];

            if (listRes.status !== 200) {
                throw new Error();
            } else {
                listRes.data.map(async (drone, index) => {
                    const res = await getPackageListAPI(drone.serial_number);
                    if (res.data !== "") {
                        const pack = res.data;
                        pack.launchTime = moment(res.data.launchTime).format('hh:mm A, MMM YY');
                        pack.estimatedArrivalTime = moment(res.data.estimatedArrivalTime).format('hh:mm A, MMM YY');
                        drone_package.push(pack);
                    }
                })
                socket.packages = drone_package;
            }
            socket.droneInfoList = listRes.data;
        } catch (e) {
            console.log(e);
        }
    }, [])

    const getSafetyPoint = useCallback(async (socket) => {
        try {
            const getRes = await getEmergencyPointAPI();
            if (getRes.status !== 200) {
                throw new Error();
            } else {
                socket.send(`20||${JSON.stringify(getRes.data)}`)
            }
        } catch (e) {
            console.log(e);
        }
    }, [])

    const getDeliveryStatus = useCallback(async () => {
        try {
            const getRes = await getDeliveryStatusAPI();

            if (getRes.status !== 200) {
                throw new Error();
            } else {
                if (getRes.data.status === "open") {
                    dispatch(setDeliveryStatus(true));
                } else {
                    dispatch(setDeliveryStatus(false));
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (delivery_status === true) {
                getOrderList();
            } else {
                getForestFireReport();
            }
        }, 10000)
    }, [timeOutStd, delivery_status])
    
    const getOrderList = useCallback(async () => {
        try {
            const getRes = await getOrderListAPI();
            if (getRes.status !== 200) {
                throw new Error();
            } else {
                let newOrderDetected = false;
                
                if (orderList.length === 0 && getRes.data.length > 0) {
                    newOrderDetected = true;
                } else {
                    getRes.data.map((new_order) => {
                        if (!newOrderDetected) {
                            orderList.map((prev_order, index) => {
                                if (prev_order.order_number === new_order.order_number) {
                                    return;
                                }
                                if (index === prev_order.length - 1) {
                                    newOrderDetected = true;
                                    return;
                                }
                            })
                        }
                    })
                }
                if (newOrderDetected) {
                    chimeBell.play();
                }

                dispatch(setOrderList(getRes.data));
                setTimeOutStd(!timeOutStd);
            }

        } catch (e) {
            console.log(e);
        }
    }, [forest_fire_report, timeOutStd])

    const formatPhoneNumber = (phoneNumber) => {
        // 숫자만 포함된 경우에만 작동
        if (/^\d{10,11}$/.test(phoneNumber)) {
          if (phoneNumber.length === 10) {
            // 10자리 번호 (예: 0212345678)
            return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
          } else if (phoneNumber.length === 11) {
            // 11자리 번호 (예: 01012345678)
            return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
          }
        }
        return phoneNumber; // 형식에 맞지 않으면 원래 값을 반환
    }

    const getForestFireReport = useCallback(async () => {
        try {
            const getRes = await getLatestReport();
            const { data } = getRes;
            
            // 기본 데이터 설정
            const reportData = {
                id: data.id,
                images: data.images,
                phone_number: formatPhoneNumber(data.phone_number),
                reporter_name: data.reporter_name,
                created_at: data.created_at,
                position: data.position,
                content_text: data.report_text,
                first_status_active: 0,
                second_status_active: 0,
                third_status_active: 0,
                title: data.report_type === "fire" ? "산불신고" : "산악신고",
                relief_supplies: data.relief_supplies,
                report_status: data.report_status,
            };

            // 상태에 따른 status_active 값 설정
            switch (data.report_status) {
                case 0:
                    reportData.first_status_active = 1;
                    if (prevReportTypeRef.current !== data.id) {
                        if (data.report_type === "fire") {
                            dispatch(setFireReportWarning(true));
                            fireReportVoice.play()
                        } else {
                            dispatch(setAccidentReportWarning(true));
                            accidentReoprtVoice.play();
                        }
                    }
                    break;
                case 1:
                    reportData.second_status_active = 1;
                    break;
                case 2:
                    reportData.third_status_active = 1;
                    break;
                default:
                    console.warn("Unexpected report_status:", data.report_status);
            }

            if (reportData.third_status_active !== 1) {
                dispatch(setForestFireReport(reportData));
            } else {
                dispatch(setForestFireReport({}));
                map.removeObjectById(`reporterPositionMaker`);

            }

            // 이전 report_type 저장
            prevReportTypeRef.current = data.id;

            // 타임아웃 상태 토글
            setTimeOutStd(prev => !prev);

        } catch (e) {
            console.error("Failed to fetch forest fire report:", e);
        }
    }, [dispatch, setTimeOutStd]);
    // 처음 연결 시 설정
    const doSetBase = (socket) => {
        socket.setIsConnect(true);
        socket.setMap(map);
        socket.setVw(vw);
    };

    useEffect(() => {
        if (!!socket) {
            clearInterval(socket.interval);

            if (socket.droneInfoList.length > 0) {
                let serial_number_list = "";
                socket.droneInfoList.map((droneInfo, index) => {
                    if (index === 0) {
                        serial_number_list = droneInfo.serial_number;
                    } else {
                        serial_number_list += `,${droneInfo.serial_number}`;
                    }
                })

                const interval = setInterval(() => {
                    socket.send(`00||${serial_number_list}`);
                }, 100);
                socket.setSocketInterval(interval);
            } else {
                const interval = setInterval(() => {
                    socket.send("00");
                }, 100);
                socket.setSocketInterval(interval);
            }
        }
    }, [socket.droneInfoList])

    ws3d.viewer.screenSpaceEventHandler.setInputAction(function (event) {
        var pickedObject = ws3d.viewer.scene.pick(event.position);
        if (socket.marker.length > 0) {
            socket.marker.map((data) => {
                // Check if the picked object is the marker entity
                if (Cesium.defined(pickedObject) && pickedObject.id === data.marker) {
                    socket.drone_serial_number = data.ip;
                    setSelectedDroneSN(data.ip);
                    // setSelectedDroneSN()
                    // Perform your desired actions when the marker is clicked
                    // You can access the marker's properties using the 'marker' variable
                }
            });
        }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    // 연결 시 카메라 이동
    const doCameraMove = (droneStatus) => {
        // 좌표가 (0.0, 0.0)이 아닐 때
        if (!!droneStatus) {
            if (droneStatus.longitude !== 0.0 && droneStatus.latitude !== 0.0) {
                let movePosition = new vw.CoordZ(droneStatus.longitude, droneStatus.latitude, 800);
                let cameraPosition = new vw.CameraPosition(movePosition, new vw.Direction(0, -90, 0));
                map.moveTo(cameraPosition);
            }
        }
    };

    return ;
}

export default ConnectServer;
