import React from 'react';
import styled from 'styled-components';

const S = {
    Wrap: styled.div`
        /* width: 263px; */
        width: ${props => props.width};
        max-height: 500px;
        overflow: scroll;
        position: fixed;
        left: 370px;
        bottom: 72px;
        background-color: #201f1f;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    `
}

function BottomMenuModal({ width = "263px", children}) {
    return (
        <S.Wrap width={width}>
            {children}
        </S.Wrap>
    );
}

export default BottomMenuModal;