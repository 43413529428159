import { getHeight } from "../../Function/Cesium";
import { doLand, setROI, setSpeed, setWaypoint } from "./MissionObject";

export const createMission = (missionData)=>{
  let missions = [];
  if(missionData.waypoint.length > 0){
    let copyArr = [...missionData.waypoint].reverse();
    if(missionData.speed==0) missions.push(setSpeed(0,3));
    else missions.push(setSpeed(0,missionData.speed));
    // if(missionData.roi!=0){
    //   missions.push(setROI(missionData.roi.latitude,missionData.roi.longitude,missionData.roi.altitude))
    // }
    if(missionData.reapet==0){
      for(let i=0; i<missionData.waypoint.length; i++){
        missions.push(setWaypoint(missionData.waypoint[i].latitude,missionData.waypoint[i].longitude,missionData.waypoint[i].altitude));
      }
    }else{
      for(let j=0;j<missionData.reapet;j++){
        for(let i=0; i<missionData.waypoint.length; i++){
          missions.push(setWaypoint(missionData.waypoint[i].latitude,missionData.waypoint[i].longitude,missionData.waypoint[i].altitude));
        }
        for(let i=0; i<copyArr.length; i++){
          missions.push(setWaypoint(copyArr[i].latitude,copyArr[i].longitude,copyArr[i].altitude));
        }
      }
    }
  }
  return JSON.stringify(missions);
}

export const createVwMarker3D = (vw, id, image, position, home_alt) => {
  let alt_calc = Number(home_alt) + Number(position.altitude) - getHeight(position.latitude, position.longitude)
  let ptCoord = new vw.CoordZ(position.longitude, position.latitude, alt_calc);
  let pt = new vw.geom.PointZ(ptCoord);
  pt.setId(id);
  pt.setImage(image);
  pt.create();
  return pt;
}

export const createVwMarker2D = (vw,id,image,position)=>{
  let ptCoord = new vw.Coord(position.longitude,position.latitude);
  let pt = new vw.geom.Point(ptCoord);
  pt.setId(id);
  pt.setImage(image);
  pt.create();
  return pt;
}

export const createVwMarker2DReporterName = (vw, id, image, position, label = "", fontSize = 12, fontWeight = 'normal', textAlign = 'center', color = '#000000') => {
  let ptCoord = new vw.Coord(position.longitude, position.latitude);
  let pt = new vw.geom.Point(ptCoord);
  pt.setId(id);
  pt.setImage(image);
  pt.setName(label);
  pt.setFontSize(fontSize);
  pt.create();
  return pt;
}

export const createVwMarker2DLabel = (vw,id,image,position,label = "")=>{
  let ptCoord = new vw.Coord(position.longitude,position.latitude);
  let pt = new vw.geom.Point(ptCoord);
  pt.setId(id);
  pt.setImage(image);
  pt.setName(label);
  pt.setFontSize(12);
  pt.create();
  return pt;
}

export const drawLine = (vw,datas,id)=>{
  let ar=[];
  for(let i=0; i<datas.length; i++){
    let point = new vw.Coord(datas[i].longitude,datas[i].latitude);
    ar.push(point);
  }
  if(ar.length>1){
    let coord = new vw.Collection(ar);
    let line = new vw.geom.LineString(coord);
    line.setId(id);
    line.setFillColor(vw.Color.RED);
    line.setWidth(1);
    line.create();
    return line
  }
}
/*
const points = [
  {latitude : 35.809855,longitude : 127.161174,altitude : 100},
  {latitude : 35.809889,longitude : 127.162687,altitude : 100},
  {latitude : 35.806702,longitude : 127.163398,altitude : 100},
  {latitude : 35.803845,longitude : 127.164823,altitude : 100},
  {latitude : 35.802215,longitude : 127.162814,altitude : 290},
  {latitude : 35.800628,longitude : 127.160851,altitude : 290},
];
*/
// const alt = 30;
export const testGoMission = (altitude)=>{
  const points = [
    {latitude : 35.809855,longitude : 127.161174,altitude : 100},
    {latitude : 35.809889,longitude : 127.162687,altitude : 100},
    {latitude : 35.806702,longitude : 127.163398,altitude : 100},
    {latitude : 35.803845,longitude : 127.164823,altitude : 100},
    {latitude : 35.802215,longitude : 127.162814,altitude : 290},
    {latitude : 35.800628,longitude : 127.160851,altitude : 290},
  ];
  let missions = [];

  for(let i=0;i<points.length;i++){
    if(i<4){
      missions.push(setWaypoint(points[i].latitude,points[i].longitude,altitude))
    }else{
      missions.push(setWaypoint(points[i].latitude,points[i].longitude,Number(altitude)+190))
    }
  }
  return JSON.stringify(missions);
}

export const testBackMission = (altitude)=>{
  const points = [
    {latitude : 35.809855,longitude : 127.161174},
    {latitude : 35.809889,longitude : 127.162687},
    {latitude : 35.806702,longitude : 127.163398},
    {latitude : 35.803845,longitude : 127.164823},
    {latitude : 35.802215,longitude : 127.162814},
    {latitude : 35.800628,longitude : 127.160851},
  ];
  let missions = [];
  let reverse = [...points].reverse();
  
  for(let i=0;i<reverse.length;i++){
    if(i<2){
      missions.push(setWaypoint(reverse[i].latitude,reverse[i].longitude,Number(altitude)+190))
    }else{
      missions.push(setWaypoint(reverse[i].latitude,reverse[i].longitude,(altitude)))
    }
  }

  missions.push(doLand());
  return JSON.stringify(missions);
}

export const testWarningMission = (droneLocation,points,alt)=>{
  let missions = [];
  let nearPoint={
    distance:0,
    point:{latitude:0,longitude:0},
  };
  
  for(let i=0;i<points.length;i++){
    let distance = get_distance_metres(droneLocation,points[i].point);
    if(nearPoint.distance>distance){
      nearPoint={
        distance:distance,
        point:points[i],
      }
    }
  }

  missions.push(setWaypoint(nearPoint.latitude,nearPoint.longitude,alt));
  missions.push(doLand());
  return JSON.stringify(missions);
}

export const get_distance_metres = (location1, location2) =>{
  let dlat = location2.latitude - location1.latitude
  let dlong = location2.longitude - location1.longitude
  return Math.sqrt((dlat*dlat) + (dlong*dlong)) * 1.113195e5
};