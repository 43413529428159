const SET_DELIVERY_STATION = 'skycook/DELIVREY_STATION';
const SET_STATION_LIST = 'skycook/STATION_LIST';
const SET_ORDER_LIST = 'skycook/ORDER_COUNT';
const SET_LOG_TIMER = 'skycook/LOG_TIMER';
const SET_WEBSOCKET = 'skycook/WEB_SOCKET';
const SET_DELIVERY_STATUS = 'skycook/DELIVERY_STATUS';
const SET_FOREST_FIRE_REPORT = 'skycook/FOREST_FIRE_REPORT';
const SET_FIRE_REPORT_WARNING = 'skycook/FIRE_REPORT_WARNING';
const SET_ACCIDENT_WARNING = 'skycook/ACCIDENT_WARNING';
const SET_DRONE_REPORT = 'skycook/DRONE_REPORT';

export const setDeliveryStation = station => ({ type: SET_DELIVERY_STATION, station });
export const setStationList = list => ({ type: SET_STATION_LIST, list });
export const setOrderList = list => ({ type: SET_ORDER_LIST, list });
export const setLogTimer = timer => ({ type: SET_LOG_TIMER, timer });
export const setWebsocket = websocket => ({ type: SET_WEBSOCKET, websocket });
export const setDeliveryStatus = status => ({ type: SET_DELIVERY_STATUS, status });
export const setForestFireReport = report => ({ type: SET_FOREST_FIRE_REPORT, report });
export const setFireReportWarning = fire_warning => ({ type: SET_FIRE_REPORT_WARNING, fire_warning });
export const setAccidentReportWarning = accident_warning => ({ type: SET_ACCIDENT_WARNING, accident_warning });
export const setDroneReport = drone_report => ({ type: SET_DRONE_REPORT, drone_report });

const initialState = {
    deliveryStation: "",
    stationList: [],
    orderList: [],
    logTimer: false,
    skycook_websocket: null,
    delivery_status: false,
    forest_fire_report: {},
    fire_report_warning: false,
    accident_report_warning:false,
    drone_report: {},
}

export default function drone(state=initialState, action){
  switch(action.type){
    case SET_DELIVERY_STATION:
      return {
            ...state,
            deliveryStation: action.station
        }
      case SET_STATION_LIST:
        return {
            ...state,
            stationList: action.list
        }
    case SET_ORDER_LIST:
        return {
          ...state,
          orderList: action.list
        }
      case SET_LOG_TIMER:
        return {
          ...state,
          logTimer: action.timer
        }
      case SET_WEBSOCKET:
        return {
          ...state,
          skycook_websocket: action.websocket,
        }
      case SET_DELIVERY_STATUS:
        return {
          ...state,
          delivery_status: action.status
        }
      case SET_FOREST_FIRE_REPORT:
        return {
          ...state,
          forest_fire_report: action.report
        }
      case SET_FIRE_REPORT_WARNING:
        return {
          ...state,
          fire_report_warning: action.fire_warning
        }
      case SET_ACCIDENT_WARNING:
        return {
          ...state,
          accident_report_warning: action.accident_warning
        }
      case SET_DRONE_REPORT:
        return {
          ...state,
          drone_report: action.drone_report
        }

      default:
        return state;
  }

}