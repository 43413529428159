import React, {useCallback, useState, useEffect} from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

import BlueCheckIcon from "../../../../gcs_image/common/check-icon.svg";
import CriticalIcon from "../../../../gcs_image/common/red-critical-icon.svg";
import { toast } from 'react-toastify';
import { orderPackageAPI } from '../../../../api/skycook_apis';

import { ReactComponent as NoticeIcon } from "../../../../gcs_image/common/notice-critical-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../gcs_image/common/check-circle-icon.svg";
import { updateReportStatus } from '../../../../api/skycook_apis';
const Spinner = keyframes`
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
`

const S = {
    Wrap: styled.div`
        display: flex;
        flex-direction: column;
        padding: 13px;
        background-color: #151515;

        .divide-line {
            width: 100%;
            height: 1px;
            background-color: rgba(73, 73, 73, 0.60);
        }
    `,
    Title: styled.p`
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 19px;
    `,
    OrderListWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-bottom: 18px;
    `,
    OrderWrap: styled.div`
        display: flex;
        align-items: center;

        .sub-title {
            width: 92px;
            font-size: 12px;
            line-height: 18px;
            color: #B5B5B5;
        }
        .order-number-wrap {
            width: 166px;
            height: 31px;
            background-color: #292828;
            border-bottom: 1px solid #004be9;
            display: flex;
            align-items: center;
            padding: 6.5px 8px 6.5px 11px;

            p {
                font-size: 14px;
                line-height: 18px;
                color: #fff;
            }
        }
        .order-number-forbid-wrap {
            width: 166px;
            height: 31px;
            background-color: #292828;
            border-bottom: 1px solid #F62A49;
            display: flex;
            align-items: center;
            padding: 6.5px 8px 6.5px 11px;

            p {
                font-size: 14px;
                line-height: 18px;
                color: #fff;
            }
        }
        
        img {
            margin-left: auto;
        }
    `,
    WeightWrap: styled.div`
        display: flex;
        align-items: center;
        padding: 10px 0;
        
        .weight-text {
            font-size: 12px;
            line-height: 18px;
            color: #b5b5b5;
        }
        .weight-value {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            margin-left: auto;
        }
        .weight-unit {
            font-size: 12px;
            font-weight: 300;
            color: #fff;
        }
    `,
    BtnWrap: styled.div`
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 8px;
        margin-top: 16px;

        .inactive-save-btn {
            width: 67px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #004be9;
            border: 1px solid #004be9;
            border-radius: 2px;
            opacity: 0.5;

            p {
                font-size: 12px;
                line-height: 20px;
                color: #fff;
            }
        }
    `,
    LoadingSpinnerWrap: styled.div`
        width: 100%;
        min-height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        p {
            font-size: 12px;
            font-weight: 16px;
            color: #B5B5B5;
        }
    `,
    LoadingSpinner: styled.div`
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 5px solid #292828;
        border-right: 5px solid #004BE9;
        animation: ${Spinner} 0.8s linear infinite;
    `,
}

function DronePackaging({ drone, selectedOrderList, setSelectedOrderList }) {
    const [isPackageLoading, setIsPackageLoading] = useState(false);
    const { socket, orderList, delivery_status, forest_fire_report } = useSelector((state) => ({
        socket: state.drone.socket,
        orderList: state.skycook.orderList,
        delivery_status: state.skycook.delivery_status,
        forest_fire_report:state.skycook.forest_fire_report
    }));

    const [totalWeight, setTotalWeight] = useState(0);
    
    const onClickCancel = useCallback(() => {
        setSelectedOrderList({
            droneSN: "",
            orderList: [],
        });
    }, [])

    useEffect(() => {
        setTotalWeight(totalWeight + selectedOrderList.orderList[selectedOrderList.orderList.length - 1].order_weight);
    }, [selectedOrderList])

    useEffect(() => {
        if (totalWeight > 3500 && totalWeight <= 5000) {
            // 자동 컨펌
            // sleep, Lottie
            onClickSave();
        }
        if (totalWeight > 5000) {
            toast.success(`A drone can be packaged up to 5kg.`, {
                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
            })
        }
    }, [totalWeight])

    const getPackageList = (orderList, selectedOrderList, deliveryStatus) => {
        const packageList = [];
        let lastIndex = -1;
    
        selectedOrderList.orderList.map((order) => {
            const index = orderList.findIndex((obj) => obj.order_number === order.order_number);
            packageList.push({
                order_number: order.order_number,
                order_product: deliveryStatus ? orderList[index].order_menu : "구호 물품",
                route_type: deliveryStatus ? -1 : order.route_type
            });
            lastIndex = index;
        });
    
        return { packageList, lastIndex };
    };
    
    const getOrderNumberList = (packageList) => {
        return packageList.map((order_package) => order_package.order_number);
    }
    
    const createDataObject = (droneSN, orderNumberList) => {
        return {
            serial_number: droneSN,
            order_number_list: orderNumberList,
        };
    }
    
    async function handlePackage(deliveryStatus, data, orderList, packageList, totalWeight, selectedOrderList, socket, index) {
        try {
            const packageRes = deliveryStatus ? await orderPackageAPI(data) : { status: 200 };
    
            if (packageRes.status !== 200) {
                throw new Error();
            } else {
                setTimeout(() => {
                    toast.success(`The Drone is packaged.`, {
                        icon: ({ theme, type }) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    });
    
                    const defaultStations = deliveryStatus ? {
                        startStation: orderList[index].store_station,
                        destination: orderList[index].pick_up_station
                    } : {
                        startStation: {
                            id: 1,
                            latlng: { lat: 35.800101, lng: 127.167857 },
                            name: "대성공영 주차장",
                            safe_altitude: "30",
                        },
                        destination: {
                            id: 2,
                            latlng: { lat: 35.800672, lng: 127.160845 },
                            name: "남고산 정상",
                            safe_altitude: "260",
                        }
                    };
    
                    // 내부 패키지 저장
                    socket.packages.push({
                        startStation: defaultStations.startStation,
                        destination: defaultStations.destination,
                        route: {},
                        launchTime: "",
                        is_delivery_complete: false,
                        is_emergency_active: false,
                        estimatedArrivalTime: "",
                        estimatedTakenTime: "-",
                        orderList: packageList,
                        totalWeight: (Math.round(totalWeight / 100) * 100) / 1000,
                        // orderTime: "03:55 PM, July 24",
                        droneSN: selectedOrderList.droneSN,
                    });
    
                    // 초기화
                    setSelectedOrderList({
                        droneSN: "",
                        orderList: [],
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const onClickSave = useCallback(async () => {
        setIsPackageLoading(true);

        let index;
        let data;
        let orderNumberList;

        if (delivery_status === true) {
            const { packageList, lastIndex } = getPackageList(orderList, selectedOrderList, true);
            orderNumberList = getOrderNumberList(packageList);
            data = createDataObject(selectedOrderList.droneSN, orderNumberList);
            handlePackage(true, data, orderList, packageList, totalWeight, selectedOrderList, socket, lastIndex);
        } else {
            const { packageList, lastIndex } = getPackageList(orderList, selectedOrderList, false);
            orderNumberList = getOrderNumberList(packageList);
            data = createDataObject(selectedOrderList.droneSN, orderNumberList);
            handlePackage(false, data, orderList, packageList, totalWeight, selectedOrderList, socket, lastIndex);
            changeReportStatus();
        }

    }, [socket, selectedOrderList, totalWeight, orderList])

    const changeReportStatus = useCallback(async () => {
        try {
            const changeRes = await updateReportStatus(forest_fire_report.id, 1);

            if (changeRes.status !== 200) {
                throw new Error();
            } 
        } catch (e) {
            console.log(e);
        }
    }, [socket, drone, forest_fire_report, delivery_status])

    return (
        <S.Wrap>
            {isPackageLoading ?
                <S.LoadingSpinnerWrap>
                    <S.LoadingSpinner />
                    <p>Loading Packaging...</p>
                </S.LoadingSpinnerWrap>
            :
                <>
                    <S.Title>Packaged this order</S.Title>
                    <S.OrderListWrap>
                        {selectedOrderList.orderList.map((order, index) => {
                            return (<S.OrderWrap key={`orderList${index}`}>
                                <p className="sub-title">Orders</p>
                                {totalWeight < 5000 ?
                                    <div className="order-number-wrap">
                                        <p>{order.order_number}</p>
                                        <img src={BlueCheckIcon} alt="check-icon" />
                                    </div>
                                    :
                                    <div className="order-number-forbid-wrap">
                                        <p>{order.order_number}</p>
                                        <img src={CriticalIcon} alt="close-icon" />
                                    </div>
                                }
                            </S.OrderWrap>)
                        })}
                    </S.OrderListWrap>

                    <div className="divide-line" />

                    <S.WeightWrap>
                        <p className="weight-text">Total weight</p>
                        <p className="weight-value">{(Math.round(totalWeight/100) * 100) / 1000}<span className="weight-unit">kg</span></p>
                    </S.WeightWrap>

                    <div className="divide-line" />
                    <S.BtnWrap>
                        <div className="cancel-btn" onClick={onClickCancel}>
                            <p>Cancel</p>
                        </div>
                        {totalWeight < 5000 ?
                            <div className="save-btn" onClick={onClickSave}>
                                <p>Save</p>
                            </div>
                            :
                            <div className="inactive-save-btn">
                                <p>Save</p>
                            </div>
                        }
                    </S.BtnWrap>
                </>
            }
        </S.Wrap>
    );
}

export default DronePackaging;