import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CloseIcon from "../../../../gcs_image/common/close-icon.svg";
import BottomMenuModal from '../../../common/BottomMenuModal';
import { createVwMarker2D, createVwMarker3D } from '../../../Left/Mission/MissionFunctions';
import { getHeight } from '../../../Function/Cesium';
import WaypointImage from "../../../../gcs_image/marker/waypoint.svg";
import { createCorridorAPI, getCorridorAPI, getCorridorInfoAPI, getStationListAPI, updateCorridorAPI } from '../../../../api/skycook_apis';

import { toast } from 'react-toastify';

import { ReactComponent as NoticeIcon } from "../../../../gcs_image/common/notice-critical-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../gcs_image/common/check-circle-icon.svg";

const S = {
    TitleWrap: styled.div`
        height: 52px;
        background-color: #201f1f;
        padding: 21px 15px;
        border-bottom: 1px solid #353535;
        display: flex;
        align-items: center;

        div {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
    `,
    ModalWrap: styled.div`
        display: flex;
        flex-direction: column;

        .tap-wrap {
            display: flex;
        }
    `,
    Tap: styled.div`
        width: 50%;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: ${props => props.selectedTap === props.tapName ? "1px solid #004be9" : ""};
        cursor: pointer;

        div {
            font-size: 12px;
            line-height: 16px;
            color: ${props => props.selectedTap === props.tapName ? "#004be9" : "#fff"};
        }
    `,
    ModalInputWrap: styled.div`
        padding: 18px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .select-wrap {
            width: 200px;
            height: 31px;
            background-color: #292828;
            display: flex;
            align-items: center;
            padding: 6.5px 11px;
        }

        .input-row-wrap {
            display: flex;
            align-items: center;

            div {
                width: 130px;
                font-size: 12px;
                line-height: 18px;
                color: #b5b5b5;
            }

            .input-wrap {
                width: 200px;
                height: 31px;
            }
            .select-wrap {
                width: 200px;
                height: 31px;
                background-color: #292828;
                display: flex;
                align-items: center;
                padding: 6.5px 11px;
            }

            select {
                cursor: pointer;
            }
        }
        .btn-wrap {
            display: flex;
            gap: 5px;
            margin-left: auto;
            margin-top: 2px;
        }
    `,
    LatLngAltWrap: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        .latlngalt-row-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .sub-title-id {
            width: 15px;
            font-size: 12px;
            line-height: 18px;
            color: #b5b5b5;
            text-align: center;
        }
        
        .sub-title-text {
            width: 80px;
            font-size: 12px;
            line-height: 18px;
            color: #b5b5b5;
            text-align: center;
        }

        .input-wrap {
            width: 80px;
        }

        .remove-btn-wrapper {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .remove-btn {
                width: 25px;
                height: 25px;
                border-radius: 2px;
                background-color: #2c2c2c;
                border: 1px solid #6d6d6d;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                :hover,
                :focus {
                    background-color: #3e3e3e;
                }

                div {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 600;
                    color: #f62a49;
                }
            }
        }
    `,
    AddWaypointBtn: styled.div`
        width: 100%;
        height: 30px;
        border: 1px solid #6d6d6d;
        border-radius: 2px;
        background-color: #2c2c2c;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        cursor: pointer;

        :hover,
        :focus {
            background-color: #3e3e3e;
        }

        div {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #fff;
        }
    `,
}

function CorridorModal({ isModalOpen, setIsModalOpen }) {
    const { vw, map, socket } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
    }));
    const [selectedTap, setSelectedTap] = useState("registration");
    const [corridorName, setCorridorName] = useState("");
    const [startStation, setStartStation] = useState("");
    const [destStation, setDestStation] = useState("");
    const [stationList, setStationList] = useState([]);
    const [corridorList, setCorridorList] = useState([]);
    const [selectedCorridor, setSelectedCorridor] = useState("");
    const [estimateTime, setEstimateTime] = useState("");

    // const [waypointList, setWaypointList] = useState([
    //     { lat: "35.810203", lng: "127.161064", alt: "35.058" },
    //     { lat: "35.810157", lng: "127.161488", alt: "40.008" },
    //     { lat: "35.809235", lng: "127.161556", alt: "40.015" },
    //     { lat: "35.808615", lng: "127.162160", alt: "59.989" },
    //     { lat: "35.807896", lng: "127.161944", alt: "69.933" },
    //     { lat: "35.806804", lng: "127.161228", alt: "79.965" },
    //     { lat: "35.805192", lng: "127.160873", alt: "139.882" },
    //     { lat: "35.802765", lng: "127.160191", alt: "189.995" },
    //     { lat: "35.800628", lng: "127.160804", alt: "249.98" },
    // ]);
    const [waypointList, setWaypointList] = useState([{
        latitude: "",
        longitude: "",
        altitude: "",
    }]);

    useEffect(() => {
        if (isModalOpen.corridor) {
            getStationListWithInit();
        }
    }, [isModalOpen.corridor])

    const onClickModifyTap = useCallback(async () => {
        if (stationList.length > 0) {
            setStartStation(stationList[0].id);
            setDestStation(stationList[0].id);
        }
        setSelectedTap("modify");
    }, [stationList])

    useEffect(() => {
        if (selectedTap === "modify") {
            getCorridorList();
        }
    }, [startStation, destStation])

    const getCorridorList = useCallback(async () => {
        try {
            if (startStation !== "" && destStation !== "") {
                const getRes = await getCorridorAPI(startStation, destStation);
                setCorridorList(getRes.data);

                if (getRes.data.length > 0) {
                    setSelectedCorridor(getRes.data[0].id);
                } else {
                    setSelectedCorridor("");
                    setCorridorName("");
                    setWaypointList([]);
                    setEstimateTime("");
                }
            }

        } catch (e) {
            console.log(e);
        }
    }, [startStation, destStation])

    const getStationListWithInit = useCallback(async () => {
        try {
            const res = await getStationListAPI();
            setStationList(res.data);

            setStartStation(res.data[0].id);
            setDestStation(res.data[0].id);
        } catch (e) {
            console.log(e);
        }
    }, [])

    const onClickUpdate = useCallback(async () => {
        try {
            if (corridorName.length > 10) {
                toast.success(`The corridor name should be less than 10 letters.`, {
                    icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
                return;
            }
            let route_string = "";
            waypointList.map((waypoint, index) => {
                if (index === 0) {
                    route_string = `${waypoint.latitude},${waypoint.longitude},${waypoint.altitude}`;
                } else {
                    route_string += `|||${waypoint.latitude},${waypoint.longitude},${waypoint.altitude}`;
                }
            })
            const data = {
                route_id: selectedCorridor,
                type: "update",
                departure_station_id: startStation,
                destination_station_id: destStation,
                name: corridorName,
                estimate_time: estimateTime,
                route: route_string
            }
            
            const updateRes = await updateCorridorAPI(data);

            if (updateRes.status !== 200) {
                throw new Error();
            } else {
                getCorridorList();
                toast.success(`The corridor is updated.`, {
                    icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
            }
        } catch (e) {
            console.log(e);
        }
    }, [selectedCorridor, startStation, destStation, waypointList, corridorName, estimateTime])

    const onClickDelete = useCallback(async () => {
        try {
            const data = {
                route_id: selectedCorridor,
                type: "delete"
            }
            const deleteRes = await updateCorridorAPI(data);
            
            if (deleteRes.status !== 200) {
                throw new Error();
            } else {
                toast.success(`The corridor is removed.`, {
                    icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
                setCorridorName("");
                setWaypointList([]);
                getCorridorList();
            }
        } catch (e) {
            console.log(e);
        }
    }, [selectedCorridor])

    const onClickCancel = useCallback(() => {
        setIsModalOpen({
            ...isModalOpen,
            corridor: false,
        });
        setSelectedTap("registration");
        overlayInit();
        init();
    }, [waypointList, map, socket])

    const init = useCallback(() => {
        if (stationList.length > 0) {
            setStartStation(stationList[0].id);
            setDestStation(stationList[0].id);
        }
        setCorridorName("");
        setSelectedCorridor("");
        setCorridorList([]);
        setEstimateTime("");
        setWaypointList([{
            latitude: "",
            longitude: "",
            altitude: "",
        }]);
    }, [stationList])

    const overlayInit = useCallback(() => {
        if (!!map && !!socket) {
            waypointList.map((waypoint, index) => {
                map.removeObjectById(`wp${index}`);
            })
        }
    }, [waypointList, map, socket])

    useEffect(() => {
        setCorridorName("");
        setWaypointList([{
            latitude: "",
            longitude: "",
            altitude: "",
        }]);
        setEstimateTime("");
    }, [selectedTap])

    useEffect(() => {
        getCorridorInfo();
    }, [selectedCorridor])

    const getCorridorInfo = useCallback(async () => {
        try {
            if (selectedCorridor !== "") {
                const getRes = await getCorridorInfoAPI(selectedCorridor);
                const route_list = getRes.data.route.split("|||");

                const route = [];
                route_list.map((waypoint) => {
                    const split_data = waypoint.split(",");
                    route.push({
                        latitude: split_data[0],
                        longitude: split_data[1],
                        altitude: split_data[2]
                    })
                })
                setCorridorName(getRes.data.name);
                setWaypointList(route);
                setEstimateTime(getRes.data.estimate_time);
            }
        } catch (e) {
            console.log(e);
        }
    }, [selectedCorridor])

    useEffect(() => {
        if (!!socket) {
            waypointList.map((waypoint, index) => {
                // 기존 마커 제거
                if (!!map) map.removeObjectById(`wp${index}`);

                // 새로운 마커 생성
                if (waypoint.latitude !== "" && waypoint.longitude !== "") {
                    if (waypoint.altitude === "" || (waypoint.altitude !== "" && socket.getDroneHomePosition().altitude === '')) {
                        // 2D 마커 생성
                        const position = {
                            latitude: waypoint.latitude,
                            longitude: waypoint.longitude,
                        }
                        createVwMarker2D(vw, `wp${index}`, WaypointImage, position);
                    } else {
                        // 3D 마커 생성
                        const position = {
                            latitude: waypoint.latitude,
                            longitude: waypoint.longitude,
                            altitude: waypoint.altitude,
                        }
                        createVwMarker3D(vw, `wp${index}`, WaypointImage, position, socket.getDroneHomePosition().altitude);
                    }
                }
            })
        }
    }, [waypointList, socket])

    useEffect(() => {
        overlayInit();
        // init();
        if (isModalOpen.corridor) {
            // const clickEvent = (windowPosition, ecefPosition, cartographic) => {
            //     const missionAlt = getHeight(cartographic.latitudeDD, cartographic.longitudeDD) + Number(missionData.altitude) - Number(socket.getDroneHomePosition().altitude);
            //     const position = { latitude: cartographic.latitudeDD, longitude: cartographic.longitudeDD, altitude: missionAlt };
            //     let point;
            //     if (modifyData.isModify) {
            //         const markerId = `wp${modifyData.modifyIndex}`;
            //         map.removeObjectById(markerId);
            //         point = createVwMarker2D(vw, markerId, WaypointImage, position);
            //         setModifyData({
            //             ...modifyData,
            //             isModify: false,
            //         });
            //         let newWaypoint = [...missionData.waypoint];
            //         newWaypoint[modifyData.modifyIndex] = position;
            //         setMissionDate({
            //             ...missionData,
            //             waypoint: newWaypoint,
            //         });
            //         point.addEventListener(markerClickEvent);
            //     } else {
            //         point = createVwMarker2D(vw, `wp${missionData.waypoint.length}`, WaypointImage, position);
            //         addWaypoint(position);
            //     }
            //     point.addEventListener(markerClickEvent);
            // };
            // map.onClick.addEventListener(clickEvent);
        } else {

        }
    }, [isModalOpen.corridor])

    const onClickCreate = useCallback(async () => {
        if (startStation === destStation) {
            toast.success(`The departure and destination station cannot be set the same`, {
                    icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
            return;
        }
        if (corridorName.length > 10) {
            toast.success(`The corridor name should be less than 10 letters.`, {
                    icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
            return;
        }
        if (estimateTime === "") {
            toast.success(`Estimate time is essential.`, {
                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
            })
            return;
        }

        try {
            let route_string = "";
            waypointList.map((waypoint, index) => {
                if (index === 0) {
                    route_string = `${waypoint.latitude},${waypoint.longitude},${waypoint.altitude}`
                } else {
                    route_string += `|||${waypoint.latitude},${waypoint.longitude},${waypoint.altitude}`
                }
            })

            const data = {
                departure_station_id: startStation,
                destination_station_id: destStation,
                name: corridorName,
                route: route_string,
                estimate_time: Number(estimateTime),
            }

            const createRes = await createCorridorAPI(data);

            if (createRes.status !== 201) {
                throw new Error();
            } else {
                toast.success(`The corridor is added.`, {
                    icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
                init();
                overlayInit();
            }
        } catch (e) {
            console.log(e);
        }
    }, [startStation, destStation, corridorName, waypointList, stationList, estimateTime])
    
    if (isModalOpen.corridor) {
        return (
            <BottomMenuModal width="345px">
                <S.TitleWrap>
                    <div>CORRIDORS</div>
                </S.TitleWrap>
                <S.ModalWrap>
                    <div className="tap-wrap">
                        <S.Tap selectedTap={selectedTap} tapName={"registration"} onClick={() => setSelectedTap("registration")}>
                            <div>Registration</div>
                        </S.Tap>
                        <S.Tap selectedTap={selectedTap} tapName={"modify"} onClick={onClickModifyTap}>
                            <div>Modify</div>
                        </S.Tap>
                    </div>

                    {selectedTap === "registration" ?
                        <S.ModalInputWrap>
                            <div className="input-row-wrap">
                                <div>Start station</div>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        value={startStation}
                                        onChange={e => setStartStation(e.target.value)}>
                                        {stationList.map((station) => {
                                            return (<option value={station.id} key={`station${station.id}`}>{station.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <div>Dest. station</div>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        value={destStation}
                                        onChange={e => setDestStation(e.target.value)}>
                                        {stationList.map((station) => {
                                            return (<option value={station.id} key={`station${station.id}`}>{station.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="input-row-wrap">
                                <div>Corridor name</div>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={corridorName}
                                        onChange={e => setCorridorName(e.target.value)} />
                                    {corridorName !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setCorridorName("")} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <div>Estimate time</div>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={estimateTime}
                                        onChange={e => setEstimateTime(e.target.value)} />
                                    {estimateTime !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setEstimateTime("")} />
                                    )}
                                </div>
                            </div>

                            <S.LatLngAltWrap>
                                <div className="latlngalt-row-wrapper">
                                    <div className="sub-title-id">id</div>
                                    <div className="sub-title-text">lat</div>
                                    <div className="sub-title-text">lng</div>
                                    <div className="sub-title-text">alt</div>
                                    <div className="remove-btn-wrapper" />
                                </div>

                                {waypointList.map((waypoint, index) => {
                                    return (
                                        <div className="latlngalt-row-wrapper" key={`waypointList${index}`}>
                                            <div className="sub-title-id">{index + 1}</div>
                                            <div className="input-wrap default-input">
                                                <input type="text" value={waypoint.latitude} onChange={(e) => {
                                                    setWaypointList(prev => {
                                                        let newList = [...prev];
                                                        newList[index].latitude = e.target.value;
                                                        return newList;
                                                    })
                                                }} />
                                            </div>
                                            <div className="input-wrap default-input">
                                                <input type="text" value={waypoint.longitude} onChange={e => {
                                                    setWaypointList(prev => {
                                                        let newList = [...prev];
                                                        newList[index].longitude = e.target.value;
                                                        return newList;
                                                    })
                                                }} />
                                            </div>
                                            <div className="input-wrap default-input">
                                                <input type="text" value={waypoint.altitude} onChange={e => { 
                                                    setWaypointList(prev => {
                                                        let newList = [...prev];
                                                        newList[index].altitude = e.target.value;
                                                        return newList;
                                                    })
                                                }} />
                                            </div>
                                            <div className="remove-btn-wrapper">
                                                {index > 0 &&
                                                        <div className="remove-btn" onClick={() => {
                                                            // 마커 지우기
                                                            map.removeObjectById(`wp${waypointList.length-1}`);
                                                            setWaypointList(prev => {
                                                                let newList = [...prev];
                                                                newList.splice(index, 1);
                                                                return newList;
                                                            })
                                                        }}>
                                                        <div>-</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                
                                <S.AddWaypointBtn onClick={() => {
                                    setWaypointList(prev => {
                                        let newList = [...prev];
                                        newList.push({
                                            latitude: "",
                                            longitude: "",
                                            altitude: ""
                                        })
                                        return newList;
                                    })
                                }}>
                                    <div>+</div>
                                </S.AddWaypointBtn>
                            </S.LatLngAltWrap>

                            <div className="btn-wrap">
                                <div className="cancel-btn" onClick={onClickCancel}>
                                    <p>Cancel</p>
                                </div>
                                <div className="save-btn" onClick={onClickCreate}>
                                    <p>Save</p>
                                </div>
                            </div>

                        </S.ModalInputWrap>
                        :
                        <S.ModalInputWrap>
                            <div className="input-row-wrap">
                                <div>Start station</div>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        value={startStation}
                                        onChange={e => setStartStation(e.target.value)}>
                                        {stationList.map((station) => {
                                            return (<option value={station.id} key={`station${station.id}`}>{station.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <div>Dest. station</div>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        value={destStation}
                                        onChange={e => setDestStation(e.target.value)}>
                                        {stationList.map((station) => {
                                            return (<option value={station.id} key={`station${station.id}`}>{station.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <div>Corridor list</div>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        value={selectedCorridor}
                                        onChange={e => setSelectedCorridor(Number(e.target.value))}>
                                        {corridorList.map((corridor) => {
                                            return (<option value={corridor.id} key={`corridorList${corridor.id}`}>{corridor.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <div>Corridor Name</div>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={corridorName}
                                        onChange={e => setCorridorName(e.target.value)} />
                                    {corridorName !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setCorridorName("")} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <div>Estimate time</div>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={estimateTime}
                                        onChange={e => setEstimateTime(e.target.value)} />
                                    {estimateTime !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setEstimateTime("")} />
                                    )}
                                </div>
                            </div>

                            <S.LatLngAltWrap>
                                <div className="latlngalt-row-wrapper">
                                    <div className="sub-title-id">id</div>
                                    <div className="sub-title-text">lat</div>
                                    <div className="sub-title-text">lng</div>
                                    <div className="sub-title-text">alt</div>
                                    <div className="remove-btn-wrapper" />
                                </div>

                                {waypointList.map((waypoint, index) => {
                                    return (
                                        <div className="latlngalt-row-wrapper" key={`waypointList${index}`}>
                                            <div className="sub-title-id">{index + 1}</div>
                                            <div className="input-wrap default-input">
                                                <input type="text" value={waypoint.latitude} onChange={(e) => {
                                                    setWaypointList(prev => {
                                                        let newList = [...prev];
                                                        newList[index].latitude = e.target.value;
                                                        return newList;
                                                    })
                                                }} />
                                            </div>
                                            <div className="input-wrap default-input">
                                                <input type="text" value={waypoint.longitude} onChange={e => {
                                                    setWaypointList(prev => {
                                                        let newList = [...prev];
                                                        newList[index].longitude = e.target.value;
                                                        return newList;
                                                    })
                                                }} />
                                            </div>
                                            <div className="input-wrap default-input">
                                                <input type="text" value={waypoint.altitude} onChange={e => { 
                                                    setWaypointList(prev => {
                                                        let newList = [...prev];
                                                        newList[index].altitude = e.target.value;
                                                        return newList;
                                                    })
                                                }} />
                                            </div>
                                            <div className="remove-btn-wrapper">
                                            {index > 0 &&
                                                    <div className="remove-btn" onClick={() => {
                                                        setWaypointList(prev => {
                                                            let newList = [...prev];
                                                            newList.splice(index, 1);
                                                            return newList;
                                                        })
                                                        overlayInit();
                                                    }
                                                }>
                                                    <div>-</div>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    )
                                })}
                                
                                <S.AddWaypointBtn onClick={() => {
                                    setWaypointList(prev => {
                                        let newList = [...prev];
                                        newList.push({
                                            latitude: "",
                                            longitude: "",
                                            altitude: ""
                                        })
                                        return newList;
                                    })
                                }}>
                                    <div>+</div>
                                </S.AddWaypointBtn>
                            </S.LatLngAltWrap>

                            <div className="btn-wrap">
                                <div className="cancel-btn" onClick={onClickCancel}>
                                    <p>Cancel</p>
                                </div>
                                <div className="delete-btn" onClick={onClickDelete}>
                                    <p>Delete</p>
                                </div>
                                <div className="save-btn" onClick={onClickUpdate}>
                                    <p>Save</p>
                                </div>
                            </div>
                        </S.ModalInputWrap>
                    }
                </S.ModalWrap>
            </BottomMenuModal>
        );
    }
}

export default CorridorModal;