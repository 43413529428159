const SET_ROUTE_LINE_OVERLAY = 'overlay/SET_ROUTE_LINE_OVERLAY';
const SET_EMERGENCY_LINE_OVERLAY = 'overlay/SET_EMERGENCY_LINE_OVERLAY';
const SET_WAYPOINT_COUNT = 'overlay/SET_WAYPOINT_COUNT';
const SET_GROUND_FLIGHT_PATH = 'overlay/SET_GROUND_FLIGHT_PATH';

export const setRouteLineOverlay = (entity) => ({ type: SET_ROUTE_LINE_OVERLAY, entity });
export const setEmergencyLineOverlay = (entity) => ({ type: SET_EMERGENCY_LINE_OVERLAY, entity });
export const setWaypointCount = (count) => ({ type: SET_WAYPOINT_COUNT, count });
export const setGroundFlightPath = (path) => ({ type: SET_GROUND_FLIGHT_PATH, path });

const initialState = {
  routeLineOverlay: null,
  emergencyLineOverlay: null,
  waypointCount: 0,
  groundFlightPath: null,
}

export default function overlay(state = initialState, action){
  switch (action.type){
    case SET_ROUTE_LINE_OVERLAY:
      return{
        ...state,
        routeLineOverlay : action.entity,
      }
    case SET_EMERGENCY_LINE_OVERLAY:
      return{
        ...state,
        emergencyLineOverlay : action.entity,
      }
    case SET_WAYPOINT_COUNT:
      return {
        ...state,
        waypointCount: action.count,
      }
    case SET_GROUND_FLIGHT_PATH:
      return {
        ...state,
        groundFlightPath: action.path,
      }
    default:
      return state;
  }
}