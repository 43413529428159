import { useState } from "react";
import styled from "styled-components"
import icon from '../../../gcs_image/prediction/TimeStampDragIMG.svg';
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const DragAbleScrollBox = styled.div`
  position: absolute;
  width: 90%;
  height: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: grab;
  z-index: 2;
  display: flex;
  align-items: center;
`
const DragAbleScroll = styled.div`
  position: absolute;
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
`
const DragAbleScrollDone = styled.div`
  overflow: hidden;
  height: 5px;
  position: absolute;
`
const DragAbleScrollActive = styled.div` 
  position: absolute;
  width: 100%;
  height: 5px;
  /* background: #C3FFE2; */
  background: #FFFFFF;
  border-radius: 3px;
`
const Timer = styled.img`
  display: block;
  position: absolute;
  -webkit-user-drag: none;
`
function Scroll({predictionPoints}){
  const {vw,map} = useSelector(state=>({
    vw : state.map.vw,
    map : state.map.map,
  }));
  const timebar = useRef(null);
  const [stamp,setStamp] = useState(0);
  const [isDown,setIsdown] = useState(false);
  const [focusNum,setFocusNum] = useState(0);//8퍼가 1 17퍼마다 1씩 증가

  const setFocus = (num)=>{
    if(num<8){
      setFocusNum(0)
    }else{
      setFocusNum(Math.floor((num-8)/17)+1)
    }
  }
  const down = e =>{
    if(e.nativeEvent.offsetX>23){
      setFocus(+((e.nativeEvent.offsetX/timebar.current.offsetWidth)*100).toFixed(0))
      setStamp(e.nativeEvent.offsetX)
    }
    setIsdown(true)
  }
  const move = e =>{
    if(!isDown) return;
    if(e.nativeEvent.offsetX>23){
      setFocus(+((e.nativeEvent.offsetX/timebar.current.offsetWidth)*100).toFixed(0))
      setStamp(e.nativeEvent.offsetX)
    }
  }
  const up = e =>{
    setIsdown(false);
  }
  const drawGrid = useEffect(()=>{
    const rgbmap = [[255,0,0],[255,74,0],[255,139,0],[255,182,0],[255,221,0],[255,255,0]];
    if(focusNum>0){
      if(predictionPoints.length>0){
        for(let j=0;j<6;j++){
          if(focusNum > j){
            let coords=[];
            for(let i=0;i<predictionPoints[j].length;i++){
              let pt = new vw.Coord(predictionPoints[j][i][0],predictionPoints[j][i][1]);
              coords.push(pt)
            }
            let temp = new vw.Collection(coords);
            let poly = new vw.geom.Polygon(temp);
            poly.setId(`predictGrid${j}`);
            poly.setFillColor(new vw.Color(rgbmap[j][0],rgbmap[j][1],rgbmap[j][2],50),30)
            poly.create();
            let lastpt = new vw.Coord(predictionPoints[j][0][0],predictionPoints[j][0][1]);
            coords.push(lastpt);
            const coordCol = new vw.Collection(coords); 
            const linestring = new vw.geom.LineString(coordCol);
            linestring.setId( `linestring${j}` ); 
            linestring.setFillColor( new vw.Color(rgbmap[j][0],rgbmap[j][1],rgbmap[j][2]) );
            linestring.setWidth(5);            
            linestring.create();
          }
        }
      }
    }
    return ()=>{
      for(let i=0; i<6;i++){
        map.removeObjectById(`predictGrid${i}`);
        map.removeObjectById(`linestring${i}`);
      }
    }
  },[focusNum,vw,map,predictionPoints])
  return(
    <DragAbleScrollBox
      ref={timebar}
      onMouseDown={down}
      onMouseUp={up}
      onMouseMove={move}
    >
      <DragAbleScroll />
      <DragAbleScrollDone style={{width:`${stamp}px`}}>
        <DragAbleScrollActive />
      </DragAbleScrollDone>
      <Timer src={icon} style={{left:`${stamp-6.5}px`}}/>
    </DragAbleScrollBox>
  )
}

export default Scroll;