import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { sendMsg } from '../../../../store/reducer/drone';
import { setWaypoint, setSpeed } from '../../../Left/Mission/MissionObject';
import { createVwMarker3D, createVwMarker2DLabel } from '../../../Left/Mission/MissionFunctions';
import pointMarker from "../../../../gcs_image/marker/waypoint.svg";
import stationMarker from "../../../../gcs_image/marker/station-icon.svg";
import destinationMarker from "../../../../gcs_image/marker/destination-icon.svg";
import { createFlightPath, deleteEntitie, createMissionLine, createGroundFlightPath } from '../../../Function/Cesium';
import { setGroundFlightPath, setRouteLineOverlay, setWaypointCount } from '../../../../store/reducer/overlay';
import { cancelOrderPackageAPI, changeDroneStatusAPI, changeOrderStatusAPI, getOrderListAPI, getRouteAPI, getEmergencyRoutes, updateReportStatus } from '../../../../api/skycook_apis';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ReactComponent as CheckIcon } from "../../../../gcs_image/common/check-circle-icon.svg";
import { ReactComponent as NoticeIcon } from "../../../../gcs_image/common/notice-critical-icon.svg";
import chimeBellFile from "../../../../audio/chime-bell.wav";
import { setOrderList } from '../../../../store/reducer/skycook';

const A = {
    fadeIn: keyframes`
        from {
            opacity: 0;
            transform: translateY(-5%);
        } to {
            opacity: 1;
            transform: translateY(0%);
        }
    `,
    fadeOut: keyframes`
        from {
            opacity: 1;
            transform: translateY(0%);
        } to {
            opacity: 0;
            transform: translateY(-5%);
        }
    `
}

const S = {
    Wrap: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 13px;
    `,
    ActionInactiveBtn: styled.div`
        width: 100%;
        height: 35px;
        padding: 7.5px 108.5px;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.30);
        background-color: #2c2c2c;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #fff;
        }
    `,
    ActionActiveBtn: styled.div`
        width: 100%;
        height: 35px;
        padding: 7.5px 108.5px;
        border: 1px solid #004be9;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
        }
    `,
    ControlBtnWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 4px;
        animation: ${props => props.animation} 0.3s linear;
        
        .btn-wrapper {
            display: flex;
            width: 100%;
            height: 35px;
            justify-content: space-between;
        }

        .control-left-btn {
            width: 50%;
            padding: 10px;
            border-radius: 2px 0px 0px 2px;
        }
        .control-right-btn {
            width: 50%;
            padding: 10px;
            border-radius: 0px 2px 2px 0px;
            border-left: none;
        }

        .control-servo-left-btn {
            width: 33%;
            padding: 10px;
            border-radius: 2px 0px 0px 2px;
            border-right: none;
        }
        .control-servo-center-btn {
            width: 34%;
            padding: 10px;
        }
        .control-servo-right-btn {
            width: 33%;
            padding: 10px;
            border-radius: 0px 2px 2px 0px;
            border-left: none; 
        }
        .servo-text {
            font-size: 10px;
        }
    `,
}

function DroneActionBtn({ droneFlightStatus, selectedDroneSN, droneMode, droneSN }) {
    const [chimeBell] = useState(new Audio(chimeBellFile));
    const dispatch = useDispatch();
    const { vw, map, socket, startHeight, emergencyLineOverlay, routeLineOverlay, waypointCount, logInterval, skycook_websocket, deliveryStatus, groundFlightPath, orderList, speed, forest_fire_report } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
        startHeight: state.droneSetting.startHeight,
        routeLineOverlay: state.overlay.routeLineOverlay,
        waypointCount: state.overlay.waypointCount,
        emergencyLineOverlay: state.overlay.emergencyLineOverlay,
        logInterval: state.skycook.logInterval,
        skycook_websocket: state.skycook.skycook_websocket,
        deliveryStatus: state.skycook.delivery_status,
        groundFlightPath: state.overlay.groundFlightPath,
        orderList: state.skycook.orderList,
        speed: state.droneSetting.speed,
        forest_fire_report:state.skycook.forest_fire_report,
    }))
    const [animation, setAnimation] = useState(A.fadeIn);
    const [actionClicked, setActionClicked] = useState(false);
    // const [isRouteSetted, setIsRoutedSetted] = useState(false);
    const [takenTime, setTakenTime] = useState(0);
    const [corridor, setCorridor] = useState({
        id: "",
        name: "",
        route: [],
    });

    // const mission = [
    //     { latitude: 35.800101, longitude: 127.167857, altitude: 30 },
    //     { latitude: 35.799508, longitude: 127.166024, altitude: 127 },
    //     { latitude: 35.799812, longitude: 127.163409, altitude: 230 },
    //     { latitude: 35.800672, longitude: 127.160845, altitude: 260 },
    // ]
    // const mission = [];

    useEffect(() => {
        if (selectedDroneSN !== droneSN) {
            setActionClicked(false);
        }
        setAnimation(A.fadeIn);
    }, [selectedDroneSN])

    const onClickResetPackage = useCallback(async () => {
        // if (emergencyAcitve) {
        //     toast.warn(`${socket.getDroneName(droneSN)} : The package cannot be reset on emergency.`, {
        //         // position: toast.POSITION.TOP_CENTER,
        //         icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#FFDE0D"} />
        //     })
        // }

        if (socket.getEmergencyActive(droneSN)) {
            toast.warn(`${socket.getDroneName(droneSN)} : The package cannot be reset on emergency.`, {
                // position: toast.POSITION.TOP_CENTER,
                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#FFDE0D"} />
            })
            return;
        }

        try {
            const order_list = [];
            if (deliveryStatus === true) {
                const order_package = socket.getDronePackage(droneSN);

                order_package.orderList.map((order) => {
                    order_list.push(order.order_number);
                })
                const resetRes = await cancelOrderPackageAPI({order_number_list: order_list});
    
                if (resetRes.status !== 200) {
                    throw new Error();
                } else {
                    clearInterval(logInterval);
                    socket.removePackage(droneSN);
                    initOverlay();
                    getOrderList();
                    toast.success(`The package is reset`, {
                        icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                    // skycook_websocket.send(JSON.stringify({
                    //     type: "delivery_request",
                    // }))
                }
            } else {
                changeReportStatus();
                initOverlay();
                socket.removePackage(droneSN);
                
            }
           
        } catch (e) {
            console.log(e);
        }
    }, [socket, droneSN, logInterval, skycook_websocket, deliveryStatus, waypointCount, routeLineOverlay, emergencyLineOverlay, groundFlightPath, forest_fire_report])

    const initOverlay = useCallback(() => {
        map.removeObjectById('stationMarker');
        map.removeObjectById('destinationMarker');
        map.removeObjectById('emergencyMarker');
        // for (let i = 0; i < waypointCount; i++) {
        //     map.removeObjectById(`wp${i}`)
        // }

        // deleteEntitie(routeLineOverlay);
        deleteEntitie(emergencyLineOverlay);
        deleteEntitie(groundFlightPath);
    }, [map, waypointCount, routeLineOverlay, emergencyLineOverlay, groundFlightPath])

    const onClickARMED = useCallback(() => {
        dispatch(sendMsg(`01||${droneSN}`));
    }, [droneSN])

    const onClickTakeOff = useCallback(() => {
        dispatch(sendMsg(`02||${startHeight}||${droneSN}`));
    }, [droneSN, startHeight])

    useEffect(() => {
        if (!!socket) {
            if (droneFlightStatus === "") {
                // 대기중(DISARM)
                // 드론 상태 변경 "대기중"
                changeDroneStatus("abailable");
            } else if (droneFlightStatus === "IN FLIGHT") {
                // 운행중

                const drone_package = socket.getDronePackage(droneSN)
                if (drone_package) {
                    // package에 이륙 시간 추가
                    const departure_time = moment();
                    socket.setPackageDepartureTime(droneSN, departure_time.format('hh:mm A, MMM YY'))
                    if (drone_package.estimatedTakenTime !== "-") {
                        const arrive_time = departure_time.add(socket.getPackageEstimateTime(droneSN), "minutes")
                        socket.setPackageEstimatedArrivalTime(droneSN, arrive_time.format('hh:mm A, MMM YY'))
                    }
                }

                // 드론 상태 변경 "운행중"
                changeDroneStatus("operating");
            } else if (droneFlightStatus === "EMERGENCY") {
                // 드론 상태
                // 드론 상태 변경 "비상착륙중"
                changeDroneStatus("unabailable");
            }
        }
    }, [droneFlightStatus])

    const changeReportStatus = useCallback(async () => {
        try {
            const changeRes = await updateReportStatus(forest_fire_report.id, 0);

            if (changeRes.status !== 200) {
                throw new Error();
            } 
        } catch (e) {
            console.log(e);
        }
    }, [forest_fire_report])

    const changeDroneStatus = useCallback(async (status) => {
        try {
            const data = {
                serial_number: droneSN,
                status: status
            }
            const changeRes = await changeDroneStatusAPI(data);

            if (changeRes.status !== 200) {
                throw new Error();
            } else {
                // console.log(`드론 상태 변경 : ${status}`);
            }
        } catch (e) {
            console.log(e);
        }
    }, [droneSN])

    const onClickSetRoute = useCallback(async () => {
        if (socket.getEmergencyActive(droneSN)) {
            toast.warn(`${socket.getDroneName(droneSN)} : The route cannot be set on emergency.`, {
                // position: toast.POSITION.TOP_CENTER,
                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#FFDE0D"} />
            })
            return;
        }

        if (deliveryStatus === true) {
            try {
                const data = {
                    order_number_list: socket.getOrderNumberListInPackage(droneSN),
                    departure_station_name: socket.getDepartureStationName(droneSN).name,
                    destination_station_name: socket.getDestinationStationName(droneSN).name,
                }
                const getRouteRes = await getRouteAPI(data);
    
                if (getRouteRes.status !== 200) {
                    throw new Error();
                } else {
                    if (getRouteRes.data !== "") {
                        setMissionList(getRouteRes.data);
                        // skycook_websocket.send(JSON.stringify({ 
                        //     type: "delivery_request",
                        // }))
                        getOrderList();
                    } else {
                        toast.success(`There are no possible flight routes.`, {
                            icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                        })
                        return;
                    }
                }
    
            } catch (e) {
                console.log(e);
            }
        } else {
            let packageData = socket.getDronePackage(droneSN)
            const getRouteRes = await getEmergencyRoutes(packageData.orderList[0].route_type);
       
            if (getRouteRes.status !== 200) {
                throw new Error();
            } else {
                if (getRouteRes.data !== "") {
                    setMissionList(getRouteRes.data[0]);
                } else {
                    toast.success(`There are no possible flight routes.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                    return;
                }
            }
        
        }
     
    }, [droneSN, socket, skycook_websocket, deliveryStatus])

    const getOrderList = useCallback(async () => {
        try {
            const getRes = await getOrderListAPI();
            if (getRes.status !== 200) {
                throw new Error();
            } else {
                let newOrderDetected = false;
                
                if (orderList.length === 0 && getRes.data.length > 0) {
                    newOrderDetected = true;
                } else {
                    getRes.data.map((new_order) => {
                        if (!newOrderDetected) {
                            orderList.map((prev_order, index) => {
                                if (prev_order.order_number === new_order.order_number) {
                                    return;
                                }
                                if (index === prev_order.length - 1) {
                                    newOrderDetected = true;
                                    return;
                                }
                            })
                        }
                    })
                }
                if (newOrderDetected) {
                    chimeBell.play();
                }

                dispatch(setOrderList(getRes.data));
            }
        } catch (e) {
            console.log(e);
        }
    }, [orderList, chimeBell])

    const setMissionList = useCallback((saved_route) => {
        let waypoint_list = [];
        
        saved_route.route.split("|||").map((waypoint) => {
            const comma_split_data = waypoint.split(",");
            waypoint_list.push({
                latitude: comma_split_data[0],
                longitude: comma_split_data[1],
                altitude: comma_split_data[2]
            })
        })
        const temp_list = {
            id: saved_route.id,
            name: saved_route.name,
            route: waypoint_list, 
        }
        socket.setPackageEstimateTime(droneSN, saved_route.estimate_time);
        setTakenTime(saved_route.estimate_time);
        setCorridor(temp_list);
    }, [droneSN])

    useEffect(() => {
        if (corridor.id !== "") {
            socket.setOrderRoute(droneSN, corridor);

            if (droneFlightStatus !== "" && socket.getPackageDepartureTime(droneSN) !== "") {
                // 드론이 비행중인데 패키지에 이륙 시간이 없으면 현재 시간 저장
                // socket.setPackageEstimateTime(droneSN, takenTime);
                const departure_time = moment();
                socket.setPackageDepartureTime(droneSN, departure_time.format('hh:mm A, MMM YY'));
                const arrive_time = departure_time.add(takenTime, "minutes");
                // const arrive_time = departure_time.add(socket.getPackageEstimateTime(droneSN), "minutes");
                socket.setPackageEstimatedArrivalTime(droneSN, arrive_time.format('hh:mm A, MMM YY'));
            }

            displayMissionOverlay();
            // setCorridor({
            //     id: "",
            //     name: "",
            //     route: [],
            // });
        }
    }, [corridor])

    const displayMissionOverlay = useCallback(() => {
        // 지도 표시
        map.removeObjectById(`stationMarker`);
        map.removeObjectById(`destinationMarker`);
        deleteEntitie(routeLineOverlay);
        dispatch(setRouteLineOverlay(null));
        deleteEntitie(groundFlightPath);
        dispatch(setGroundFlightPath(null));

        const drone_package = socket.getDronePackage(droneSN);

        // for (let i = 0; i < waypointCount; i++) {
        //     map.removeObjectById(`wp${i}`);
        // }
        let position_list = [];
        corridor.route.map((waypoint, idx) => {
            // map.removeObjectById(`wp${idx}`);

            const position = {
                latitude: waypoint.latitude,
                longitude: waypoint.longitude,
                altitude: waypoint.altitude,
            }
            // createVwMarker3D(vw, `wp${idx}`, pointMarker, position, socket.getDroneHomePosition().altitude)
            position_list.push(position);
        })
        dispatch(setWaypointCount(corridor.route.length));

        // 출발지 도착지 마커 표시
        const station = {
            latitude: drone_package.startStation.latlng.lat,
            longitude: drone_package.startStation.latlng.lng,
        }
        createVwMarker2DLabel(vw, `stationMarker`, stationMarker, station, "station");
        
        const destination = {
            latitude: drone_package.destination.latlng.lat,
            longitude: drone_package.destination.latlng.lng,
        }
        createVwMarker2DLabel(vw, `destinationMarker`, destinationMarker, destination, "destination");

        dispatch(setGroundFlightPath(createGroundFlightPath(position_list)));

        // setIsRoutedSetted(true);
    }, [routeLineOverlay, corridor, groundFlightPath])

    const onClickMissionSend = useCallback(() => {
        let send_mission = [setSpeed(0, speed)];

        try {
            if (corridor.id === "") {
                // 패키징 후 GCS 다시 실행 했을 때
                const route = socket.getPackageRoute(droneSN);
                if (!!route) {
                    route.route.map((waypoint) => {
                        send_mission.push(setWaypoint(waypoint.latitude, waypoint.longitude, waypoint.altitude));
                    })
                    dispatch(sendMsg(`11||${JSON.stringify(send_mission)}||${droneSN}`));
                    // dispatch(setRouteLineOverlay(createMissionLine(corridor.route, socket.getDroneHomePosition().altitude)));
                }
            } else {
                corridor.route.map((waypoint) => {
                    send_mission.push(setWaypoint(waypoint.latitude, waypoint.longitude, waypoint.altitude))
                })
                dispatch(sendMsg(`11||${JSON.stringify(send_mission)}||${droneSN}`));
                // dispatch(setRouteLineOverlay(createMissionLine(corridor.route, socket.getDroneHomePosition().altitude)));
            }
        } catch (e) {
            console.log(e);
        }
    }, [droneSN, corridor, socket])

    const onClickPause = useCallback(() => {
        dispatch(sendMsg(`03||GUIDED||${droneSN}`));
    }, [droneSN])

    const onClickAuto = useCallback(() => {
        dispatch(sendMsg(`03||AUTO||${droneSN}`));
    }, [droneSN])

    const onClickReturn = useCallback(() => {
        dispatch(sendMsg(`12||${droneSN}`))
        if (deliveryStatus === true) {
            // 복귀중 변경
            changeOrderStatus();
        }
    }, [droneSN, deliveryStatus])

    const onClickUnFollow = useCallback(() => {
        socket.setClickFollow(false)

    }, [droneSN, socket])

    const onClicFollow = useCallback(() => {
        socket.setClickFollow(true)
    }, [droneSN, socket])

    const changeOrderStatus = useCallback(async () => {
        try {
            const data = {
                order_number_list: socket.getOrderNumberListInPackage(droneSN),
                status: "in_comeback",
            }
            const res = await changeOrderStatusAPI(data);

            if (res.status !== 200) {
                throw new Error();
            } else {
                // console.log("복귀")
            }

        } catch (e) {
            console.log(e);
        }
    }, [])

    const onClickEmergency = useCallback(() => {
        dispatch(sendMsg(`13||${droneSN}`))
    }, [droneSN])

    const onClickLand = useCallback(() => {
        dispatch(sendMsg(`03||LAND||${droneSN}`));
    }, [droneSN])

    const onClickRTL = useCallback(() => {
        dispatch(sendMsg(`03||RTL||${droneSN}`));
    }, [droneSN])

    const onClickServo = useCallback((param) => {
        dispatch(sendMsg(`14||${param}||${droneSN}`));
    }, [droneSN])

    // const onClickServoUp = useCallback(() => {
    //     dispatch(sendMsg(`14||2||${droneSN}`));
    // }, [droneSN])

    // const onClickServoDown = useCallback(() => {
    //     dispatch(sendMsg(`14||0||${droneSN}`));
    // }, [droneSN])

    return (
        <S.Wrap>
            {selectedDroneSN === droneSN ?
                <S.ActionActiveBtn className="default-btn" actionClicked={actionClicked} onClick={() => {
                    if (actionClicked) {
                        setAnimation(A.fadeOut);
                        setTimeout(() => setActionClicked(!actionClicked), 300);
                    } else {
                        setAnimation(A.fadeIn);
                        setActionClicked(!actionClicked)
                    }
                }}>
                    <p>ACTION</p>
                </S.ActionActiveBtn>
                :
                <S.ActionInactiveBtn onClick={() => setActionClicked(!actionClicked)}>
                    <p>ACTION</p>
                </S.ActionInactiveBtn>
            }
            {actionClicked && (
                <S.ControlBtnWrap animation={animation}>
                    {socket.isDroneHasPackage(droneSN) ?
                        <div className="btn-wrapper">
                            <div className="control-left-btn gray-default-btn"
                                onClick={onClickResetPackage}>
                                <p>RESET PACKAGE</p>
                            </div>
                            <div className="control-right-btn gray-default-btn"
                                onClick={onClickSetRoute}>
                                <p>SET ROUTE</p>
                            </div>
                        </div>
                    :
                        <div className="btn-wrapper">
                            <div className="control-left-btn gray-default-disable-btn">
                                <p>RESET PACKAGE</p>
                            </div>
                            <div className="control-right-btn gray-default-disable-btn">
                                <p>SET ROUTE</p>
                            </div>
                        </div>
                    }
                    <div className="btn-wrapper">
                        <div className="control-left-btn gray-default-btn">
                            <p>-</p>
                        </div>
                        <div className="control-right-btn gray-default-btn"
                            onClick={onClickMissionSend}>
                            <p>SEND MISSION</p>
                        </div>
                    </div>
                    {droneFlightStatus === "IN FLIGHT" ?
                        (
                            <div className="btn-wrapper">
                                {/* 비활성화 */}
                                <div className="control-left-btn gray-default-disable-btn">
                                    <p>ARMED</p>
                                </div>
                                <div className="control-right-btn gray-default-disable-btn">
                                    <p>TAKE OFF</p>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className="btn-wrapper">
                                <div className="control-left-btn gray-default-btn"
                                    onClick={onClickARMED}>
                                    <p>ARMED</p>
                                </div>
                                <div className="control-right-btn gray-default-btn"
                                    onClick={onClickTakeOff}>
                                    <p>TAKE OFF</p>
                                </div>
                            </div>
                    )}
                    <div className="btn-wrapper">
                        <div className="control-left-btn gray-default-btn"
                            onClick={onClickPause}>
                            <p>PAUSE</p>
                        </div>
                        <div className="control-right-btn gray-default-btn"
                            onClick={onClickAuto}>
                            <p>AUTO</p>
                        </div>
                    </div>
                    {droneMode !== "AUTO" ?
                        <div className="btn-wrapper">
                            <div className="control-servo-left-btn gray-default-btn"
                                onClick={() => onClickServo(2)}>
                                <p>UP</p>
                            </div>
                            <div className="control-servo-center-btn gray-default-btn"
                                onClick={() => onClickServo(3)}>
                                <p>STOP</p>
                            </div>
                            <div className="control-servo-right-btn gray-default-btn"
                                onClick={() => onClickServo(1)}>
                                <p>DOWN</p>
                            </div>
                        </div>
                        :
                        <div className="btn-wrapper">
                            <div className="control-servo-left-btn gray-default-disable-btn">
                                <p>UP</p>
                            </div>
                            <div className="control-servo-center-btn gray-default-disable-btn">
                                <p>STOP</p>
                            </div>
                            <div className="control-servo-right-btn gray-default-disable-btn">
                                <p>DOWN</p>
                            </div>
                        </div>
                    }
                    <div className="btn-wrapper">
                        <div className="control-left-btn gray-default-btn"
                            onClick={onClickLand}>
                            <p>LAND</p>
                        </div>
                        <div className="control-right-btn gray-default-btn"
                            onClick={onClickRTL}>
                            <p>RTL</p>
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <div className="control-left-btn gray-default-btn"
                            onClick={onClickReturn}>
                            <p>RETURN</p>
                        </div>
                        <div className="control-right-btn gray-warning-btn"
                            onClick={onClickEmergency}>
                            <p>EMERGENCY</p>
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <div className="control-left-btn gray-default-btn"
                            onClick={onClicFollow}>
                            <p>FOLLOW</p>
                        </div>
                        <div className="control-right-btn gray-default-btn"
                            onClick={onClickUnFollow}>
                            <p>UN FOLLOW</p>
                        </div>
                    </div>
                </S.ControlBtnWrap>
            )}
        </S.Wrap>
    );
}

export default DroneActionBtn;