import { combineReducers } from "redux";
import map from "./reducer/map";
import drone from "./reducer/drone";
import video from "./reducer/video";
import drag from "./reducer/drag";
import droneSetting from "./reducer/droneSetting";
import overlay from "./reducer/overlay";
import skycook from "./reducer/skycook";

const rootReducer = combineReducers({
  map,
  drone,
  video,
  drag,
  droneSetting,
  overlay,
  skycook,
});

export default rootReducer;