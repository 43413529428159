import { createLabel, createLineToGround, removeModel, deleteMaker, getHeight, positionModel, setCameraPositionAndOrientation, updateGroundDroneCircle, updateLineToGround } from "../components/Function/Cesium";

import whiteCircle from "../gcs_image/common/white-circle.svg";
import greenCircle from "../gcs_image/common/green-circle.svg";
import redCircle from "../gcs_image/common/red-circle.svg";
import { createModel } from "../components/Function/Cesium";
import { click } from "@testing-library/user-event/dist/click";

export class DroneWebSocket extends WebSocket {
    constructor(url) {
        super(url);
        this.addEventListener("message", this.onmessage); //onmessage에 add를 함으로써 덮어씌워지는것이 아닌 추가 되도록
    }

    drones = [];
    packages = [];
    droneInfoList = [];

    // droneName = "";
    isConnect = false;
    interval;
    drone_serial_number = "";
    // isInit = false;
    homePosition = {
        latitude: "",
        longitude: "",
        altitude: "",
    };

    flight_drone_count = 0;
    ready_drone_count = 0;
    serialNumber = 0;

    vw;
    map;
    marker = [];

    message = [];

    clickRealTimePosition = false;
    clickFollow = false;

    onerror = (error) => {
        //에러 발생 시
        this.isConnect = false;
        clearInterval(this.interval);
    };
    onopen = () => {
        //소켓 오픈 시
        this.isConnect = true;
        this.send("");
    };

    onclose = () => {
        //소켓 연결 종료 시
        this.isConnect = false;
        clearInterval(this.interval);
    };
    
    onmessage = async (event) => {
        let data = `${event.data}`;
        let socketNumber = data.substring(0, 2);
        let socketString = data.substring(2);
    
        switch (socketNumber) {
            case "00":
                this.handleSocket00(socketString);
                break;
            case "10":
                this.handleSocket10(data);
                break;
            case "99":
                this.handleSocket99(data);
                break;
            default:
                console.log(`Unknown socket number: ${socketNumber}`);
        }
    }
    
    handleSocket00(socketString) {
        let flightStatusCount = 0;
        let readyStatusCount = 0;
        let prevDrones = [...this.drones];
        let droneDatas = JSON.parse(socketString);
        if (droneDatas.text.length > 0) {
            this.message = droneDatas.text;
        }
        
        droneDatas.drones.forEach((data) => {
            let drone = this.parseDroneData(data);
        
            if (drone.droneStatus.flightStatus === "") {
                readyStatusCount++;
            } else {
                flightStatusCount++;
            }
    
            // `drone_serial_number`로 기존 드론 찾기
            let existingIndex = this.drones.findIndex(d => d.drone_serial_number === drone.drone_serial_number);
    
            if (existingIndex !== -1) {
                this.drones[existingIndex] = drone;
            } else {
                // 없으면 새 드론 추가
                this.drones.push(drone);
            }
        });
        
        this.flight_drone_count = flightStatusCount;
        this.ready_drone_count = readyStatusCount;
        this.updateDroneMarkers(prevDrones, this.drones);
    }
    
    handleSocket10(data) {
        try {
            let parse_data = data.split("||");
            let drone_serial_number = parse_data.pop();
            let socketString = parse_data.join("").substring(2);
    
            let idx = this.drones.findIndex(obj => obj.drone_serial_number === drone_serial_number);
            if (idx !== -1) {
                this.drones[idx].missionData = JSON.parse(socketString);
            }
        } catch (e) {
            console.log(`[Error] Drone socketNumber=10: ${e}`);
        }
    }
    
    handleSocket99(data) {
        try {
            let parse_data = data.split("||");
            let drone_serial_number = parse_data.pop();
            let socketString = parse_data.join("").substring(2);
    
            let idx = this.drones.findIndex(obj => obj.drone_serial_number === drone_serial_number);
    
            if (socketString === "None") {
                this.send(`99||${drone_serial_number}`);
                this.drones[idx].homePosition = { latitude: 0, longitude: 0, altitude: 0 };
            } else {
                let [latStr, lngStr] = socketString.split(",");
                let latitude = latStr.split("=")[1];
                let longitude = lngStr.split("=")[1];
                let altitude = getHeight(latitude, longitude);
    
                this.drones[idx].homePosition = { latitude, longitude, altitude };
            }
        } catch (e) {
            console.log(`[Error] Drone socketNumber=99: ${e}`);
        }
    }
    
    parseDroneData(data) {
        let drone = {
            drone_serial_number: data.data.drone_serial_num,
            dronePort: data.data.drone_port,
            battery: {
                current: data.data.battery_current,
                voltage: data.data.battery_voltage,
            },
            dronePosition: {
                latitude: data.data.lat,
                longitude: data.data.lng,
                altitude: data.data.alt,
            },
            gps: {
                satellite: data.data.gps_num_sat,
                hdop: data.data.gps_hdop,
                fix: data.data.gps_fix,
            },
            droneStatus: {
                distanceHome: data.data.distance_home,
                yaw: data.data.yaw,
                pitch:data.data.pitch,
                roll:data.data.roll,
                airSpeed: data.data.air_speed,
                isArm: data.data.is_arm,
                mode: data.data.mode,
                status: data.data.system_status,
                flightStatus: data.data.drone_status,
                wp_dist: data.data.wp_dist,
            },
            ekf: {
                ekf: data.data.ekf,
                ekf_ok: data.data.ekf_ok,
            },
            message: data.text,
            homePosition: {
                latitude: 0,
                longitude: 0,
                altitude: 0,
            },
            drone_type: data.data.drone_type,
        };
        
        let droneName = this.getDroneName(data.data.drone_serial_num);
        if (droneName) {
            drone.droneName = droneName;
        }
    
        if (this.drone_serial_number === "") {
            this.drone_serial_number = data.data.drone_serial_num;
        }
    
        if (data.data.drone_home_position !== "None") {
            const altitude = getHeight(data.data.drone_home_position.latitude, data.data.drone_home_position.longitude);
            drone.homePosition = {
                latitude: data.data.drone_home_position.latitude,
                longitude: data.data.drone_home_position.longitude,
                altitude,
            };
        }
    
        if (data.mission_data !== "None") {
            drone.missionData = JSON.parse(data.data.mission_data);
        }
    
        return drone;
    }

    async removePrevMarker(prev, crnt) {
        let tmp = prev;
        crnt.map((crntDrone) => {
            const idx = prev.findIndex((obj) => obj.drone_serial_number === crntDrone.drone_serial_number);
            if (idx >= 0) {
                tmp.splice(idx, 1);
            }
        });
       
        if (tmp.length > 0) {
            tmp.map((tmpDrone) => {
                let idx = this.marker.findIndex((obj) => obj.drone_serial_number === tmpDrone.drone_serial_number);
                removeModel(this.marker[idx].marker);
            })
        }
    }
    
    updateDroneMarkers(prevDrones, curDrones) {
        if (curDrones.length === 0) {
            return; // 비어 있는 경우 추가 작업을 수행하지 않음
        }
        this.removePrevMarker(prevDrones, curDrones);
        this.createDroneMarker(curDrones)
    }
    

    async createDroneMarker(drones) {
        let markerList = []
        this.marker.forEach(data => {
            if (!markerList.find(marker => marker.drone_serial_number === data.drone_serial_number)) {
                deleteMaker(data.markerLabel);
            }
        });

        drones.forEach(data => {
            if (data.dronePosition.latitude !== "0.0" && data.dronePosition.longitude !== "0.0" && data.homePosition !== undefined) {
                const markerData = this.createMarkerData(data);
                markerList.push(markerData);
            }
        });

   
        if (this.clickFollow === true) {
            let idx = drones.findIndex(data => data.drone_serial_number === this.serialNumber)
            let terrianAltitude = Number(drones[idx].homePosition.altitude) + Number(drones[idx].dronePosition.altitude);

            
            let position = {
                latitude: drones[idx].dronePosition.latitude,
                longitude: drones[idx].dronePosition.longitude,
                altitude: terrianAltitude,
            }

            let orientation = {
                heading: parseFloat(drones[idx].droneStatus.yaw),
                pitch: drones[idx].droneStatus.pitch,
                roll: drones[idx].droneStatus.roll
            }
        
            setCameraPositionAndOrientation(position, orientation)
        }
    
        this.marker = markerList;
    }
    
    createMarkerData(data) {
        let markerData = { drone_serial_number: data.drone_serial_number, marker: "", markerLabel: "", entitie: "" };
        let altitude = getHeight(data.dronePosition.latitude, data.dronePosition.longitude);
        let terrian = Number(data.homePosition.altitude) - altitude + Number(data.dronePosition.altitude);
        let terrianAltitude = Number(data.homePosition.altitude) + Number(data.dronePosition.altitude);
    
        const idx = this.marker.findIndex(obj => obj.drone_serial_number === data.drone_serial_number);
    
        if (idx >= 0) {
            let position = {
                lat: data.dronePosition.latitude,
                lng: data.dronePosition.longitude,
                alt: terrianAltitude,
            };
    
            markerData.entitie = updateLineToGround(
                data.dronePosition.latitude,
                data.dronePosition.longitude,
                terrian,
                this.marker[idx].entitie,
                data.droneStatus.flightStatus === "" ? `#FFFFFF` : data.droneStatus.flightStatus === "IN FLIGHT" ? '#B5FF15' :  `#FA082D`
            );
    
            markerData.groundCircle = updateGroundDroneCircle(
                data.dronePosition.latitude,
                data.dronePosition.longitude,
                data.droneStatus.flightStatus === "" ? whiteCircle : data.droneStatus.flightStatus === "IN FLIGHT" ? greenCircle : redCircle,
                this.marker[idx].groundCircle
            );
            
            if (this.marker[idx].drone_serial_number !== "" && this.marker[idx].marker === "") {
                markerData.marker = createModel(data.drone_serial_number, position);
                markerData.markerLabel = createLabel(markerData.marker, `${data.droneName} ${data.dronePosition.altitude.toFixed(1)}m`, this.marker[idx].markerLabel);
            } else {
                positionModel(this.marker[idx].marker, position, Number(data.droneStatus.yaw));
                markerData.marker = this.marker[idx].marker;
            }
    
            this.marker.splice(idx, 1);
        }

        return markerData;
    }
    
    isDroneHasPackage(selectedDroneSN) {
        try {
            let isExist = this.packages.findIndex((obj) => obj.droneSN === selectedDroneSN);
            if (isExist === -1) {
                return false
            } else {
                return true
            }
        } catch (e) {
            console.log(`[Error] Drone isDroneHasPackage : ${e}`)
            console.log(`this.packages : ${this.packages}`)
            console.log(`selectedDroneSN : ${selectedDroneSN}`)
        }
    }

    removePackage(selectedDroneSN) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === selectedDroneSN);
            this.packages.splice(index, 1);
            return;
        } catch (e) {
            console.log(`[Error] Drone removePackage : ${e}`)
            console.log('this.packages: ', this.packages)
            console.log(`selectedDroneSN : ${selectedDroneSN}`)
        }
    }

    isOrderInPackage(selectedOrder) {
        try {
            let result = false;
            this.packages.map((packagee) => {
                const index = packagee.orderList.findIndex((obj) => obj.orderNumber === selectedOrder);
                if (index !== -1) {
                    result = true;
                    return 0;
                }
            })
            return result;
        } catch (e) {
            console.log(`[Error] Drone isOrderInPackage : ${e}`)
            console.log('this.packages: ', this.packages)
            console.log(`selectedOrder : ${selectedOrder}`)
        }
    }

    getDronePackage(selectedDroneSN) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === selectedDroneSN);
            return this.packages[index];
        } catch (e) {
            console.log(`[Error] Drone getDronePackage : ${e}`)
            console.log('this.packages: ', this.packages)
            console.log(`selectedDroneSN : ${selectedDroneSN}`)
        }
    }

    getOrderNumberListInPackage(serialNumber) {
        try {
            const index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            
            let order_number_list = [];
            this.packages[index].orderList.map((order) => {
                order_number_list.push(order.order_number);
            })
            return order_number_list;
        } catch (e) {
            console.log(e);
        }
    }

    getDepartureStationName(serialNumber) {
        try {
            const index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);

            return this.packages[index].startStation;
        } catch (e) {
            console.log(e);
        }
    }

    getDestinationStationName(serialNumber) {
        try {
            const index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);

            return this.packages[index].destination;
        } catch (e) {
            console.log(e);
        }
    }

    getDroneInfo(selectedDroneId) {
        try {
            let index = this.droneInfoList.findIndex((obj) => obj.id === selectedDroneId);
            return this.droneInfoList[index];
        } catch (e) {
            console.log(`[Error] getDroneInfo : ${e}`)
        }
    }

    getDroneName(serialNumber) {
        try {
            const index = this.droneInfoList.findIndex((obj) => obj.serial_number === serialNumber);
            return this.droneInfoList[index].nickname;
        } catch (e) {
            return null
        }
    }

    getPackageDeliveryComplete(serialNumber) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                return this.packages[index].is_delivery_complete;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }

    getEmergencyActive(serialNumber) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                return this.packages[index].is_emergency_active;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }

    getPackageEstimateTime(serialNumber) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                return this.packages[index].estimatedTakenTime;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }

    getPackageDepartureTime(serialNumber) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                return this.packages[index].launchTime;
            }
        } catch (e) {
            console.log(e);
        }
    }

    getPackageRoute(serialNumber) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                return this.packages[index].route;
            }
        } catch (e) {
            console.log(e);
        }
    }

    getFlightDroneCount() {
        return this.flight_drone_count;
    }

    getReadyDroneCount() {
        return this.ready_drone_count;
    }

    getMessage() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].message;
            } else {
                return this.message;
            }
        } catch (e) {
            console.log(`[Error] Drone getMessage : ${e}`)
            return this.message;
        }
        // return this.message;
    }

    getEkf() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].efk;
            } else {
                return this.ekf;
            }
        } catch (e) {
            console.log(`[Error] Drone getEkf : ${e}`)
            return this.ekf;
        }
    }
    getDroneStatus() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].droneStatus;
            } else {
                return this.droneStatus;
            }
        } catch (e) {
            console.log(`[Error] Drone getDroneStatus : ${e}`)
            return this.droneStatus;
        }
    }

    getDronePosition() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].dronePosition;
            } else {
                return this.dronePosition;
            }
        } catch (e) {
            console.log(`[Error] Drone getDronePosition : ${e}`)
            return this.dronePosition;
        }
    }
    getGps() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].gps;
            } else {
                return this.gps;
            }
        } catch (e) {
            console.log(`[Error] Drone getGps : ${e}`)
            return this.gps;
        }
    }

    getBattery() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].battery;
            } else {
                return this.battery;
            }
        } catch (e) {
            console.log(`[Error] Drone getBattery : ${e}`)
            return this.battery;
        }
    }

    getPowerpack() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].powerpack;
            } else {
                return this.powerpack;
            }
        } catch (e) {
            console.log(`[Error] Drone getPowerpack : ${e}`)
            return this.powerpack;
        }
    }

    getFlightMode() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].flightMode;
            } else {
                return this.flightMode;
            }
        } catch (e) {
            console.log(`[Error] Drone getFlightMode : ${e}`)
            return this.flightMode;
        }
    }

    getIsConnect() {
        return this.isConnect;
    }

    getDroneHomePosition() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].homePosition;
            } else {
                return this.homePosition;
            }
        } catch (e) {
            console.log(`[Error] Drone getDroneHomePosition : ${e}`)
            return this.homePosition;
        }
    }

    getTerrainAltitude() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0 && this.drones[idx].homePosition != undefined) {
                return (
                    Number(this.drones[idx].dronePosition.altitude) + Number(this.drones[idx].homePosition.altitude)
                ).toFixed(2);
            } else {
                return (Number(this.dronePosition.altitude) + Number(this.homePosition.altitude)).toFixed(2);
            }
        } catch {
            return (Number(this.dronePosition.altitude) + Number(this.homePosition.altitude)).toFixed(2);
        }
    }
    getMissionData() {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                return this.drones[idx].missionData;
            } else {
                return this.missionData;
            }
        } catch (e) {
            console.log(`[Error] Drone getMissionData : ${e}`)
            return this.missionData;
        }
    }

    getSocketInterval() {
        return this.interval;
    }
    
    getFireFightingdrone() {
        try{
            let idx = this.drones.findIndex((obj) => obj.is_detect === false);
            return this.drones[idx];
        } catch (e) {
            console.log(`[Error] getFireFightingdrone : ${e}`)
            return "None"
        }
    }

    getRealTimePostition() {
        return this.clickRealTimePosition;
    }

    //setter
    setMap(map) {
        this.map = map;
    }
    setVw(vw) {
        this.vw = vw;
    }
    setIsConnect(bool) {
        this.isConnect = bool;
    }
    setSocketInterval(interval) {
        this.interval = interval;
    }
    setSerialNumber(serial_number) {
        this.serialNumber = serial_number

    }
    setMissionData(missionData) {
        try {
            if (this.drones.length > 0) {
                let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
                this.drones[idx].missionData = missionData;
            } else {
                this.missionData = missionData;
            }
            this.missionData = missionData;
        } catch (e) {
            console.log(`[Error] Drone setMissionData : ${e}`)
            // console.log("drone remove");
        }
    }

    setOrderRoute(serialNumber, route) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            this.packages[index].route = route;
            return;
        } catch (e) {
            console.log(e);
        }
    }

    setPackageDepartureTime(serialNumber, time_string) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                this.packages[index].launchTime = time_string
            }
            return;
        } catch (e) {
            console.log(e)
        }
    }

    setPackageDeliveryComplete(serialNumber, boolean) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                this.packages[index].is_delivery_complete = boolean;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }
    setPackageEmergencyActive(serialNumber, boolean) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                this.packages[index].is_emergency_active = boolean;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }
    setPackageEstimateTime(serialNumber, time) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                this.packages[index].estimatedTakenTime = time;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }
    setPackageEstimatedArrivalTime(serialNumber, time) {
        try {
            let index = this.packages.findIndex((obj) => obj.droneSN === serialNumber);
            if (index !== -1) {
                this.packages[index].estimatedArrivalTime = time;
            }
            return;
        } catch (e) {
            console.log(e);
        }
    }

    setClickRealTimePostition(value) {
        this.clickRealTimePosition = value
    }

    setClickFollow(value) {
        this.clickFollow = value
    }
}   
