import styled from "styled-components";
import { BaseBtn } from "../../../GlobalStyled";
import fireImage from "../../../gcs_image/prediction/fire_start_button.svg"
import gridImage from "../../../gcs_image/marker/fire_point.svg"
import { useState } from "react";
import PredictionConsole from "./PredictionConsole";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createVwMarker2D } from "../../Left/Mission/MissionFunctions";
import { drawLine } from "../../Left/Mission/MissionFunctions";
import { getSlope, sortPointsClockwise, getExpenssionPoint, checkWindDir } from "./PredictionFunction";
import { getPredictAPI, sendWeightAPI } from "../../../api/prediction_apis";
import TimeScroll from "./TimeScroll";

const Container = styled.div`
  width: 100%;
`
const Btn = styled(BaseBtn)`
  display:flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 67px;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  background: ${props=>props.step===0?'linear-gradient(180deg, #FF2828 0%, #B00000 100%)':'linear-gradient(180deg, #1059F2 0%, #003FC2 100%)'};
  &:active {
    background: ${props=>props.step===0?'linear-gradient(180deg, #F75656 0%, #CD1A1A 100%)':'linear-gradient(180deg, #4480FF 0%, #1959DF 100%)'};
  }
`
const BtnImage = styled.img`
  margin-left: 3px;
`

function Prediction({data, step, setStep}){
  const {vw,map} = useSelector(state=>({
    vw : state.map.vw,
    map : state.map.map,
  }));

  const [gridPoints,setGridPoints] = useState([]);
  const [predictionPoints,setPredictionPoints] = useState([]);
  const [weight,setWeight] = useState({
    humidity:0,
    winsSpeed:0,
    slope:0,
  })
  const btnText = ['Fire prediction start','Check up'];

  const clickEvent = useEffect(() => {
    let event;
  
    if (step === 1) {
      event = (windowPosition, ecefPosition, cartographic) => {
        let newGridPoints = [...gridPoints];
        const position = {
          latitude: cartographic.latitudeDD,
          longitude: cartographic.longitudeDD,
        };
        newGridPoints.push(position);
        setGridPoints(newGridPoints);
        createVwMarker2D(vw, `grid${gridPoints.length}`, gridImage, position);
        if (newGridPoints.length > 2) {
          let linePoints = [...newGridPoints];
          linePoints = sortPointsClockwise(linePoints);
          linePoints.push(linePoints[0]);
          map.removeObjectById(`gridLine`);
          drawLine(vw, linePoints, 'gridLine');
        }
      };
      map.onClick.addEventListener(event);
    }
  
    return () => {
      if (event) {
        map.onClick.removeEventListener(event);
      }
    };
  }, [step, gridPoints, vw, map]);

  const prediction = useEffect(()=>{
    if(step===3){
      const getdata = async()=>{
        let points = [];
        let temp = [];
        for(let i=0; i<gridPoints.length; i++){
          points.push([gridPoints[i].longitude,gridPoints[i].latitude])
        }
        
        for(let i=0; i<6;i++){
          let slopes = await getSlope(points,data[i].VEC)
          let predic = await getPredictAPI(data[i].REH,data[i].WSD,slopes[0],slopes[2],slopes[3],slopes[1],i+1);
          points = getExpenssionPoint(points,predic,checkWindDir(data[i].VEC),i);
          temp.push(points);
        }
        setPredictionPoints(temp)
        // setIsLoading(false)
      }
      // setIsLoading(true)
      getdata();
    }
  },[step,gridPoints,vw,map]);
  
  const deleteImage = ()=>{
    for(let i=0; i<gridPoints.length; i++){
      map.removeObjectById(`grid${i}`);
    }
    map.removeObjectById(`gridLine`);
  }
  const nextStep = async()=>{
    if(step===3) {
      setStep(0);
      deleteImage();
      setGridPoints([]);
      setPredictionPoints([]);
    }else if(step===2){
      if (weight.humidity === "" || weight.winsSpeed === "" || weight.slope === "" ) {
        alert("Among the weights, there are non-numeric values.")
      } else {
        let result = await sendWeightAPI(weight.humidity,weight.winsSpeed,weight.slope);
        setStep(step+1);
      }
      
    }else if(step===1){
      setWeight({...weight,slope:0, humidity:0, winsSpeed:0})
      if(gridPoints.length>2){
        setStep(step+1)
      } else {
        alert("Please draw at least 3 dots where the forest fire occurred.")
      }
    }
    else setStep(step+1);
  }
  return(
    <>
      {step===3?
        <TimeScroll predictionPoints={predictionPoints}/>
      :null}
      <Container>
        {step > 0? 
          <PredictionConsole step={step} weight={weight} setWeight={setWeight}/>:null}
        <Btn
          step={step}
          onClick={()=>{nextStep()}}
        >
          {step===0?btnText[0]:btnText[1]}
          {step===0?<BtnImage src={fireImage}/>:null}
        </Btn>
      </Container>
    </>
  );
}

export default Prediction;

