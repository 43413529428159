import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { setDragOrder } from '../../../store/reducer/drag';

const S = {
    Wrap: styled.div`
        
    `,
    ArrivalWrap: styled.div`
        width: 135px;
        height: 99px;
        border-radius: 3px;
        /* border: ${props => props.isClicked ? "2px solid #004BE9" : "2px solid #201f1f"}; */
        opacity: 0.8299999833106995;
        /* background-color: #201f1f; */
        background-color: ${props => props.isArrival ? "#0137AA" : "#201f1f"};
        padding: 13px 17px;
        cursor: pointer;

        .divide-line {
            width: 100%;
            height: 0.5px;
            background-color: #494949;
            margin: 7px 0;
        }
    `,
    OrderNumberDateWrap: styled.div`
        display: flex;
        flex-direction: column;

        .order-number {
            font-size: 11.5px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
        .order-date {
            font-size: 9px;
            zoom: 0.9;
            line-height: 16px;
            color: #89a3e7;
        }
    `,
    StationInfoWrap: styled.div`
        display: flex;
        flex-direction: column;

        .station-row-wrap {
            display: flex;
            gap: 2px;

            .station-text {
                font-size: 9px;
                zoom: 0.9;
                line-height: 16px;
                color: #fff;
            }
            .station-value {
                font-size: 9px;
                zoom: 0.9;
                line-height: 16px;
                font-weight: 300;
                color: #fff;
            }
        }
    `,
    ConfirmWrap: styled.div`
        width: 135px;
        height: 99px;
        border-radius: 3px;
        opacity: 0.8299999833106995;
        background-color: #201f1f;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        padding: 13px 19px;

        .title-text {
            font-size: 17px;
            line-height: 20px;
            font-weight: 500;
            color: #fff;
            text-align: center;
        }
    `,
}

function OrderInfo({ order, selectedDroneSN, selectedOrderList, setSelectedOrderList }) {
    const dispatch = useDispatch();
    const { socket, mapClicked } = useSelector((state) => ({
        socket: state.drone.socket,
        mapClicked: state.map.mapClicked,
    }))
    const orderRef = useRef(null);
    const [isArrival, setIsArrival] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [orderTime, setOrderTime] = useState(moment(order.created_at).format('hh:mm A, MMM YY'));
    
    // const [orderNumber, setOrderNumber] = useState("DF203184231");

    useEffect(() => {
        setClicked(false);
    }, [mapClicked])

    useEffect(() => {
        function handleClickOutside(event) {
            if (orderRef.current && !orderRef.current.contains(event.target)) {
                setClicked(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [orderRef, clicked])

    return (
        <S.Wrap ref={orderRef}>
            {isArrival && (
                clicked ?
                    <S.ConfirmWrap>
                        <div className="title-text">Not arrived at Station?</div>
                        <div className="save-btn" onClick={() => {
                            if (!socket.isOrderInPackage(order.order_number)) {
                                setIsArrival(false);
                                setClicked(false);
                            }
                        }}>
                            <div>Confirm</div>
                        </div>
                    </S.ConfirmWrap>
                :
                    <S.ArrivalWrap
                        isArrival={isArrival}
                        draggable
                        onDragStart={(e) => {
                            dispatch(setDragOrder({
                                order_number: order.order_number,
                                order_weight: order.weight,
                            }))
                            // dispatch(setDragOrder(order.order_number))
                        }}
                        onClick={() => setClicked(true)}>
                        <S.OrderNumberDateWrap>
                            <div className="order-number">{order.order_number}</div>
                            <div className="order-date">{orderTime}</div>
                        </S.OrderNumberDateWrap>
                        <div className="divide-line" />
                        <S.StationInfoWrap>
                            <div className="station-row-wrap">
                                <div className="station-text">To</div>
                                <div className="station-value">{order.pick_up_station.name}</div>
                            </div>
                            <div className="station-row-wrap">
                                <div className="station-text">Package</div>
                                <div className="station-value">{order.order_menu}</div>
                            </div>
                        </S.StationInfoWrap>
                    </S.ArrivalWrap>
            )}
            {!isArrival && (
                clicked ?
                    <S.ConfirmWrap>
                        <div className="title-text">Arrived at Station?</div>
                        <div className="save-btn" onClick={() => {
                            setIsArrival(true);
                            setClicked(false);
                        }}>
                            <div>Confirm</div>
                        </div>
                    </S.ConfirmWrap>
                :
                    <S.ArrivalWrap
                        isArrival={isArrival}
                        onClick={() => setClicked(true)}>
                        <S.OrderNumberDateWrap>
                            <div className="order-number">{order.order_number}</div>
                            <div className="order-date">{orderTime}</div>
                        </S.OrderNumberDateWrap>
                        <div className="divide-line" />
                        <S.StationInfoWrap>
                            <div className="station-row-wrap">
                                <div className="station-text">To</div>
                                <div className="station-value">{order.pick_up_station.name}</div>
                            </div>
                            <div className="station-row-wrap">
                                <div className="station-text">Package</div>
                                <div className="station-value">{order.order_menu}</div>
                            </div>
                        </S.StationInfoWrap>
                    </S.ArrivalWrap>

            )}
        </S.Wrap>
    )





    // if (isArrival) {
    //     if (clicked) {
    //         return (
    //             <S.ConfirmWrap>
    //                 <div className="title-text">Not arrived at Station?</div>
    //                 <div className="save-btn" onClick={() => {
    //                     if (!socket.isOrderInPackage(orderNumber)) {
    //                         setIsArrival(false);
    //                         setClicked(false);
    //                     }
    //                 }}>
    //                     <div>Confirm</div>
    //                 </div>
    //             </S.ConfirmWrap>
    //         )
    //     } else {
    //         return(
    //             <S.ArrivalWrap
    //                 isArrival={isArrival}
    //                 draggable
    //                 onDragStart={(e) => dispatch(setDragOrder(orderNumber))}
    //                 onClick={() => setClicked(true)}>
    //                 <S.OrderNumberDateWrap>
    //                     <div className="order-number">{orderNumber}</div>
    //                     <div className="order-date">08:10 AM, June 18</div>
    //                 </S.OrderNumberDateWrap>
    //                 <div className="divide-line" />
    //                 <S.StationInfoWrap>
    //                     <div className="station-row-wrap">
    //                         <div className="station-text">To</div>
    //                         <div className="station-value">남고산 정상</div>
    //                     </div>
    //                     <div className="station-row-wrap">
    //                         <div className="station-text">Package</div>
    //                         <div className="station-value">비빔밥 3EA</div>
    //                     </div>
    //                 </S.StationInfoWrap>
    //             </S.ArrivalWrap>
    //         )
    //     }
    // } else {
    //     if (clicked) {
    //         return (
    //             <S.ConfirmWrap>
    //                 <div className="title-text">Arrived at Station?</div>
    //                 <div className="save-btn" onClick={() => {
    //                     setIsArrival(true);
    //                     setClicked(false);
    //                 }}>
    //                     <div>Confirm</div>
    //                 </div>
    //             </S.ConfirmWrap>
    //         )
    //     } else {
    //         return (
    //             <S.ArrivalWrap
    //                 isArrival={isArrival}
    //                 onClick={() => setClicked(true)}>
    //                 <S.OrderNumberDateWrap>
    //                     <div className="order-number">{orderNumber}</div>
    //                     <div className="order-date">08:10 AM, June 18</div>
    //                 </S.OrderNumberDateWrap>
    //                 <div className="divide-line" />
    //                 <S.StationInfoWrap>
    //                     <div className="station-row-wrap">
    //                         <div className="station-text">To</div>
    //                         <div className="station-value">남고산 정상</div>
    //                     </div>
    //                     <div className="station-row-wrap">
    //                         <div className="station-text">Package</div>
    //                         <div className="station-value">비빔밥 3EA</div>
    //                     </div>
    //                 </S.StationInfoWrap>
    //             </S.ArrivalWrap>
    //         );
    //     }
    // }
}

export default OrderInfo;