import React from 'react';
import styled from 'styled-components';

const S = {
    Wrap: styled.div`
        /* width: 263px; */
        width: 502px;
        height: 553px;
        position: fixed;
        left: 40.5%;
        bottom: 30%;
        background: #272A27;
        /* 글래스1 */
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.29947);
        backdrop-filter: blur(30px);
        display: flex;
        flex-direction: column;
        z-index: 99;
    `
}

function ImageListModal({ width = "502px", children}) {
    return (
        <S.Wrap >
            {children}
        </S.Wrap>
    );
}

export default ImageListModal;