

export const createMissionObject = ({frame=3,command=0,current=0,autocontinue=0,param1=0,param2=0,param3=0,param4=0,param5=0,param6=0,param7=0})=>{
  return {
    frame: isNaN(Number(frame))?3:Number(frame), //좌표계 설정 인듯
    command: isNaN(Number(command))?0:Number(command),  // mavlink command id
    current: isNaN(Number(current))?0:Number(current), //dronkit python은 미지원
    autocontinue: isNaN(Number(autocontinue))?0:Number(autocontinue), //dronkit python은 미지원
    param1: isNaN(Number(param1))?0:Number(param1), //param1~7은 command id에 맞춰 입력
    param2: isNaN(Number(param2))?0:Number(param2), 
    param3: isNaN(Number(param3))?0:Number(param3), 
    param4: isNaN(Number(param4))?0:Number(param4), 
    param5: isNaN(Number(param5))?0:Number(param5), //5~7은 보통 lat,lng,alt로 사용되는듯
    param6: isNaN(Number(param6))?0:Number(param6), 
    param7: isNaN(Number(param7))?0:Number(param7)
  };
}

/**
 * 미션수행할 웨이포인트 추가
 * 딜레이 추가를 원할경우 setWaypoint_AddDelay 사용
 * 그외 파라미터 사용하고싶을경우 createMissionObject({...{파라미터}})으로
 * param1~7까지 직접 설정가능
 * 예) createMissionObject({...{param2 : 10, param5 : 35, param6 : 127, param7 : 20}})
 * @param {number} lat 
 * @param {number} lng 
 * @param {number} alt 
 */
export const setWaypoint = (lat,lng,alt)=>{
  return createMissionObject({...{command:16,param5:lat,param6:lng,param7:alt}});
}

/**
 *  * 웨이포인트에 딜레이 시간 추가 
 * @param {number} delay 해당 포인트에서 대기 시간
 * @param {number} lat 
 * @param {number} lng 
 * @param {number} alt 
 * @returns 
 */
export const setWaypoint_AddDelay = (delay,lat,lng,alt)=>{
  return createMissionObject({...{command:16,param1:delay,param5:lat,param6:lng,param7:alt}});
}

/**
 * 드론 비행중 헤딩 방향 고정가능
 * @param {number} lat 
 * @param {number} lng 
 * @param {number} alt 
 * @returns 
 */
export const setROI = (lat,lng,alt)=>{
  return createMissionObject({...{command:201,param5:lat,param6:lng,param7:alt}});
}

/**
 * 미션 중 속도
 * 타입 : (0=Airspeed, 1=Ground Speed, 2=Climb Speed, 3=Descent Speed)
 * @param {number} type 
 * @param {number} speed 
 * @returns 
 */
export const setSpeed = (type,speed)=>{
  return createMissionObject({...{command:178,param1:type,param2:speed}});
}

/**
 * 미션 고도로 제자리에서 이동
 * 고도 2m에서 takeOff(10) 미션 수행시 제자리에서 10m까지 상승 후 다음미션 수행
 * @param {number} alt 
 */
export const takeOff = (alt) =>{
  return createMissionObject({...{command:22,param7:alt}});
}

/**
 * 홈포지션 귀환 후 착륙
 */
export const doRTL = () =>{
  return createMissionObject({...{command:20}});
}

/**
 * 딜레이 없이 사용할 경우 웨이포인트 도착 전에 착륙
 * 원하는 좌표에서 착륙하길 원하는 경우 웨이포인트에 딜레이추가 필요
 */
export const doLand = () =>{
  return createMissionObject({...{command:21}});
}