import React from 'react';
import styled from 'styled-components';

import SystemMenu from './bottom-menu/SystemMenu';
import DroneSettingMenu from './bottom-menu/DroneSettingMenu';

const S = {
    Wrap: styled.div`
        width: 100%;
        height: 60px;
        position: fixed;
        display: flex;
        bottom: 0px;
        z-index: 99;
        padding: 0px 30px;
        background-color: #201f1f;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.60);
        left:0;
    `,
}

function BottomMenubar(props) {
    return (
        <S.Wrap>
            <SystemMenu />
            <DroneSettingMenu />
        </S.Wrap>
    );
}

export default BottomMenubar;