import styled from "styled-components";
import GuideText from "./GuideText";
import WeightSetting from "./WeightSetting";

const Container = styled.div`
  width: 100%;
`

function PredictionGuide({isOpenGuide,weight,setWeight}){

  return(
    <Container>
      {isOpenGuide?
        <GuideText />
        :<WeightSetting weight={weight} setWeight={setWeight} />}
    </Container>
  )
}

export default PredictionGuide;