import React, { useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import ImageListModal from '../../../common/ImageListModal';
import { ReactComponent as CloseIcon } from "../../../../gcs_image/common/close.svg";
import { useSelector } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";

const S = {
    TitleWrap: styled.div`
        width: 100%;
        height: 52px;
        padding: 0px 20px;
        border-bottom: 1px solid #353535;
        border-bottom: 1px solid #131212;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }

        svg {
            cursor: pointer;
           
        }
    `,
    ModalWrap: styled.div`
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        gap:24px;

        .big-image-wrap {
            width: 462px;
            height: 290px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .sub-title-text {
            display: flex;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            color: #FFFFFF;
        }
        .image-list {
            display: flex;
            width: 462px;
            gap:16px;
            overflow-x: auto;
        }
        .cancel-bnt {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 227px;
            height: 35px;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */
            color: #FFFFFF;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 2px 0px 0px 2px;
            cursor: pointer;
        }

        .confirm-bnt {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 35px;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */
            color: #FFFFFF;
            
            background: #FF6060;
            border-radius: 2px;
            cursor: pointer;
        }
    `,

    BtnWrap: styled.div`
        display: flex; 
        justify-content: space-between;
        width: 462px;
        height: 35px;
    `,
    
    SelectedImage: styled.img`
        width: 462px;
        height: 266px;

        border: 1px solid #6C6C6C;
        border-radius: 3px;
    `,

    Image: styled.img`
        width: 79.6px;
        height: 78px;
        ${({ status }) => {
            switch (status) {
                case true:
                    return css`
                        border: 2px solid #B5FF15;
                    `;
                default:
                    return css`
                        border: 1px solid #6C6C6C;
                    `;
            }
        }}
        border-radius: 3px;
        cursor: pointer;
    `,
 
}

function ReportImageModal({isModalOpen, setIsModalOpen}) {

    const {
        forest_fire_report,
        drone_report,
        is_drone_detect,
    } = useSelector((state) => ({
        forest_fire_report: state.skycook.forest_fire_report,
        drone_report: state.skycook.drone_report,
        is_drone_detect: state.video.is_drone_detect,
      }));

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        if (isModalOpen) {
            // map.addEventListener 추가
        } else {
            // map.removeEventListener
        }
    }, [isModalOpen])

    if (isModalOpen) {
        return (
            <ImageListModal>
                <S.TitleWrap>
                    <p>전체 사진</p>
                    <CloseIcon onClick={()=>setIsModalOpen(false)}/>
                </S.TitleWrap>
                <S.ModalWrap>
                    <div className='big-image-wrap'>
                        <div className='sub-title-text'>
                            {"신고자가 보낸 사진 목록 입니다."}
                        </div>
                        <S.SelectedImage src={forest_fire_report.images[selectedImageIndex].image} />
                    </div>
              
                    <ScrollContainer className='image-list'>
                        { is_drone_detect === false ? (
                            <>
                                {forest_fire_report.images.map((imageData, index) => (
                                    <S.Image key={imageData.id} src={imageData.image} alt={`Image ${index + 1}`} onClick={()=>setSelectedImageIndex(index)} status={index === selectedImageIndex}/>
                                ))}
                            </>
                        ) : (
                            <>
                                {drone_report.images.map((imageData, index) => (
                                    <S.Image key={imageData.id} src={imageData.image} alt={`Image ${index + 1}`} onClick={()=>setSelectedImageIndex(index)} status={index === selectedImageIndex}/>
                                ))}
                            </>
                        )}

                    </ScrollContainer>
                    <S.BtnWrap>
                        <div className='confirm-bnt' onClick={()=>setIsModalOpen(false)}>
                            확인
                        </div>
                    </S.BtnWrap>
                </S.ModalWrap>
            
            </ImageListModal>
        );
    }
}

export default ReportImageModal;