import React, { useState, useCallback } from "react";
import styled from "styled-components";

import Logo from "../../gcs_image/common/logo.svg";
import CloseIcon from "../../gcs_image/common/close-icon.svg";
import { loginAPI } from "../../api/skycook_apis";

const S = {
    Wrap: styled.div`
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        position: fixed;
        z-index: 99;
        left:0;
        top:0;
    `,
    LoginWrap: styled.div`
        width: 357px;
        height: 426px;
        background-color: #201F1F;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        padding: 52px 24px;

        .logo-wrap {
            width: 100%;
            min-height: 26px;
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .input-div {
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin-bottom: 40px;
        }
        .input-wrap {
            width: 100%;
            height: 56px;
            padding: 18px 21px;
            background-color: #292828;
            border: 2px solid #292828;
            display: flex;
            align-items: center;

            img {
                margin-left: auto;
                cursor: pointer;
            }
        }
        .input-wrap:focus-within {
            border: 2px solid #6f6f6f;
        }

        input {
            width: 100%;
            height: 100%;
            outline: none;
            background-color: transparent;
            border: none;
            font-size: 18px;
            line-height: 20px;
            color: #fff;
        }
    `,
    CompleteBtn: styled.div`
        width: 100%;
        height: 56px;
        margin: 0 auto;
        cursor: pointer;

        p {
            font-size: 20px;
            line-height: 20px;
        }
    `,
};

function Login({ setIsLogin }) {
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [focusIdx, setFocusIdx] = useState("");

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onClickLoginBtn();
            }
        },
        [id, pw]
    );

    const onClickLoginBtn = useCallback(async () => {
        try {
            const data = {
                email: id,
                password: pw,
            }
            const res = await loginAPI(data);

            if (res.status !== 200) {
                throw new Error();
            }

            setIsLogin(true);
        } catch (e) {
            console.log(e)
        }
    }, [id, pw]);

    return (
        <S.Wrap>
            <S.LoginWrap>
                <div className="logo-wrap">
                    <img src={Logo} alt="logo-icon" onClick={() => setIsLogin(true)} />
                </div>
                <div className="input-div">
                    <div className="input-wrap">
                        <input
                            type="text"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            onFocus={() => setFocusIdx(1)}
                            placeholder="ID"
                            onKeyDown={onKeyDown}
                            autoFocus
                        />
                        {focusIdx === 1 && id !== "" && (
                            <img src={CloseIcon}
                                width="24px"
                                height="24px"
                                alt="close-btn"
                                onClick={() => {
                                    setId("");
                            }}/>
                        )}
                    </div>
                    <div className="input-wrap">
                        <input
                            type="password"
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                            onFocus={() => setFocusIdx(2)}
                            placeholder="Password"
                            onKeyDown={onKeyDown}
                        />
                        {focusIdx === 2 && pw !== "" && (
                            <img src={CloseIcon}
                                width="24px"
                                height="24px"
                                alt="close-btn"
                                onClick={() => {
                                    setPw("");
                                }} />
                        )}
                    </div>
                </div>
                <S.CompleteBtn className="default-btn" onClick={onClickLoginBtn}>
                    <p>Log in</p>
                </S.CompleteBtn>
            </S.LoginWrap>
        </S.Wrap>
    );
}

export default Login;
