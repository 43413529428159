import React from "react";
import ReactDOM from "react-dom/client";
// import Main from "./components/Main/Main";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store";
import GlobalStyle from "./GlobalStyled";
import Main from "./pages/index";
import axios from "axios";
import "./Global.css";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

axios.defaults.headers.common["checkhost"] = "delivery_company";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = createStore(rootReducer);

root.render(
    <Provider store={store}>
        <GlobalStyle />
        <Main />
    </Provider>
);
