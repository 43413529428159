import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    .default-btn {
        border-radius: 2px;
        background-color: #004be9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        :hover,
        :focus {
            background-color: #1860f9;
        }

        p {
            color: #fff;
        }
    }
    
    .gray-default-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #6d6d6d;
        background-color: #2c2c2c;
        cursor: pointer;

        :hover,
        :focus {
            background-color: #3e3e3e;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #fff;
        }
    }
    .gray-default-disable-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #6d6d6d;
        background-color: #2c2c2c;
        opacity: 0.4;

        p {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #fff;
        }
    }
    .gray-warning-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #6d6d6d;
        background-color: #2c2c2c;
        cursor: pointer;

        :hover,
        :focus {
            background-color: #3e3e3e;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #f62a49;
        }
    }

    .default-input {
        background-color: #292828;
        border: 2px solid #292828;
        display: flex;
        align-items: center;
        padding: 7px;
        
        img {
            margin-left: auto;
            cursor: pointer;
        }

        input {
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            color: #fff;
        }
    }
    .default-input:focus-within {
        border: 2px solid #6f6f6f;
    }

    .save-btn {
        width: 67px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #004be9;
        border: 1px solid #004be9;
        border-radius: 2px;
        cursor: pointer;
        
        :hover,
        :focus {
            background-color: #1860f9;
            border: 1px solid #1860f9;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            color: #fff;
        }
    }
    .delete-btn {
        width: 67px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f62a49;
        border: 1px solid #f62a49;
        border-radius: 2px;
        cursor: pointer;
        
        :hover,
        :focus {
            background-color: #ff4e69;
            border: 1px solid #ff4e69;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            color: #fff;
        }
    }
    .cancel-btn {
        width: 67px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #636262;
        border-radius: 2px;
        cursor: pointer;
        
        :hover,
        :focus {
            border: 1px solid #3e3e3e;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            color: #fff;
        }
    }

    .default-select {
        width: 100%;
        border: none;
        outline: none;
        background-color: #292828;
        
        font-size: 14px;
        line-height: 18px;
        color: #fff;
    }
`;

export default GlobalStyle;

export const BaseBtn = styled.div`
    background: linear-gradient(180deg, #1059f2 0%, #003fc2 100%);
    border-radius: 3px;
    cursor: pointer;
    &:active {
        background: linear-gradient(180deg, #4480ff 0%, #1959df 100%);
    }
`;
export const BaseCancleBtn = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    cursor: pointer;
    &:active {
        border: 1px solid rgba(255, 255, 255, 0.16);
    }
`;

export const BaseInput = styled.input`
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #47524d;
    border-radius: 3px;
    box-sizing: border-box;
    padding-left: 20px;
`;
