import styled from "styled-components";
import { BaseInput } from "../../../GlobalStyled";

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 30px;
  /* justify-content: center; */
`
const InputContainer = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 39px;
  color: rgba(255, 255, 255, 0.8);
  display:flex;
  gap: 5px;
`
const InputWeight = styled(BaseInput)`
  margin-left: 7px;
  height: 39px;
  width: 70px;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  padding-right:7px;
`
const FlexContainer = styled.div`
  display:flex;
  margin-bottom: 10px;
  gap:15px;
  margin-right: 20px;
`
const Text = styled.span`
  display: block;
  width: 70px;
  text-align: right;

`
function WeightSetting({weight,setWeight}){

  const onChangeHumidity =(e)=> {
    const value = e.target.value;
    // Replace non-numeric input with an empty string

    if (value <= 100 && value >= -100) {
      setWeight({...weight,humidity:value})
    } else {
      alert('Only integers between minus 100 and 100 can be entered.\nPlease refer to the guide')
    }
  }

  const onChangeWindSpeed =(e)=> {
    const value = e.target.value;
    // Replace non-numeric input with an empty string
   
    if (value <= 100 && value >= -100) {
      setWeight({...weight,winsSpeed:value})
    } else {
      alert('Only integers between minus 100 and 100 can be entered.\nPlease refer to the guide')
    }
  }

  const onChangeSlope =(e)=> {
    const value = e.target.value;
  
    if (value <= 100 && value >= -100) {
      setWeight({...weight,slope:value})
    } else {
      alert('Only integers between minus 100 and 100 can be entered.\nPlease refer to the guide')
    }
  }

  return(
    <Container>
      <FlexContainer>
        <InputContainer >
          <Text>Humidity</Text>
          <InputWeight type={'number'} value={weight.humidity} onChange={onChangeHumidity}/>
        </InputContainer>
        <InputContainer style={{gap:'20px'}}>
          <Text style={{textAlign:'left'}}>WindSpeed</Text>
          <InputWeight type={'number'} value={weight.winsSpeed} onChange={onChangeWindSpeed}/>
        </InputContainer>
      </FlexContainer>
      <InputContainer>
        <Text>Slope</Text>
        <InputWeight type={'number'} value={weight.slope} onChange={onChangeSlope}/>
      </InputContainer>
    </Container>
  )
}

export default WeightSetting;