import styled from "styled-components";
import Scroll from "./Scroll";
import { useState } from "react";

const Container = styled.div`
  width: 42%;
  position: fixed;
  bottom: 17%;
  left: 50%;
  transform: translate(-50%,-17%);
`
const Timebar = styled.div`
  display: flex;
  justify-content: space-between;
`
const Time = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
`
const ScrollContainer = styled.div`
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.24);
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 100px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function TimeScroll({predictionPoints}){
  
  return(
    <Container>
      <Timebar>
        <Time></Time>
        <Time>1hour</Time>
        <Time>2hour</Time>
        <Time>3hour</Time>
        <Time>4hour</Time>
        <Time>5hour</Time>
        <Time>6hour</Time>
        <Time></Time>
        
      </Timebar>
      <ScrollContainer>
        <Scroll predictionPoints={predictionPoints}/>
      </ScrollContainer>
    </Container>
  )
}

export default TimeScroll;