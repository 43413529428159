import {getHeight} from "../../Function/Cesium";
import hull from "hull.js";

export const getExpenssionPoint = (points,predic,diretion,idx)=>{
  let mperLat = Number(process.env.REACT_APP_DISTANCE_PER_M_LAT);
  let mperLon = Number(process.env.REACT_APP_DISTANCE_PER_M_LNG);
  let result = [];
  for(let i=0;i<points.length;i++){
    for(let j=0;j<4;j++){
      let x = Number(points[i][0]);
      let y = Number(points[i][1]);
      let dir = (diretion + (j*2))%8
      let area;
      if(idx===0){
        if (j===0){
          area = Number(predic.FrontArea);
        }else if(j===2){
          area = Number(predic.BackArea);
        }else{
          area = Number(predic.SideArea);
        }
      }else{
        if(j===0){
          area = Number(predic.FrontArea[idx]);
        }else if(j===2){
          area = Number(predic.BackArea[idx]);
        }else{
          area = Number(predic.SideArea[idx]);
        }
      }
      if(dir===0){
        y = y + Number((mperLat*area));
      }else if(dir===1){
        let dis = area/Math.sqrt(2);
        x=x + (dis*mperLon);
        y=y + (dis*mperLat); 
      }else if(dir===2){
        x = x + (mperLon*area);
      }else if(dir===3){
        let dis = area/Math.sqrt(2);
        x=x + (dis*mperLon);
        y=y - (dis*mperLat);
      }else if(dir===4){
        y=y- (mperLat*area);
      }else if(dir===5){
        let dis = area/Math.sqrt(2);
        x=x - (dis*mperLon);
        y=y - (dis*mperLat);
      }else if(dir===6){
        x=x - (mperLon*area);
      }else if(dir===7){
        let dis = area/Math.sqrt(2);
        x=x - (dis*mperLon);
        y=y + (dis*mperLat);
      }
      result.push([x,y]);
    }
  }
  let hpoint = hull(result);
  
  return hpoint;
}

export const checkWindDir = (deg)=>{
  if(Number(deg) < 22.5 || Number(deg) > 337.5){
    return 0;
  }else{
    return (parseInt(((Number(deg)/22.5)-1)/2)+1);
  }
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371;
  var dLat = deg2rad(lat2-lat1);  
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; 

  return (d*1000); 
}

const calcSlope = async(point,dir) =>{
  let tragetX = point[0];
  let tragetY = point[1];
  if(5<=dir&&dir<=7) tragetY=(Number(tragetY)+0.0001);
  else if(1<=dir&&dir<=3) tragetY=(Number(tragetY)-0.0001);

  if(3<=dir&&dir<=5) tragetX=(Number(tragetX)-0.0001);
  else if(7<=dir||dir<=1) tragetX=(Number(tragetX)+0.0001);

  let homeAlt = getHeight(point[1],point[0])
  let targetAlt = getHeight(tragetY,tragetX);
  let diffAlt = Math.abs(Number(homeAlt)-Number(targetAlt)) ;
  return ((diffAlt/getDistanceFromLatLonInKm(point[1],point[0],tragetY,tragetX)) * 100)
}

const changePoint = (point)=>{
  let temp = Math.round(point * 10000);
  return (temp/10000).toFixed(4);
}

export const getCenter = (points)=>{
  const center = points.reduce((acc, cur) => {
    return { latitude: acc.latitude + cur.latitude, longitude: acc.longitude + cur.longitude };
  }, { latitude: 0, longitude: 0 });
  center.latitude /= points.length;
  center.longitude /= points.length;

  return center;
} 

export const sortPointsClockwise = (points) => {
  // const center = points.reduce((acc, cur) => {
  //   return { latitude: acc.latitude + cur.latitude, longitude: acc.longitude + cur.longitude };
  // }, { latitude: 0, longitude: 0 });
  // center.latitude /= points.length;
  // center.longitude /= points.length;
  const center = getCenter(points)
  // 각도 계산
  const angles = points.map(point => {
    const angle = Math.atan2(point.longitude - center.longitude, point.latitude - center.latitude);
    return angle < 0 ? angle + 2 * Math.PI : angle; // 각도를 0~2π 범위로 조정
  });

  // 각도를 기준으로 정렬
  const sortedPoints = points.slice().sort((a, b) => angles[points.indexOf(a)] - angles[points.indexOf(b)]);

  return sortedPoints;
}

export const getSlope = async(points,winddir)=>{
  const center = getCenter(points);
  let lon = changePoint(center.longitude);
  let lat = changePoint(center.latitude);
  let result = []
  for(let i=0;i<4;i++){
  // for(let i=0;i<points.length;i++){
    // let head = (checkWindDir(data[idx].VEC) + (i*2))%8;
    let head = (checkWindDir(winddir) + (i*2))%8;
    let res = await calcSlope([lon,lat],head);
    result.push(res.toFixed(2))
  }
  return(result);
}