import React, {useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import CloseIcon from "../../../../gcs_image/common/close-icon.svg";
import BottomMenuModal from '../../../common/BottomMenuModal';
import { createDroneAPI, getDroneListAPI, updateDroneAPI } from '../../../../api/skycook_apis';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { ReactComponent as NoticeIcon } from "../../../../gcs_image/common/notice-critical-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../gcs_image/common/check-circle-icon.svg";

const S = {
    TitleWrap: styled.div`
        width: 100%;
        height: 52px;
        padding: 21px 15px;
        border-bottom: 1px solid #353535;
        display: flex;
        align-items: center;

        p {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
    `,
    ModalWrap: styled.div`
        display: flex;
        flex-direction: column;

        .tap-wrap {
            display: flex;
        }
    `,
    Tap: styled.div`
        width: 50%;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: ${props => props.selectedTap === props.tapName ? "1px solid #004be9" : ""};
        cursor: pointer;

        p {
            font-size: 12px;
            line-height: 16px;
            color: ${props => props.selectedTap === props.tapName ? "#004be9" : "#fff"};
        }
    `,
    ModalInputWrap: styled.div`
        padding: 18px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-row-wrap {
            display: flex;
            align-items: center;

            p {
                width: 92px;
                font-size: 12px;
                line-height: 18px;
                color: #b5b5b5;
            }

            .input-wrap {
                width: 138px;
                height: 31px;
            }
            .select-wrap {
                width: 138px;
                height: 31px;
                background-color: #292828;
                display: flex;
                align-items: center;
                padding: 6.5px 11px;
            }

            select {
                cursor: pointer;
            }
        }
        .btn-wrap {
            display: flex;
            gap: 5px;
            margin-left: auto;
            margin-top: 2px;
        }
    `,
}

function AirframeModal({ isModalOpen, setIsModalOpen }) {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }))
    const [selectedTap, setSelectedTap] = useState("registration");     // registration, modify
    const [serialNumber, setSerialNumber] = useState("");
    const [droneName, setDroneName] = useState("");
    const [selectedDrone, setSelectedDrone] = useState("");

    useEffect(() => {
        setSerialNumber("");
        setDroneName("");
        if (!!socket && selectedTap === "modify" && selectedDrone !== "") {
            const saved_drone = socket.getDroneInfo(Number(selectedDrone));
            setSerialNumber(saved_drone.serial_number);
            setDroneName(saved_drone.nickname);
        }
    }, [selectedDrone, selectedTap])

    const getDroneList = useCallback(async (type="") => {
        try {
            const listRes = await getDroneListAPI();
            socket.droneInfoList = listRes.data;

            if (type === "delete") {
                if (socket.droneInfoList.length > 0) {
                    setSelectedDrone(socket.droneInfoList[0].id);
                } else {
                    setSelectedDrone("");
                    setDroneName("");
                    setSerialNumber("");
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [socket])

    const onClickModifySave = useCallback(async () => {
        try {
            if (droneName.length > 10) {
                toast.success(`The drone name should be less than 10 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (serialNumber.length > 20) {
                toast.success(`The serial number should be less than 20 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            const data = {
                drone_id: selectedDrone,
                type: "update",
                serial_number: serialNumber,
                nickname: droneName,
            }
            const updateRes = await updateDroneAPI(data);

            if (updateRes.status !== 200) {
                throw new Error();
            } else {
                toast.success(`The aircraft is updated.`, {
                    icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
                getDroneList();

            }
        } catch (e) {
            console.log(`error: ${e}`)
        }
    }, [selectedDrone, serialNumber, droneName, getDroneList])

    const onClickDelete = useCallback(async () => {
        try {
            const data = {
                drone_id: selectedDrone,
                type: "delete",
            }
            const deleteRes = await updateDroneAPI(data);
            
            if (deleteRes.status !== 200) {
                throw new Error();
            } else {
                toast.success(`The aircraft is removed.`, {
                        icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                getDroneList("delete");
            }
        } catch (e) {
            console.log(`error: ${e}`)
        }
    }, [selectedDrone, getDroneList])

    const onClickCreate = useCallback(async () => {
        try {
            if (droneName.length > 10) {
                toast.success(`The drone name should be less than 10 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (serialNumber.length > 20) {
                toast.success(`The serial number should be less than 20 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            const data = {
                nickname: droneName,
                serial_number: serialNumber
            }
            const createRes = await createDroneAPI(data);

            if (createRes.status !== 201) {
                throw new Error();
            } else {
                toast.success(`The aircraft is added.`, {
                    icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
                getDroneList();
                
                setSerialNumber("");
                setDroneName("");
            }
        } catch (e) {
            console.log(`error: ${e}`)
        }
    }, [droneName, serialNumber, getDroneList])

    const onClickCancel = useCallback(() => {
        init();
        setIsModalOpen({
            ...isModalOpen,
            airframe: false,
        })
    }, [])

    const onClickModifyTap = useCallback(() => {
        setSelectedTap("modify")

        // 초기화
        if (!!socket && socket.droneInfoList.length > 0) {
            setSelectedDrone(socket.droneInfoList[0].id);
        } else {
            setSelectedDrone("");
        }
    }, [socket])

    const init = useCallback(() => {
        setSelectedTap("registration");
        setSerialNumber("");
        setDroneName("");
    }, [])

    useEffect(() => {
        init();
    }, [isModalOpen.airframe, init])

    if (isModalOpen.airframe) {
        return (
            <BottomMenuModal>
                <S.TitleWrap>
                    <p>AIRCRAFTS</p>
                </S.TitleWrap>
                <S.ModalWrap>
                    <div className="tap-wrap">
                        <S.Tap selectedTap={selectedTap} tapName={"registration"} onClick={() => setSelectedTap("registration")}>
                            <p>Registration</p>
                        </S.Tap>
                        <S.Tap selectedTap={selectedTap} tapName={"modify"} onClick={onClickModifyTap}>
                            <p>Modify</p>
                        </S.Tap>
                    </div>

                    {selectedTap === "registration" ?
                        <S.ModalInputWrap>
                            <div className="input-row-wrap">
                                <p>Drone name</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={droneName}
                                        onChange={e => setDroneName(e.target.value)}
                                        autoFocus />
                                    {droneName !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setDroneName("");
                                        }}/>
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Serial number</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={serialNumber}
                                        onChange={e => setSerialNumber(e.target.value)} />
                                    {serialNumber !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setSerialNumber("");
                                        }}/>
                                    )}
                                </div>
                            </div>

                            <div className="btn-wrap">
                                <div className="cancel-btn" onClick={onClickCancel}>
                                    <p>Cancel</p>
                                </div>
                                <div className="save-btn" onClick={onClickCreate}>
                                    <p>Save</p>
                                </div>
                            </div>
                        </S.ModalInputWrap>
                        :
                        <S.ModalInputWrap>
                            <div className="input-row-wrap">
                                <p>Aircraft list</p>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        value={selectedDrone}
                                        onChange={(e) => setSelectedDrone(e.target.value)}>
                                        {!!socket && socket?.droneInfoList.map((drone) => {
                                            return (
                                                <option value={drone.id}
                                                    key={`drone${drone.serial_number}`}>
                                                    {drone.nickname}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Drone name</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={droneName}
                                        onChange={e => setDroneName(e.target.value)} />
                                    {droneName !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setDroneName("");
                                        }}/>
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Serial number</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={serialNumber}
                                        onChange={e => setSerialNumber(e.target.value)} />
                                    {serialNumber !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setSerialNumber("");
                                        }}/>
                                    )}
                                </div>
                            </div>

                            <div className="btn-wrap">
                                <div className="cancel-btn" onClick={onClickCancel}>
                                    <p>Cancel</p>
                                </div>
                                <div className="delete-btn" onClick={onClickDelete}>
                                    <p>Delete</p>
                                </div>
                                <div className="save-btn" onClick={onClickModifySave}>
                                    <p>Save</p>
                                </div>
                            </div>
                        </S.ModalInputWrap>
                    }

                </S.ModalWrap>
            </BottomMenuModal>
        );
    }
}

export default AirframeModal;