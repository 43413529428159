import React from 'react';
import styled from 'styled-components';
import FireReportInfo from './forest-fire-report/FireReportInfo';
import { useSelector } from "react-redux";
const S = {
    Wrap: styled.div`
        width: 390px;
        height: 483px;
        background-color: #201f1f;
        opacity: 0.9700000286102295;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.30);
        backdrop-filter: blur(30px);
    `,
}

function FilreReportView({setIsModalOpen}) {
    return (
        <S.Wrap >
            <FireReportInfo setIsModalOpen={setIsModalOpen}/>
        </S.Wrap>
    );
}

export default FilreReportView;