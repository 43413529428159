import React, {useEffect, useCallback, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import DroneNotPackageInfo from './not-packaged/DroneNotPackageInfo';
import DronePackaging from './packaging/DronePackaging';
import { setDragDrone, setDragOrder } from '../../../store/reducer/drag';
import DronePackaged from './DronePackaged';
import { createVwMarker2DLabel, createVwMarker3D } from '../../Left/Mission/MissionFunctions';
import EmergencyIcon from "../../../gcs_image/marker/emergency-icon.svg";
import { createEmergencyPath, createGroundFlightPath, deleteEntitie, getHeight } from '../../Function/Cesium';
import { setEmergencyLineOverlay, setGroundFlightPath, setWaypointCount } from '../../../store/reducer/overlay';
import { toast } from 'react-toastify';
import { ReactComponent as NoticeIcon } from "../../../gcs_image/common/notice-critical-icon.svg";
import { ReactComponent as CheckIcon } from "../../../gcs_image/common/check-circle-icon.svg";

import pointMarker from "../../../gcs_image/marker/waypoint.svg";
import stationMarker from "../../../gcs_image/marker/station-icon.svg";
import destinationMarker from "../../../gcs_image/marker/destination-icon.svg";
import { ReactComponent as MedicalDroneIcon } from "../../../gcs_image/common/medical-drone.svg";
import { ReactComponent as FireDroneIcon } from "../../../gcs_image/common/fire-drone.svg";
import { cancelOrderPackageAPI, changeOrderStatusAPI , updateReportStatus } from '../../../api/skycook_apis';

const fadeIn = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`
const slideUp = keyframes`
    from {
        transform: translateY(5px);
        opacity: 0;
    } to {
        transform: translateY(0px);
        opacity: 1;
    }
`

const S = {
    Wrap: styled.div`
        width: 100%;
        animation: ${slideUp} 0.3s linear;
    `,
    TitleWrap: styled.div`
        display: flex;
        align-items: center;
        padding: 12px 19px 10px 19px;
        border-bottom: 1px solid #131212;
        border-top: 1px solid #131212;

        .flight-mode {
            margin-left: auto;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            line-height: 22px;
            color: #FFFFFF80;
        }
        /* .wait-state {
            color: #fff;
        }
        .flight-state {
            color: #b5ff15;
        }
        .emergency-state {
            color: #fa082d;
        }

        img {
            margin-left: auto;
        } */
    `,

    DroneName: styled.div` 
        display: flex;
        width:fit-content;
        gap:10px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
    `,
    DroneInfoWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 17px 20px;
        border-radius: 3px;
        /* border: ${props => props.selectedDroneSN === props.droneSN ? "2px solid #004be9" : "2px solid #151515" }; */
        border: ${props => props.flightStatus === "" ? props.selectedDroneSN === props.droneSN ? "2px solid #004be900" : "2px solid #151515": props.flightStatus === "IN FLIGHT" ? "2px solid #B5FF15" : "2px solid #FA082D"};
        background-color: ${props => props.selectedDroneSN === props.droneSN ? "#004be933" : "#151515"};
        /* padding: 13px; */
    `,
}

function DroneInfo({ drone, index, selectedOrderList, setSelectedOrderList, selectedDroneSN, setSelectedDroneSN }) {
    const dispatch = useDispatch();
    const [isEmergency, setIsEmergency] = useState(false);
    const { vw, map, socket, dragDrone, dragOrder, emergencyLineOverlay, orderList, routeLineOverlay, waypointCount, groundFlightPath, delivery_status, forest_fire_report } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
        dragDrone: state.drag.dragDrone,
        dragOrder: state.drag.dragOrder,
        emergencyLineOverlay: state.overlay.emergencyLineOverlay,
        orderList: state.skycook.orderList,
        routeLineOverlay: state.overlay.routeLineOverlay,
        waypointCount: state.overlay.waypointCount,
        groundFlightPath: state.overlay.groundFlightPath,
        delivery_status: state.skycook.delivery_status,
        forest_fire_report: state.skycook.forest_fire_report,
    }));

    useEffect(() => {
        if (drone.drone_serial_number === selectedDroneSN) {
            // 클릭하면 카메라 이동
            const movePo = new vw.CoordZ(drone.dronePosition.longitude, drone.dronePosition.latitude, getHeight(drone.dronePosition.latitude, drone.dronePosition.longitude) + 200);
            // vw.Direction(heading, tilt, roll)
            const mPosi = new vw.CameraPosition(movePo, new vw.Direction(210, -45, 0));
            map.moveTo(mPosi);
            // 경로 표시
            drawPackageRoute();
        }
    }, [selectedDroneSN])

    const drawPackageRoute = useCallback(() => {
        initOverlay();

        dispatch(setGroundFlightPath(null));

        const drone_package = socket.getDronePackage(drone.drone_serial_number);

        if (!!drone_package && !!drone_package?.route.route) {

            // 웨이포인트 마커 생성
            let waypoint_list = [];
            drone_package.route.route.map((waypoint, index) => {
                const position = {
                    latitude: waypoint.latitude,
                    longitude: waypoint.longitude,
                    altitude: waypoint.altitude,
                }
                // createVwMarker3D(vw, `wp${index}`, pointMarker, position, socket.getDroneHomePosition().altitude);
                waypoint_list.push(position);
            })
            dispatch(setWaypointCount(drone_package.route.route.length));

            // 출발지 도착지 마커 생성
            const station = {
                latitude: drone_package.startStation.latlng.lat,
                longitude: drone_package.startStation.latlng.lng,
            }
            createVwMarker2DLabel(vw, `stationMarker`, stationMarker, station, "station");

            const destination = {
                latitude: drone_package.destination.latlng.lat,
                longitude: drone_package.destination.latlng.lng,
            }
            createVwMarker2DLabel(vw, `destinationMarker`, destinationMarker, destination, "destination");
            dispatch(setGroundFlightPath(createGroundFlightPath(waypoint_list)));
        }

    }, [map, waypointCount, routeLineOverlay, emergencyLineOverlay, groundFlightPath])

    const initOverlay = useCallback(() => {
        map.removeObjectById('stationMarker');
        map.removeObjectById('destinationMarker');
        map.removeObjectById('emergencyMarker');
        // for (let i = 0; i < waypointCount; i++) {
        //     map.removeObjectById(`wp${i}`)
        // }

        // deleteEntitie(routeLineOverlay);
        deleteEntitie(emergencyLineOverlay);
        deleteEntitie(groundFlightPath)
    }, [map, waypointCount, routeLineOverlay, emergencyLineOverlay, groundFlightPath])

    const onDragDrop = useCallback(async () => {
        if (dragOrder.route_type === 1 && drone.drone_type !== "fire") {
            toast.success(`Since it is not a fire suppression drone, a fire suppression mission cannot be set..`, {
                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
            })
            return;
        }

        if (dragOrder.route_type === 0 && drone.drone_type !== "emergency") {
            toast.success(`Since it is not a relief goods delivery drone, a relief goods mission cannot be set..`, {
                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
            })
            return;
        }
        dispatch(setDragDrone(drone.drone_serial_number));
        setSelectedDroneSN(drone.drone_serial_number);
 
        // 이미 패키지 저장된 드론인 경우가 아닐경우
        if (!socket.isOrderInPackage(dragOrder.order_number) && !socket.isDroneHasPackage(drone.drone_serial_number) && dragOrder) {
            // 임시 패키지 리스트에 추가
            if (selectedOrderList.droneSN === "" || selectedOrderList.droneSN === drone.drone_serial_number) {
                // 이미 추가된 주문이 아닐 경우
                // if (!selectedOrderList.orderList.includes(dragOrder.order_number)) {
                if (selectedOrderList.orderList.findIndex((obj) => obj.order_number === dragOrder.order_number) === -1) {
                    if (selectedOrderList.droneSN !== "") {
                        // 목적지가 다른 order의 경우 추가 불가

                        // 드론에 담긴 order의 목적지
                        const new_index = orderList.findIndex((obj) => obj.order_number === selectedOrderList.orderList[0].order_number);
                        const pickUpStation = orderList[new_index].pick_up_station.id;

                        // 드래그한 새 order의 목적지
                        const drag_index = orderList.findIndex((obj) => obj.order_number === dragOrder.order_number);
                        const dragedPickUpStation = orderList[drag_index].pick_up_station.id;

                        // 드론에 담긴 order와 드래그한 새 order의 목적지가 다를경우 패키징 불가
                        if (pickUpStation !== dragedPickUpStation) {
                            toast.success(`Only orders with the same destination can be packaged.`, {
                                icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                            })
                            return;
                        }
                    }
                    // 임시 패키지 리스트의 드론 시리얼 넘버와 동일해야 나머지 추가 가능
                    setSelectedOrderList(prev => {
                        let newList = { ...prev };
                        newList.droneSN = drone.drone_serial_number;
                        newList.orderList.push(dragOrder);
                        return newList;
                    })
                }
            }
            // 아닐 경우 에러 메세지 리턴?
        }

        // 초기화
        dispatch(setDragDrone(null));
        dispatch(setDragOrder(null));
    }, [drone, dragOrder, dragDrone, selectedOrderList, orderList, forest_fire_report])
    
    useEffect(() => {
        if (drone.droneStatus.flightStatus === "EMERGENCY") {
            if (!isEmergency) {
                setIsEmergency(true);
                socket.setPackageEmergencyActive(drone.drone_serial_number, true);
                // resetPackage();
            }
        } else {
            if (isEmergency) {
                setIsEmergency(false);
                socket.setPackageEmergencyActive(drone.drone_serial_number, false);
            }
        }
    }, [drone])

    // EMERGENCY 마커 생성
    useEffect(() => {
        if (isEmergency) {
            try {
                const waypointIndex = drone.missionData.findIndex((e) => e.command === 16);
                const emergencyPosition = {
                    latitude: drone.missionData[waypointIndex].param5,
                    longitude: drone.missionData[waypointIndex].param6,
                    altitude: drone.missionData[waypointIndex].param7
                }

                createVwMarker3D(vw, "emergencyMarker", EmergencyIcon, emergencyPosition, socket.getDroneHomePosition().altitude);

                // 내 위치 - 안전착륙지점 라인 생성
                dispatch(setEmergencyLineOverlay(createEmergencyPath(socket.getDronePosition(), emergencyPosition, socket.getDroneHomePosition().altitude)));
            } catch (e) {
                console.log(`[Error] emergency Marker error : ${e}`)
            }
        } else {
            // 삭제
            map.removeObjectById("emergencyMarker");
            deleteEntitie(emergencyLineOverlay);
        }
    }, [isEmergency])



    useEffect(() => {
        if (delivery_status === true) {
            if (drone.droneStatus.isArm === false && socket.getPackageDeliveryComplete(drone.drone_serial_number)) {
                // 배송 후 복귀 완료
                changeDeliveryStatusToReturn();
            }
            if (drone.droneStatus.isArm === false && socket.getEmergencyActive(drone.drone_serial_number)) {
                // 비상착륙 후 DISARM
                // 패키지 리셋
                resetPackage();
            }
        } else {
            if (drone.droneStatus.isArm === false && socket.getPackageDeliveryComplete(drone.drone_serial_number)) {
                changeReportStatus();
            }
        }

    }, [drone.droneStatus.isArm, forest_fire_report, delivery_status])

    const changeReportStatus = useCallback(async () => {
        try {
            const changeRes = await updateReportStatus(forest_fire_report.id, 2);

            if (changeRes.status !== 200) {
                throw new Error();
            } else {
                // socket.setPackageDeliveryComplete(drone.drone_serial_number, false);
                initOverlay();

                // 패키지 제거
                socket.removePackage(drone.drone_serial_number);

                // 배송 완료 알림?
                toast.info(`${socket.getDroneName(drone.drone_serial_number)} : The delivery has been completed.`, {
                    // position: toast.POSITION.TOP_CENTER,
                    icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#97FF11"} />
                })
            }
        } catch (e) {
            console.log(e);
        }
    }, [socket, drone, forest_fire_report, delivery_status])

    const changeDeliveryStatusToReturn = useCallback(async () => {
        try {
            const data = { 
                order_number_list: socket.getOrderNumberListInPackage(drone.drone_serial_number),
                status: "comeback_complete"
            }
            const changeRes = await changeOrderStatusAPI(data);

            if (changeRes.status !== 200) {
                throw new Error();
            } else {
                // socket.setPackageDeliveryComplete(drone.drone_serial_number, false);
                initOverlay();

                // 패키지 제거
                socket.removePackage(drone.drone_serial_number);

                // 배송 완료 알림?
                toast.info(`${socket.getDroneName(drone.drone_serial_number)} : The delivery has been completed.`, {
                    // position: toast.POSITION.TOP_CENTER,
                    icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#97FF11"} />
                })
            }
        } catch (e) {
            console.log(e);
        }
    }, [socket, drone])

    const resetPackage = useCallback(async () => {
        try {
            const order_list = [];
            const order_package = socket.getDronePackage(drone.drone_serial_number);

            order_package.orderList.map((order) => {
                order_list.push(order.order_number);
            })
            const resetRes = await cancelOrderPackageAPI({order_number_list: order_list});

            if (resetRes.status !== 200) {
                throw new Error();
            } else {
                socket.removePackage(drone.drone_serial_number);
                toast.success(`The package is reset`, {
                    icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                })
            }
        } catch (e) {
            console.log(e);
        }
    }, [socket, drone])

    return (
        <S.Wrap>
            <S.TitleWrap>
                <S.DroneName>
                    { delivery_status === false && drone.drone_type === "emergency" &&
                        <MedicalDroneIcon/>    
                    }
                    { delivery_status === false && drone.drone_type === "fire" &&
                        <FireDroneIcon/>    
                    }
                    {drone.droneName}
                </S.DroneName>
                <p className="flight-mode">{drone.droneStatus.mode}</p>
            </S.TitleWrap>

            <S.DroneInfoWrap
                selectedDroneSN={selectedDroneSN}
                droneSN={drone.drone_serial_number}
                flightStatus={drone.droneStatus.flightStatus}
                onDrop={onDragDrop}
                onClick={() => {
                        setSelectedDroneSN(drone.drone_serial_number);
                        socket.setSerialNumber(drone.drone_serial_number)
                    } 
                }>
                {!!socket && !socket.isDroneHasPackage(drone.drone_serial_number) ?
                    // 패키징 중인지 아닌지 판별
                    selectedOrderList.droneSN === drone.drone_serial_number ?
                        // 패키징 아닌 상태
                        <DronePackaging drone={drone} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
                        :
                        <DroneNotPackageInfo drone={drone} selectedDroneSN={selectedDroneSN} setSelectedDroneSN={setSelectedDroneSN} droneSN={drone.drone_serial_number} />
                    :
                    // 드론에 패키지가 있는 경우
                    <DronePackaged drone={drone} selectedDroneSN={selectedDroneSN} setSelectedDroneSN={setSelectedDroneSN} droneSN={drone.drone_serial_number} />
                }
            </S.DroneInfoWrap>
                
        </S.Wrap>
    );
}

export default DroneInfo;