import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_vworld, set_map_clicked } from "../../store/reducer/map";
import styled from "styled-components";
import { getHeight } from "../Function/Cesium";

const S = {
    Wrap: styled.div`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top:0;
        left:0;
        z-index: 1;
    `,
};

function Map(props) {
    const dispatch = useDispatch();
    const { socket, mapClicked } = useSelector((state) => ({
        socket: state.drone.socket,
        mapClicked: state.map.mapClicked,
    }));

    useEffect(() => {
        setMap();
    }, [dispatch]);

    const setMap = useCallback((lat = 35.8508276, lng = 127.1230727) => {
        const { vw } = window;
        const mapOptions = new vw.MapOptions(
            vw.BasemapType.GRAPHIC,
            "",
            vw.DensityType.FULL,
            vw.DensityType.BASIC,
            false,
            new vw.CameraPosition(new vw.CoordZ(126.425, 38.196, 83487000), new vw.Direction(-90, 0, 0)),
            new vw.CameraPosition(new vw.CoordZ(lng, lat, 243), new vw.Direction(210, -45, 0))
        );
        const map = new vw.Map("vmap", mapOptions);
        dispatch(set_vworld(vw, map));
        return { vw, map };
    }, []);

    useEffect(() => {
        let { ws3d } = window;
        let CesiumScene = ws3d.viewer.scene;
        CesiumScene.context.canvas.addEventListener("webglcontextlost", function (e) {
            e.preventDefault();
        });
        CesiumScene.context.canvas.addEventListener("webglcontextrestored", function () {
            const droneStatus = socket.getDronePosition();
            setMap(droneStatus.longitude, droneStatus.latitude);
        });
    }, [dispatch, socket]);

    return <S.Wrap
        id="vmap"
        onClick={() => dispatch(set_map_clicked(!mapClicked))} />;
}

export default Map;
