import axios from "axios"

export const loginAPI = async(data) => {
    const result = await axios.post(process.env.REACT_APP_PUBLIC_END_POINT + `/api/account/delivery/login`, data, {
      withCredentials: true,
  });
	return result;
};

export const createDroneAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/drone/create", data, {
        withCredentials: true
    })
}

export const getDroneListAPI = () => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/my/drone/list", {
        withCredentials: true
    })
}

export const updateDroneAPI = (data) => {
    return axios.put(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/drone/update", data, {
        withCredentials: true,
    })
}

export const createStationAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/station/create", data, {
        withCredentials: true,
    })
}

export const getStationListAPI = () => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/station/list", {
        withCredentials: true,
    })
}

export const getStationInfoAPI = (selectedStation) => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + `/api/delivery/station/info/${selectedStation}`, {
        withCredentials: true,
    })
}

export const updateStationAPI = (data) => {
    return axios.put(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/station/update", data, {
        withCredentials: true,
    })
}

export const createCorridorAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/route/create", data, {
        withCredentials: true,
    })
}

export const getCorridorAPI = (departure_station_id, destination_station_id) => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + `/api/delivery/route/list/${departure_station_id}/${destination_station_id}`, {
        withCredentials: true
    })
}

export const getCorridorInfoAPI = (route_id) => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + `/api/delivery/route/info/${route_id}`, {
        withCredentials: true
    })
}

export const updateCorridorAPI = (data) => {
    return axios.put(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/route/update", data, {
        withCredentials: true,
    })
}

export const getOrderListAPI = () => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/request/order/list", {
        withCredentials: true
    })
}

export const updateDeliveryStationAPI = (data) => {
    return axios.put(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/station/update/departure", data, {
        withCredentials: true
    })
}

export const orderPackageAPI = (data) => {
    return axios.put(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/drone/packaging", data, {
        withCredentials: true,
    })
}

export const cancelOrderPackageAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/drone/packaging/cancel", data, {
        withCredentials: true,
    })
}

export const getEmergencyPointAPI = () => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/station/emergency/list", {
        withCredentials: true
    })
}

export const getRouteAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/route/available", data, {
        withCredentials: true
    })
}

export const changeDroneStatusAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/drone/status/update", data, {
        withCredentials: true,
    })
}

export const changeOrderStatusAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/company/order/status/change", data, {
        withCredentials: true
    })
}

export const getPackageListAPI = (serial_number) => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + `/api/delivery/drone/in_delivery/${serial_number}`, {
        withCredentials: true
    })
}

export const setDeliveryLogAPI = (data) => {
    return axios.post(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/history/create", data, {
        withCredentials: true,
    })
}

export const getDeliveryStatusAPI = () => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/company/status", {
        withCredentials: true,
    })
}

export const updateDeliveryStatusAPI = (data) => {
    return axios.put(process.env.REACT_APP_PUBLIC_END_POINT + "/api/delivery/company/status/update", data, {
        withCredentials: true,
    })
}

export const getLatestReport = () => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + "/api/forest_fire_report/reports/latest", {
        withCredentials: true,
    })
};

export const updateReportStatus = (reportId, status) => {
      return axios.patch(`${process.env.REACT_APP_PUBLIC_END_POINT}/api/forest_fire_report/reports/${reportId}/update-status/`, {
        report_status: status
      });
 
};

export const getEmergencyRoutes = (routeType) => {
    return axios.get(process.env.REACT_APP_PUBLIC_END_POINT + `/api/forest_fire_report/emergency-routes/?route_type=${routeType}`, {
        withCredentials: true,
    })
  };