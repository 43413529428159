import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setSafetyMargin, setStartHeight, setSpeed } from '../../../store/reducer/droneSetting';
import { set_is_active_ai } from "../../../store/reducer/video";
import Toggle from './Toggle';

const S = {
    Wrap: styled.div`
        margin-left: auto;
        display: flex;
        align-items: center;

        .vertical-divide-line {
            width: 1px;
            height: 30px;
            background-color: #4f4e4e;
            margin: 0px 20px;
        }
    `,
    SettingMenuWrap: styled.div`
        display: flex;
        align-items: center;
        gap: 8px;

        .menu-title {
            font-size: 12px;
            line-height: 18px;
            color: #b5b5b5;
        }

        .input-wrap {
            width: 80px;
            height: 31px;
        }
    `,

    ToggleContainer: styled.div`
        display: flex;
        align-items: center;
        gap: 8px;

        width: 100px;

        .menu-title {
            font-size: 12px;
            line-height: 18px;
            color: #b5b5b5;
            width:300px;
        }
    `,
}

function DroneSettingMenu(props) {
    const [tempSafetyMargin, setTempSafetyMargin] = useState(50);
    const [tempStartHeight, setTempStartHeight] = useState(10);
    const [tempSpeed, setTempSpeed] = useState(3);
    const dispatch = useDispatch();
    const { is_active_ai } = useSelector((state) => ({
        is_active_ai: state.video.is_active_ai, //AI 감지 ON/OFF
    }));
    return (
        <S.Wrap>
            <S.ToggleContainer>
                <div className="menu-title">AI DECTION</div>
                <Toggle
                    isActive={is_active_ai}
                    setIsActive={() => {
                        dispatch(set_is_active_ai(!is_active_ai));
                    }}
                />
            </S.ToggleContainer>
            <div className="vertical-divide-line" />
            <S.SettingMenuWrap>
                <p className="menu-title">DRONE SPEED</p>
                <div className="default-input input-wrap">
                    <input type="text" value={`${tempSpeed}m/s`} onChange={(e) => setTempSpeed(e.target.value.replace("m/s", ""))}
                        onBlur={() => {
                            dispatch(setSpeed(tempSpeed))
                        }
                        }/>
                </div>
            </S.SettingMenuWrap>
            <div className="vertical-divide-line" />
            <S.SettingMenuWrap>
                <p className="menu-title">SAFETY MARGIN</p>
                <div className="default-input input-wrap">
                    <input type="text" value={`${tempSafetyMargin}m`} onChange={(e) => setTempSafetyMargin(e.target.value.replace("m", ""))}
                        onBlur={() => {
                            dispatch(setSafetyMargin(tempSafetyMargin))
                        }
                        }/>
                </div>
            </S.SettingMenuWrap>
            <div className="vertical-divide-line" />
            <S.SettingMenuWrap>
                <p className="menu-title">START HEIGHT</p>
                <div className="default-input input-wrap">
                    <input type="text" value={`${tempStartHeight}m`} onChange={(e) => setTempStartHeight(e.target.value.replace("m", ""))}
                        onBlur={() => {
                            dispatch(setStartHeight(tempStartHeight));
                    }}/>
                </div>
            </S.SettingMenuWrap>
        </S.Wrap>
    );
}

export default DroneSettingMenu;