import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as EmergencySuppliesButton } from "../../../gcs_image/detect_fire_warning/emergency-supplies.svg";
import { ReactComponent as FireSuppressionButton } from "../../../gcs_image/detect_fire_warning/fire-suppression.svg";
import { setDragOrder } from '../../../store/reducer/drag';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

const S = {
    Wrap: styled.div`
        width: 460px;
        height: 72px;
        display: flex;
        position: fixed;
        justify-content: center;
        gap:15px;
        left:41%;
        bottom: 100px;
        
        z-index: 99;
    `,
    EmergecnButton: styled.div`
        width:218px;
        height: 72px;
        cursor: pointer;
    `,

    FireButton: styled.div`
        width:218px;
        height: 72px;
        cursor: pointer;
    `,
};

function FireMissionButton() {
    const dispatch = useDispatch();

    const {forest_fire_report} = useSelector(state=>({
        forest_fire_report: state.skycook.forest_fire_report,
      }));
    
    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
    
        const formattedDateTime = `${year}${month}${day}${hours}${minutes}`;
        return formattedDateTime;
    }
    
    return (
        <S.Wrap>
            { forest_fire_report.relief_supplies === true && forest_fire_report.report_status <= 1 && 
                <S.EmergecnButton
                    draggable 
                    onDragStart={(e) => {
                        dispatch(setDragOrder({
                            order_number: getCurrentDateTime(),
                            order_weight: 3000,
                            route_type:0,
                        }))
                        // dispatch(setDragOrder(order.order_number))
                    }}
                >
                    <EmergencySuppliesButton />
                </S.EmergecnButton>
            }

           { forest_fire_report.title == "산불신고" && forest_fire_report.report_status <= 1 &&
                <S.FireButton
                draggable 
                    onDragStart={(e) => {
                        dispatch(setDragOrder({
                            order_number: getCurrentDateTime(),
                            order_weight: 3000,
                            route_type:1,
                        }))
                        // dispatch(setDragOrder(order.order_number))
                    }}
                >
                    <FireSuppressionButton />
               </S.FireButton>
            }
         
        </S.Wrap>
    );
}

export default FireMissionButton;