import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import AirframeModal from './modal/AirframeModal';
import StationModal from './modal/StationModal';
import CorridorModal from './modal/CorridorModal';
import ClimateModal from './modal/ClimateModal';

const S = {
    Wrap: styled.div`
        display: flex;
        align-items: center;

        .vertical-divide-line {
            width: 1px;
            height: 30px;
            background-color: #4f4e4e;
            margin: 0px 20px;
        }
    `,
    MenuWrap: styled.div`
        display: flex;
        align-items: center;
        gap: 8px;

        .menu-title {
            font-size: 12px;
            line-height: 18px;
            color: #b5b5b5;
        }
    `,
    EditBtn: styled.div`
        padding: 2px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #636262;
        cursor: pointer;

        :hover,
        :focus {
            border: 1px solid #3e3e3e;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            color: #fff;
        }
    `,
}

function SystemMenu(props) {
    const [isAirframeModalOpen, setIsAirframeModalOpen] = useState(false);
    const [isStationModalOpen, setIsStationModalOpen] = useState({
        isOpen: false,
        type: "",    // station, destination
    });
    const [isModalOpen, setIsModalOpen] = useState({
        airframe: false,
        station: false,
        destination: false,
        corridor: false, 
        climate: false,
    })

    const openBtnModal = useCallback((clicked_btn) => {
        const temp_is_open = {
            airframe: clicked_btn === "airframe" ? !isModalOpen.airframe ? true : false : false,
            station: clicked_btn === "station" ? !isModalOpen.station ? true : false : false,
            destination: clicked_btn === "destination" ? !isModalOpen.destination ? true : false : false,
            corridor: clicked_btn === "corridor" ? !isModalOpen.corridor ? true : false : false,
            climate: clicked_btn === "climate" ? !isModalOpen.climate ? true : false : false,
        }

        setIsModalOpen(temp_is_open);
    }, [isModalOpen])

    // 다른 모달 켜질 때 나머지 닫거나, 하나 켜져있을 경우 새로운 모달 안켜지게
    
    return (
        <S.Wrap>
            <S.MenuWrap>
                <p className="menu-title">AIRCRAFTS</p>
                <S.EditBtn onClick={() => openBtnModal("airframe")}>
                    <p>Edit</p>
                </S.EditBtn>
                <div className="vertical-divide-line" />
            </S.MenuWrap>
            <S.MenuWrap>
                <p className="menu-title">STATIONS</p>
                <S.EditBtn onClick={() => openBtnModal("station")}>
                    <p>Edit</p>
                </S.EditBtn>
                <div className="vertical-divide-line" />
            </S.MenuWrap>
            {/* <S.MenuWrap>
                <p className="menu-title">DESTINATIONS</p>
                <S.EditBtn onClick={() => openBtnModal("destination")}>
                    <p>Edit</p>
                </S.EditBtn>
                <div className="vertical-divide-line" />
            </S.MenuWrap> */}
            <S.MenuWrap>
                <p className="menu-title">CORRIDORS</p>
                <S.EditBtn onClick={() => openBtnModal("corridor")}>
                    <p>Edit</p>
                </S.EditBtn>
                <div className="vertical-divide-line" />
            </S.MenuWrap>
            <S.MenuWrap>
                <p className="menu-title">CLIMATE</p>
                <S.EditBtn onClick={() => openBtnModal("climate")}>
                    <p>Edit</p>
                </S.EditBtn>
                <div className="vertical-divide-line" />
            </S.MenuWrap>

            {/* click modal */}
            <AirframeModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} />
            <StationModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} />
            <CorridorModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} />
            <ClimateModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} />
        </S.Wrap>
    );
}

export default SystemMenu;