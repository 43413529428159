import React from 'react';
import styled from 'styled-components';
import MarkerIcon from "../../../../gcs_image/common/marker-icon.svg";

const S = {
    Wrap: styled.div`
        width: 100%;
        /* background-color: skyblue; */
        display: flex;
        gap: 6px;
    `,
    MarkerWrap: styled.div`
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        .dash-line {
            height: 28px;
            border: 0.5px dashed #fff;
        }
    `,
    StationDestinationWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        .station-wrap {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .station-time-wrap {
                display: flex;
                
                .from-to-text {
                    width: 30px;
                    font-size: 9px;
                    zoom: 0.9;
                    line-height: 16px;
                    color: #fff;
                }
                .time-date-text {
                    font-size: 9px;
                    zoom: 0.9;
                    line-height: 16px;
                    color: #79aaf2;
                }
            }
            .station-name {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #fff;
            }
        }
    `,
    DeliveryTimeWrap: styled.div`
        margin-left: auto;
        margin-top: auto;
        display: flex;
        flex-direction: column;

        .pickup-time-text {
            font-size: 7px;
            line-height: 16px;
            font-weight: 300;
            color: #898989;
            zoom: 0.7;
        }

        .pickup-time-value {
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
            text-align: center;
        }
        .pickup-time-mins-text {
            font-size: 11px;
            line-height: 16px;
            font-weight: 300;
            color: #fff;
        }
    `,
}

function StationDestinationInfo({ deliveryPackage }) {
    return (
        <S.Wrap>
            <S.MarkerWrap>
                <img src={MarkerIcon} alt="marker-icon" />
                <div className="dash-line" />
                <img src={MarkerIcon} alt="marker-icon" />
            </S.MarkerWrap>
            <S.StationDestinationWrap>
                <div className="station-wrap">
                    <div className="station-time-wrap">
                        <div className="from-to-text">From</div>
                        <div className="time-date-text">{deliveryPackage.launchTime !== "" && deliveryPackage.launchTime}</div>
                    </div>
                    <div className="station-name">{deliveryPackage.startStation.name}</div>
                </div>
                <div className="station-wrap">
                    <div className="station-time-wrap">
                        <div className="from-to-text">To</div>
                        <div className="time-date-text">{deliveryPackage.estimatedArrivalTime !== "" && `About ${deliveryPackage.estimatedArrivalTime}`}</div>
                        {/* <div className="time-date-text">About {deliveryPackage.estimatedArrivalTime}</div> */}
                    </div>
                    <div className="station-name">{deliveryPackage.destination.name}</div>
                </div>
            </S.StationDestinationWrap>
            <S.DeliveryTimeWrap>
                <div className="pickup-time-text">Pickup time</div>
                <div className="pickup-time-value">{deliveryPackage.estimatedTakenTime} <span className="pickup-time-mins-text">Mins</span></div>
            </S.DeliveryTimeWrap>
        </S.Wrap>
    );
}

export default StationDestinationInfo;