import React, {useState} from 'react';
import styled from 'styled-components';

const S = {
    Wrap: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
    `,
    StatusWrap: styled.div`
        display: flex;
        flex-direction: column;

        .status-title {
            font-size: 9px;
            zoom: 0.9;
            line-height: 16px;
            font-weight: 300;
            color: #fff;
        }
        .status-value-wrap {
            display: flex;
            align-items: center;

            .status-value {
                font-size: 13px;
                line-height: 16px;
                font-weight: 500;
                /* color: #fff; */
                /* 배터리 정격전압을 50%로 기준함 */
                color: ${props => !!props.batt ?
                    props.batt >= 48.0 ? "#fff500"
                        : props.batt >= 44.4 ? "#ff8c01"
                        : "#fa082d"
                    : "#fff"};
            }
            .status-unit-text {
                font-size: 13px;
                line-height: 16px;
                font-weight: 300;
                color: #fff;
            }
            .status-mark-text {
                font-size: 8px;
                line-height: 16px;
                font-weight: 300;
                color: #fff;
            }
        }
    `,
}

function DroneStatusInfo({drone}) {
    const [battery, setBattery] = useState(48.5);

    return (
        <S.Wrap>
            <S.StatusWrap batt={battery}>
                <div className="status-title">Battery</div>
                <div className="status-value-wrap">
                    <div className="status-value">{drone.battery.voltage.toFixed(2)} <span className="status-unit-text">V</span></div>
                </div>
            </S.StatusWrap>
            <S.StatusWrap>
                <div className="status-title">Speed</div>
                <div className="status-value-wrap">
                    <div className="status-value">{drone.droneStatus.airSpeed.toFixed(1)} <span className="status-unit-text">m<span className="status-mark-text">/</span>s</span></div>
                </div>
            </S.StatusWrap>
            <S.StatusWrap>
                <div className="status-title">Height</div>
                <div className="status-value-wrap">
                    <div className="status-value">{drone.dronePosition.altitude.toFixed(1)} <span className="status-unit-text">m</span></div>
                </div>
            </S.StatusWrap>
        </S.Wrap>
    );
}

export default DroneStatusInfo;