import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DroneInfo from './drone-info/DroneInfo';

const S = {
    Wrap: styled.div`
        width: 330px;
        max-height: ${props => props.screen_size}px;
        position: fixed;
        z-index: 99;
        left: 30px;
        top: 72px;
        background-color: #201f1f;
        opacity: 0.9700000286102295;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.30);
        backdrop-filter: blur(30px);
        overflow: scroll;
    `,
}

function DroneListView({selectedOrderList, setSelectedOrderList, selectedDroneSN, setSelectedDroneSN}) {
    const screen_size = window.innerHeight;
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));

    return (
        <S.Wrap screen_size={screen_size - 146}>
            {!!socket && socket.drones.map((drone, index) => {
                return (
                    <DroneInfo
                        key={`drone${index}`}
                        drone={drone}
                        index={index}
                        selectedOrderList={selectedOrderList}
                        setSelectedOrderList={setSelectedOrderList}
                        selectedDroneSN={selectedDroneSN}
                        setSelectedDroneSN={setSelectedDroneSN} />)
                // if (!!selectedOrderList[0]) {
                //     return (<DronePackaging key={`drone${index}`} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />)
                // } else {
                //     return (<DroneNotPackageInfo key={`drone${index}`} selectedDroneSN={selectedDroneSN} setSelectedDroneSN={setSelectedDroneSN} id={"111111111111111111111"} />)
                // }
            })}
            {/* <DroneInfo selectedDroneSN={selectedDroneSN} setSelectedDroneSN={setSelectedDroneSN} id={1} />
            <DroneInfo selectedDroneSN={selectedDroneSN} setSelectedDroneSN={setSelectedDroneSN} id={2} />
            <DroneNotPackageInfo selectedDroneSN={selectedDroneSN} setSelectedDroneSN={setSelectedDroneSN} id={"111111111111111111111"} /> */}
        </S.Wrap>
    );
}

export default DroneListView;