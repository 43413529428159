import React, {useState, useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import CloseIcon from "../../../../gcs_image/common/close-icon.svg";
import BottomMenuModal from '../../../common/BottomMenuModal';
import { createStationAPI, getStationListAPI, getStationInfoAPI, updateStationAPI } from '../../../../api/skycook_apis';
import { useDispatch, useSelector } from 'react-redux';
import { createVwMarker2D, createVwMarker3D } from '../../../Left/Mission/MissionFunctions';
import waypointYellow from "../../../../gcs_image/marker/waypointYellow.svg"
import { toast } from 'react-toastify';

import { ReactComponent as NoticeIcon } from "../../../../gcs_image/common/notice-critical-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../gcs_image/common/check-circle-icon.svg";
import { setStationList } from '../../../../store/reducer/skycook';

const S = {
    TitleWrap: styled.div`
        width: 100%;
        height: 52px;
        /* background-color: skyblue; */
        padding: 21px 15px;
        border-bottom: 1px solid #353535;
        display: flex;
        align-items: center;

        p {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
    `,
    ModalWrap: styled.div`
        display: flex;
        flex-direction: column;

        .tap-wrap {
            display: flex;
        }
    `,
    Tap: styled.div`
        width: 50%;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: ${props => props.selectedTap === props.tapName ? "1px solid #004be9" : ""};
        cursor: pointer;

        p {
            font-size: 12px;
            line-height: 16px;
            color: ${props => props.selectedTap === props.tapName ? "#004be9" : "#fff"};
        }
    `,
    ModalInputWrap: styled.div`
        padding: 18px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-row-wrap {
            display: flex;
            align-items: center;

            p {
                width: 92px;
                font-size: 12px;
                line-height: 18px;
                color: #b5b5b5;
            }

            .input-wrap {
                width: 138px;
                height: 31px;
            }
            .select-wrap {
                width: 138px;
                height: 31px;
                background-color: #292828;
                display: flex;
                align-items: center;
                padding: 6.5px 11px;
            }

            select {
                cursor: pointer;
            }
        }
        .btn-wrap {
            display: flex;
            gap: 5px;
            margin-left: auto;
            margin-top: 2px;
        }
    `,
}

function StationModal({ isModalOpen, setIsModalOpen }) {
    const { map, vw, socket, stationList } = useSelector((state) => ({
        map: state.map.map,
        vw: state.map.vw,
        socket: state.drone.socket,
        stationList: state.skycook.stationList,
    }))
    const [selectedTap, setSelectedTap] = useState("registration");
    const [stationName, setStationName] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [altitude, setAltitude] = useState("");
    const [address, setAddress] = useState("");
    const [selectedStation, setSelectedStation] = useState("");

    const dispatch = useDispatch();
    // const [stationList, setStationList] = useState([]);

    const onClickRegistration = useCallback(() => {
        setSelectedTap("registration");
        initInput();
        setSelectedStation("");
        map.removeObjectById("stationMarker");
    }, [map])

    const onClickModifyTap = useCallback(() => {
        if (selectedTap !== "modify") {
            setSelectedTap("modify");
            initInput();
            getStationListOnModify();
        }
    }, [stationList, selectedTap])

    useEffect(() => {
        if (selectedStation !== "") {
            getStationInfo();
        } else {
            initInput();
        }
    }, [selectedStation])

    const getStationListOnModify = useCallback(async () => {
        try {
            const res = await getStationListAPI();
            dispatch(setStationList(res.data));

            if (res.data.length > 0) {
                setSelectedStation(res.data[0].id);
            } else {
                setSelectedStation("");
            }
        } catch (e) {
            console.log(e);
        }
    }, [])

    const getStationList = useCallback(async () => {
        try {
            const res = await getStationListAPI();
            dispatch(setStationList(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [])

    const getStationInfo = useCallback(async () => {
        try {
            const res = await getStationInfoAPI(selectedStation);
            setStationName(res.data.name);
            setLatitude(res.data.latlng.lat);
            setLongitude(res.data.latlng.lng);
            setAltitude(res.data.safe_altitude);
            setAddress(res.data.address);
        } catch (e) {
            console.log(e);
        }
    }, [selectedStation])

    const onClickCreateSave = useCallback(async () => {
        try {
            if (stationName.length > 10) {
                toast.success(`The station name should be less than 10 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (latitude.length + longitude.length + 1 > 255) {
                toast.success(`The sum of latitude, longitude should be less than 254 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (altitude.length > 10) {
                toast.success(`The altitude should be less than 10 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (address.length > 100) {
                toast.success(`The address should be less than 100 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            const data = {
                name: stationName,
                latlng: latitude + "," + longitude,
                safe_altitude: altitude,
                address: address,
            }
            const createRes = await createStationAPI(data);

            if (createRes.status !== 201) {
                throw new Error();
            } else {
                toast.success(`The station is added.`, {
                        icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                map.removeObjectById("stationMarker");
                initInput();
            }
        } catch (e) {
            console.log(e)
        }
    }, [stationName, latitude, longitude, altitude, address])

    const initInput = useCallback(() => {
        setStationName("");
        setLatitude("");
        setLongitude("");
        setAltitude("");
        setAddress("");
    }, [stationName,])

    const onClickDelete = useCallback(async () => {
        try {
            const data = {
                station_id: selectedStation,
                type: "delete"
            }
            const deleteRes = await updateStationAPI(data);
            
            if (deleteRes.status !== 200) {
                throw new Error();
            } else {
                toast.success(`The aircraft is removed.`, {
                        icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                map.removeObjectById("stationMarker");
                getStationListOnModify();
            }
        } catch (e) {
            console.log(e);
        }
    }, [selectedStation])
    
    const onClickUpdate = useCallback(async () => {
        try {
            if (stationName.length > 10) {
                toast.success(`The station name should be less than 10 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (latitude.length + longitude.length + 1 > 255) {
                toast.success(`The sum of latitude, longitude should be less than 254 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (altitude.length > 10) {
                toast.success(`The altitude should be less than 10 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            if (address.length > 100) {
                toast.success(`The address should be less than 100 letters.`, {
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                return;
            }
            const data = {
                station_id: selectedStation,
                type: "update",
                name: stationName,
                latlng: latitude + "," + longitude,
                safe_altitude: altitude,
                address: address,
            }
            const updateRes = await updateStationAPI(data);

            if (updateRes.status !== 200) {
                throw new Error();
            } else {
                toast.success(`The station is updated.`, {
                        icon: ({theme, type}) => <CheckIcon width={"20px"} height={"20px"} fill={"#4AE9FF"} />
                    })
                getStationList();
            }
        } catch (e) {
            console.log(e)
        }
    }, [selectedStation, stationName, latitude, longitude, altitude, address])

    const onClickCancel = useCallback(() => {
        setIsModalOpen({
            ...isModalOpen,
            station: false,
        })
        initInput();
        setSelectedTap("registration");
        setSelectedStation("");
        map.removeObjectById("stationMarker");
    }, [isModalOpen])

    // 지도에 마커 생성
    useEffect(() => {
        if (latitude !== "" && longitude !== "") {
            map.removeObjectById("stationMarker");

            const position = {
                latitude: latitude,
                longitude: longitude
            }
            createVwMarker2D(vw, "stationMarker", waypointYellow, position);
        }
    }, [latitude, longitude])
    

    if (isModalOpen.station) {
        return (
            <BottomMenuModal>
                <S.TitleWrap>
                    <p>STATIONS</p>
                </S.TitleWrap>
                <S.ModalWrap>
                    <div className="tap-wrap">
                        <S.Tap selectedTap={selectedTap} tapName={"registration"} onClick={onClickRegistration}>
                            <p>Registration</p>
                        </S.Tap>
                        <S.Tap selectedTap={selectedTap} tapName={"modify"} onClick={onClickModifyTap}>
                            <p>Modify</p>
                        </S.Tap>
                    </div>

                    {selectedTap === "registration" ?
                        <S.ModalInputWrap>
                            <div className="input-row-wrap">
                                <p>Station name</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={stationName}
                                        onChange={e => setStationName(e.target.value)}
                                        autoFocus />
                                    {stationName !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setStationName("")} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Latitude</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={latitude}
                                        onChange={e => setLatitude(e.target.value)} />
                                    {latitude !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setLatitude("");
                                                map.removeObjectById("stationMarker");
                                            }} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Longitude</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={longitude}
                                        onChange={e => setLongitude(e.target.value)} />
                                    {longitude !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setLongitude("");
                                                map.removeObjectById("stationMarker");
                                            }} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Safe alt</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={altitude}
                                        onChange={e => setAltitude(e.target.value)} />
                                    {altitude !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setAltitude("");
                                                map.removeObjectById("stationMarker");
                                            }} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Address</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={address}
                                        onChange={e => setAddress(e.target.value)} />
                                    {address !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setAddress("")} />
                                    )}
                                </div>
                            </div>

                            <div className="btn-wrap">
                                <div className="cancel-btn" onClick={onClickCancel}>
                                    <p>Cancel</p>
                                </div>
                                <div className="save-btn" onClick={onClickCreateSave}>
                                    <p>Save</p>
                                </div>
                            </div>
                        </S.ModalInputWrap>
                        :
                        <S.ModalInputWrap>
                            <div className="input-row-wrap">
                                <p>Station list</p>
                                <div className="input-wrap select-wrap">
                                    <select className="default-select"
                                        onChange={e => setSelectedStation(e.target.value)}>
                                        {stationList.map((station) => {
                                            return (<option value={station.id} key={`station${station.id}`}>{station.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Station name</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={stationName}
                                        onChange={e => setStationName(e.target.value)} />
                                    {stationName !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setStationName("")} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Latitude</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={latitude}
                                        onChange={e => setLatitude(e.target.value)} />
                                    {latitude !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setLatitude("");
                                                map.removeObjectById("stationMarker");
                                            }} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Longitude</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={longitude}
                                        onChange={e => setLongitude(e.target.value)} />
                                    {longitude !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setLongitude("");
                                                map.removeObjectById("stationMarker");
                                            }} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Safe alt</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={altitude}
                                        onChange={e => setAltitude(e.target.value)} />
                                    {altitude !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => {
                                                setAltitude("");
                                                map.removeObjectById("stationMarker");
                                            }} />
                                    )}
                                </div>
                            </div>
                            <div className="input-row-wrap">
                                <p>Address</p>
                                <div className="input-wrap default-input">
                                    <input type="text"
                                        value={address}
                                        onChange={e => setAddress(e.target.value)} />
                                    {address !== "" && (
                                        <img src={CloseIcon}
                                            width="24px"
                                            height="24px"
                                            alt="close-btn"
                                            onClick={() => setAddress("")} />
                                    )}
                                </div>
                            </div>

                            <div className="btn-wrap">
                                <div className="cancel-btn" onClick={onClickCancel}>
                                    <p>Cancel</p>
                                </div>
                                <div className="delete-btn" onClick={onClickDelete}>
                                    <p>Delete</p>
                                </div>
                                <div className="save-btn" onClick={onClickUpdate}>
                                    <p>Save</p>
                                </div>
                            </div>
                        </S.ModalInputWrap>
                    }
                </S.ModalWrap>
            </BottomMenuModal>
        );
    }
}

export default StationModal;