import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setDeliveryStatus, setOrderList, setStationList } from '../../store/reducer/skycook';
import { getOrderListAPI, getStationListAPI, updateDeliveryStatusAPI } from '../../api/skycook_apis';
import chimeBellFile from "../../audio/chime-bell.wav";

const S = {
    Wrap: styled.div`
        width: 100%;
        height: 60px;
        background-color: #201F1F;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        z-index: 99;
        padding: 0 48px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.60);
        top:0;
        left:0;
    `,
    DroneStatus: styled.div`
        /* background-color: skyblue; */
        display: flex;
        align-items: center;
        gap: 20px;
        
    `,
    TitleWrap: styled.div`
        /* background-color: skyblue; */
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        /* identical to box height, or 31px */

        color: #FFFFFF;
    `,
    StatusText: styled.p`
        font-size: 18px;
        font-weight: 600;
        
        color: ${props => props.status === "flight" ? "#B5FF15" : "#FFFFFF"};

        span {
            font-size: 15px;
            font-weight: 300;
            color: ${props => props.status === "flight" ? "#B5FF15" : "#FFFFFF"};
        }
    `,
    VerticalDivideLine: styled.div`
        width: 1px;
        height: 30px;
        background-color: #4f4e4e;
    `,
    OrderWrap: styled.div`
        display: flex;
        align-items: center;
        gap: 20px;

        .select-wrap {
            width: 138px;
            height: 31px;
            background-color: #292828;
            display: flex;
            align-items: center;
            padding: 6.5px 11px;
        }
    `,
    StopOrderBtn: styled.div`
        height: 38px;
        padding: 14px 23px;
        border-radius: 2px;
        border: 1px solid #3d3d3d;
        background-color: #383838;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {
            font-size: 16px;
            color: #F62A49;
            font-weight: 600;
        }
    `,
    OpenOrderBtnUnActive: styled.div`
        height: 38px;
        padding: 14px 23px;
        border-radius: 2px;
        border: 1px solid #3d3d3d;
        background-color: #383838;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {
            font-size: 16px;
            color: gray;
            font-weight: 600;
            
            :hover,
            :focus {
                color: #B5FF15;
            }    
        }
    `,
}

function TopMenubar(props) {
    const [chimeBell] = useState(new Audio(chimeBellFile));
    const { socket, deliveryStation, stationList, orderList, deliveryStatus } = useSelector((state) => ({
        socket: state.drone.socket,
        deliveryStation: state.skycook.deliveryStation,
        stationList: state.skycook.stationList,
        orderList: state.skycook.orderList,
        deliveryStatus: state.skycook.delivery_status,
    }))

    const dispatch = useDispatch();

    useEffect(() => {
        getStationList();
    }, [deliveryStation])

    const getStationList = useCallback(async () => {
        try {
            const res = await getStationListAPI();
            dispatch(setStationList(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [])

    const onClickStopOrder = useCallback(async () => {
        try {
            const res = await updateDeliveryStatusAPI({ status: "closed" });
            if (res.status !== 200) {
                throw new Error();
            } else {
                dispatch(setDeliveryStatus(false));
                dispatch(setOrderList([]));
            }
        } catch (e) {
            console.log(e);
        }
    }, [])

    const onClickOpenOrder = useCallback(async () => {
        try {
            const res = await updateDeliveryStatusAPI({ status: "open" });
            if (res.status !== 200) {
                throw new Error();
            } else {
                dispatch(setDeliveryStatus(true));
                getOrderList();
            }
        } catch (e) {
            console.log(e);
        }
    }, [])

    const getOrderList = useCallback(async () => {
        try {
            const getRes = await getOrderListAPI();
            if (getRes.status !== 200) {
                throw new Error();
            } else {
                let newOrderDetected = false;
                
                if (orderList.length === 0 && getRes.data.length > 0) {
                    newOrderDetected = true;
                } else {
                    getRes.data.map((new_order) => {
                        if (!newOrderDetected) {
                            orderList.map((prev_order, index) => {
                                if (prev_order.order_number === new_order.order_number) {
                                    return;
                                }
                                if (index === prev_order.length - 1) {
                                    newOrderDetected = true;
                                    return;
                                }
                            })
                        }
                    })
                }
                if (newOrderDetected) {
                    chimeBell.play();
                }

                dispatch(setOrderList(getRes.data));
            }
        } catch (e) {
            console.log(e);
        }
    }, [orderList])

    return (
        <S.Wrap>
            <S.DroneStatus>
                <S.StatusText status="flight">IN FLIGHT <span>({!!socket ? socket.getFlightDroneCount() : "0"})</span></S.StatusText>
                <S.VerticalDivideLine />
                <S.StatusText status="ready">READY TO FLY <span>({!!socket ? socket.getReadyDroneCount() : "0"})</span></S.StatusText>
            </S.DroneStatus>
            <S.TitleWrap>
                 배송 산불감시 통합 관제시스템
            </S.TitleWrap>
            <S.OrderWrap>
                {/* <div className="input-wrap select-wrap">
                    <select className="default-select"
                        value={deliveryStation}
                        onChange={(e) => dispatch(setDeliveryStation(e.target.value))}>
                        <option value={""}>-</option>
                        {stationList.map((station) => {
                            return (
                                <option value={station.id}
                                    key={`station${station.id}`}>
                                    {station.name}
                                </option>
                            )
                        })}
                    </select>
                </div> */}
                {deliveryStatus ?
                    <>
                        <S.StopOrderBtn onClick={onClickStopOrder}>
                            <p>STOP ORDER</p>
                        </S.StopOrderBtn>
                        <S.VerticalDivideLine />
                        <S.StatusText>ORDERS <span>({orderList.length})</span></S.StatusText>
                    </>
                :
                    <S.OpenOrderBtnUnActive onClick={onClickOpenOrder}>
                        <p>OPEN ORDER</p>
                    </S.OpenOrderBtnUnActive>
                }
            </S.OrderWrap>
        </S.Wrap>
    );
}

export default TopMenubar;