import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { set_is_drone_detect } from "../../../store/reducer/video";
import FireReoprtVoice from "../../../audio/fire_report_detect.mp3";
import AccidentReoprtVoice from "../../../audio/accident_report_detect.mp3";
import { createVwMarker2DLabel } from "../../Left/Mission/MissionFunctions";
import reporterPositionMaker from "../../../gcs_image/marker/reporter-position.svg";
import { setFireReportWarning, setAccidentReportWarning } from "../../../store/reducer/skycook";


const ImageWrap = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    top: 35%;
    left: 40%;
    z-index: 99;
`;

const DetectionImage = styled.img`
    z-index: 2;
    width: 25vw;
    box-shadow: 0px 24px 21px rgba(0, 0, 0, 0.38);
    border-radius: 10px;
`;

const DetectLocation = styled.div`
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 25vw;
    gap: 13px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
`;

const LocationText = styled.p`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
`;

//35.799794,127.165846 -> 화재지점 좌표라 생각
function DetectImage() {

    const [fireReportVoice] = useState(new Audio(FireReoprtVoice));
    const [accidentReoprtVoice] = useState(new Audio(AccidentReoprtVoice));

    const [imageUrl, setImageUrl] = useState();
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [altitude, setAltitude] = useState(0);

    const { vw, socket, is_active_ai } = useSelector((state) => ({
        vw: state.map.vw,
        socket: state.drone.socket,
        is_active_ai: state.video.is_active_ai, //AI 감지 ON/OFF

    }));

    const [checkConnect, setCheckConnect] = useState(false);
    const [serverSocket, setServerSocket] = useState(null);

    const dispatch = useDispatch();

    const get_distance_metres = (location1, location2) => {
        let dlat = location2.latitude - location1.latitude;
        let dlong = location2.longitude - location1.longitude;
        return Math.sqrt(dlat * dlat + dlong * dlong) * 1.113195e5;
    };

    useEffect(() => {
        const newSocket = new WebSocket(`${process.env.REACT_APP_API_URL_AI_WEBSOCKET_SERVER}/ws/1`);

        newSocket.onopen = () => {
            console.log("open");
        };

        newSocket.onclose = () => {
            console.log("close");
            setCheckConnect(prev => !prev);
        };

        newSocket.onerror = (e) => {
            console.log(e);
        };
        

        setServerSocket(newSocket);

        return () => {
            newSocket.close(); // 클린업: 컴포넌트가 언마운트될 때 소켓을 닫습니다.
        };
    }, [checkConnect]);

    useEffect(() => {
        let counter = 0;
        let prevPosition = { longitude: 0, latitude: 0 };
        if (serverSocket) {
            serverSocket.onmessage = (e) => {
                let data = e.data;
                   
                if (data != "0") {
                    if (data === "1" || data === "2") {
                        let lng = socket.getDronePosition().longitude;
                        let lat = socket.getDronePosition().latitude;
                        serverSocket.send(`${lng},${lat}`);
            
                    } else {
                        if (is_active_ai) {
                            const jsonData = JSON.parse(data);
                            let distance = get_distance_metres(prevPosition, { longitude: jsonData.longitude, latitude: jsonData.latitude });
                            console.log(jsonData.image_url)
                            if (distance > 20) {
                                let point = createVwMarker2DLabel(vw, `droneReporterPositionMaker${counter}`, reporterPositionMaker, { latitude: jsonData.latitude, longitude: jsonData.longitude }, "");
                            
                                const markerclick = function (windowPosition, ecefPosition, cartographic, featureInfo) {
                                    if (featureInfo != null) {
                                        setImageUrl(`${process.env.REACT_APP_API_URL_MEDIA}/${jsonData.image_url}`);
                                        setLatitude(jsonData.latitude);
                                        setLongitude(jsonData.longitude);
        
                                        if (jsonData.altitude === undefined || jsonData.altitude === "Nan") {
                                            jsonData.altitude = socket.getDronePosition().altitude;
                                        }
        
                                        setAltitude(round(jsonData.altitude, 10));
                                    }
                                };

                                if (data === "1" ) {
                                    dispatch(setFireReportWarning(true));
                                    fireReportVoice.play()                            
                                    
                                } else {
                                    dispatch(setAccidentReportWarning(true));
                                    accidentReoprtVoice.play();
                                }

                                point.addEventListener(markerclick);
                                dispatch(set_is_drone_detect(true))

                                counter = counter + 1;
                                prevPosition = { longitude: jsonData.longitude, latitude: jsonData.latitude };
                            }
                        } else {
                            dispatch(set_is_drone_detect(false))
                        }
                    }
            }
        }
        return () => {
            serverSocket.close(); // 클린업: 컴포넌트가 언마운트될 때 소켓을 닫습니다.
        };
        }
    }, [serverSocket, vw, socket, is_active_ai]);

    const round = (num, digit) => {
        if (Number(digit) % 10 === 0) {
            return Math.round(Number(num) * Number(digit)) / Number(digit);
        } else {
            return num;
        }
    };

    return (
        <>
            {imageUrl ? (
                <ImageWrap>
                    <DetectionImage
                        onClick={() => {
                            setImageUrl(null);
                        }}
                        src={imageUrl}
                    />
                    <DetectLocation>
                        <LocationText>latitude:{latitude.toFixed(5)}</LocationText>
                        <LocationText>logitude:{longitude.toFixed(5)}</LocationText>
                        <LocationText>altitude:{altitude}m</LocationText>
                    </DetectLocation>
                </ImageWrap>
            ) : null}
        </>
    );
}

export default DetectImage;