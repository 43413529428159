
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ItemContainer = styled.div`
  display: flex;
  margin-bottom: 13px;
`
const Item = styled.div`
  font-weight: 500;
  font-size: 16px;
  /* #525D59 */
  color: ${props=>props.isfocus?'#FFFFFF':'#BBC1BE'};
  border-left: 0.5px solid gray;
  padding: 0 13px;
  cursor: pointer;
  &:nth-child(1){
    border-left: none;
  }
`
const BodyTextContaiiner = styled.div`
  width: 100%;
  background: linear-gradient(180deg, rgba(16, 18, 23, 0.15) 0%, rgba(3, 6, 15, 0.25) 100%);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.29947);
  backdrop-filter: blur(30px);
  border-radius: 30px 30px 0px 0px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const BodyText = styled.div`
  width: 100%;
  display: flex;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #FFFFFF;
  /* box-sizing: border-box; */
  padding-left: 10%;
`
const BodtTextScore = styled.span`
  display: block;
  width: 40%;
  text-align: right;
  margin-right: 10px;
`
const BodtTextEx = styled.span`
  font-weight: 300;
`
function GuideText(){
  const [focusNum,setFocusNum] = useState(0);
  const titles = ['humidity','wind speed','slope'];
  const bodyTexts=[
    [
      {score : '40 points', ex: '0% ~ 20%'},
      {score : '20 points', ex: '20% ~ 40%'},
      {score : '0 points', ex: '40% ~ 60%'},
      {score : '-30 points', ex: '60% ~ 80%'},
      {score : '-50 points', ex: '80% ~ 100%'},
    ],
    [
      {score : '50 points', ex: '7.5m/s or more'},
      {score : '40 points', ex: '5m/s ~ 7.5m/s'},
      {score : '30 points', ex: '2.5m/s ~ 5m/s'},
      {score : '20 points', ex: '0m/s ~ 2.5m/s'},
    ],
    [
      {score : '20 points', ex: '20° or more'},
      {score : '10 points', ex: '10° ~ 20°'},
      {score : '0 points', ex: '0° ~ 10°'},
      {score : '-10 points', ex: '-10° ~ 0°'},
      {score : '-20 points', ex: '-20° ~ -10°'},
    ],
  ];
  return(
    <Container>
      <ItemContainer>
        {titles.map((title,idx)=>(
          <Item key={title} onClick={()=>{setFocusNum(idx)}} isfocus={focusNum==idx}>{title}</Item>
        ))}
      </ItemContainer>
      <BodyTextContaiiner>
        {bodyTexts[focusNum].map((bodyText)=>(
          <BodyText key={bodyText.score}>
            <BodtTextScore>{bodyText.score}</BodtTextScore>
            <BodtTextEx>{bodyText.ex}</BodtTextEx>
          </BodyText>
        ))}
      </BodyTextContaiiner>
    </Container>
  )
}

export default GuideText;