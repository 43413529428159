import React, {useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import BottomMenuModal from '../../../common/BottomMenuModal';
import CloseIcon from "../../../../gcs_image/common/close-icon.svg";

const S = {
    TitleWrap: styled.div`
        width: 100%;
        height: 52px;
        padding: 21px 15px;
        border-bottom: 1px solid #353535;
        display: flex;
        align-items: center;

        p {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
    `,
    ModalWrap: styled.div`
        display: flex;
        flex-direction: column;

        .tap-wrap {
            display: flex;
        }
    `,
    Tap: styled.div`
        width: 50%;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: ${props => props.selectedTap === props.tapName ? "1px solid #004be9" : ""};
        cursor: pointer;

        p {
            font-size: 12px;
            line-height: 16px;
            color: ${props => props.selectedTap === props.tapName ? "#004be9" : "#fff"};
        }
    `,
    ModalInputWrap: styled.div`
        padding: 18px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-row-wrap {
            display: flex;
            align-items: center;

            p {
                width: 120px;
                font-size: 12px;
                line-height: 18px;
                color: #b5b5b5;
            }

            .input-wrap {
                width: 120px;
                height: 31px;
            }

            select {
                cursor: pointer;
            }
        }
        .btn-wrap {
            display: flex;
            gap: 5px;
            margin-left: auto;
            margin-top: 2px;
        }
    `,
}

function ClimateModal({isModalOpen, setIsModalOpen}) {
    const [temperature, setTemperature] = useState(0);
    const [windSpeed, setWindSpeed] = useState(0);
    const [precipitation, setPrecipitation] = useState(0);

    const onClickCancel = useCallback(() => {
        setIsModalOpen({
            ...isModalOpen,
            climate: false,
        })
        init();
    }, [])
    
    const init = useCallback(() => {
        setTemperature(0);
        setWindSpeed(0);
        setPrecipitation(0);
    }, [])

    useEffect(() => {
        init();
        if (isModalOpen.climate) {
            // map.addEventListener 추가
        } else {
            // map.removeEventListener
        }
    }, [isModalOpen.climate])

    if (isModalOpen.climate) {
        return (
            <BottomMenuModal>
                <S.TitleWrap>
                    <p>CLIMATE</p>
                </S.TitleWrap>
                <S.ModalWrap>
                    <S.ModalInputWrap>
                        <div className="input-row-wrap">
                            <p>Temperatures</p>
                            <div className="input-wrap default-input">
                                <input type="text"
                                    value={temperature}
                                    onChange={e => setTemperature(e.target.value)}
                                    autoFocus />
                                {temperature !== 0 && (
                                    <img src={CloseIcon}
                                        width="24px"
                                        height="24px"
                                        alt="close-btn"
                                        onClick={() => setTemperature(0)} />
                                )}
                            </div>
                        </div>
                        <div className="input-row-wrap">
                            <p>Wind speed</p>
                            <div className="input-wrap default-input">
                                <input type="text"
                                    value={windSpeed}
                                    onChange={e => setWindSpeed(e.target.value)} />
                                {windSpeed !== 0 && (
                                    <img src={CloseIcon}
                                        width="24px"
                                        height="24px"
                                        alt="close-btn"
                                        onClick={() => setWindSpeed(0)} />
                                )}
                            </div>
                        </div>
                        <div className="input-row-wrap">
                            <p>Precipitation</p>
                            <div className="input-wrap default-input">
                                <input type="text"
                                    value={precipitation}
                                    onChange={e => setPrecipitation(e.target.value)} />
                                {precipitation !== 0 && (
                                    <img src={CloseIcon}
                                        width="24px"
                                        height="24px"
                                        alt="close-btn"
                                        onClick={() => setPrecipitation(0)} />
                                )}
                            </div>
                        </div>

                        <div className="btn-wrap">
                            <div className="cancel-btn" onClick={onClickCancel}>
                                <p>Cancel</p>
                            </div>
                            <div className="save-btn">
                                <p>Save</p>
                            </div>
                        </div>
                    </S.ModalInputWrap>
                </S.ModalWrap>
            
            </BottomMenuModal>
        );
    }
}

export default ClimateModal;