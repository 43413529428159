const SET_SAFETY_MARGIN = 'droneSetting/SET_SAFETY_MARGIN'
const SET_START_HEIGHT = 'droneSetting/UPDATE_DATA'
const SET_SPEED = 'droneSetting/SET_SPEED)'
const SET_DELIVERY_STATION = 'droneSetting/DELIVREY_STATION';

export const setSafetyMargin = distance =>({type:SET_SAFETY_MARGIN, distance});
export const setStartHeight = height => ({ type: SET_START_HEIGHT, height });
export const setSpeed = speed => ({ type: SET_SPEED, speed });

const initialState={
  safetyMargin: 50,
  startHeight: 10,
  speed:3,
}

export default function drone(state=initialState, action){
  switch(action.type){
    case SET_SAFETY_MARGIN:
      return{
        ...state,
        safetyMargin : action.distance
      }
    case SET_START_HEIGHT:
      return{
        ...state,
        startHeight : action.height
      }
    case SET_SPEED:
      return{
        ...state,
        speed : action.speed
      }
    default:
      return state;
  }

}