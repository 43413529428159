const SET_VWORLD = 'map/SET_VWORLD';
const SET_FULLSCREEN = 'map/SET_FULLSCREEN';
const SET_MAP_CLICKED = 'map/SET_MAP_CLICKED';

export const set_vworld = (vworld,map) => ({type:SET_VWORLD, vworld, map});
export const set_fullscreen = bool => ({ type: SET_FULLSCREEN, bool })
export const set_map_clicked = bool => ({ type: SET_MAP_CLICKED, bool });

const initialState = {
  vw : null,
  map : null,
  isFullScreen: false,
  mapClicked: false,
}

export default function map(state = initialState, action){
  switch (action.type){
    case SET_VWORLD:
      return{
        ...state,
        vw : action.vworld,
        map : action.map
      }
    case SET_FULLSCREEN:
      return{
        ...state,
        isFullScreen : action.bool
      }
    case SET_MAP_CLICKED:
      return {
        ...state,
        mapClicked: action.bool,
      }
    default:
      return state;
  }
}