import React, {useEffect, useCallback, useState} from 'react';
import styled from 'styled-components';
import OrderInfo from './order-info/OrderInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryStation, setWebsocket } from '../../store/reducer/skycook';
import { getOrderListAPI, updateDeliveryStationAPI } from '../../api/skycook_apis';
import { setOrderList } from '../../store/reducer/skycook';
import chimeBellFile from "../../audio/chime-bell.wav";

const S = {
    Wrap: styled.div`
        top: 72px;
        right: 30px;
        /* max-height: 523px; */
        max-height: ${props => props.screen_size}px;
        overflow: scroll !important;
        position: fixed;
        z-index: 99;
        display: flex;
        flex-direction: column;
        gap: 7px;
    `,
}

function OrderListView({ selectedDroneSN, selectedOrderList, setSelectedOrderList }) {
    const [chimeBell] = useState(new Audio(chimeBellFile));
    const { deliveryStation, orderList, deliveryStatus } = useSelector((state) => ({
        deliveryStation: state.skycook.deliveryStation,
        orderList: state.skycook.orderList,
        deliveryStatus: state.skycook.delivery_status,
    }))
    // const [orderList, setOrderList] = useState([]);
    const dispatch = useDispatch();
    const screen_size = window.innerHeight;

    useEffect(() => {
        if (deliveryStation !== "") {
            // 배송사업자 station 변경 api
            updateDeliveryStation();
        }
    }, [deliveryStation])

    const updateDeliveryStation = useCallback(async () => {
        try {
            const data = {
                drone_station_id: deliveryStation
            }
            const updateRes = await updateDeliveryStationAPI(data);

            if (updateRes.status !== 200) {
                throw new Error();
            }
        } catch (e) {
            console.log(e);
            dispatch(setDeliveryStation(""));
            return;
        }

        // 웹소켓 연결
        // let socket = new WebSocket(`ws://${process.env.REACT_APP_PUBLIC_END_POINT_IP}/ws/delivery/request/${deliveryStation}/`);

        // socket.onerror = (error) => {
        //     socket.isConnect = false;
        // };
        // socket.onopen = () => {
        //     socket.isConnect = true;
        //     dispatch(setWebsocket(socket));
        //     if (deliveryStatus) {
        //         getOrderList();
        //     }
        // };
        // socket.onclose = () => {
        //     socket.isConnect = false;
        // };
        // socket.onmessage = async (event) => {
        //     if (deliveryStatus) {
        //         getOrderList();
        //     }
        // }
    }, [deliveryStation, deliveryStatus])

    // const getOrderList = useCallback(async () => {
    //     try {
    //         const getRes = await getOrderListAPI();
    //         if (getRes.status !== 200) {
    //             throw new Error();
    //         } else {
    //             let newOrderDetected = false;
                
            //     if (orderList.length === 0 && getRes.data.length > 0) {
            //         newOrderDetected = true;
            //     } else {
            //         getRes.data.map((new_order) => {
            //             if (!newOrderDetected) {
            //                 orderList.map((prev_order, index) => {
            //                     if (prev_order.order_number === new_order.order_number) {
            //                         return;
            //                     }
            //                     if (index === prev_order.length - 1) {
            //                         newOrderDetected = true;
            //                         return;
            //                     }
            //                 })
            //             }
            //         })
            //     }
            //     if (newOrderDetected) {
            //         chimeBell.play();
            //     }

            //     dispatch(setOrderList(getRes.data));
            // }
    //         }

    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, [orderList])

    return (
        <S.Wrap screen_size={screen_size - 423 > 523 ? 523 : screen_size - 423}>
            {orderList.map((order, index) => {
                return (<OrderInfo
                    key={`order${order.order_number}`}
                    order={order}
                    selectedDroneSN={selectedDroneSN}
                    selectedOrderList={selectedOrderList}
                    setSelectedOrderList={setSelectedOrderList}
                />)
            })}
            {/* <OrderInfo orderName={"ABCDEFG1"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
            <OrderInfo orderName={"ABCDEFG2"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
            <OrderInfo orderName={"ABCDEFG3"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
            <OrderInfo orderName={"ABCDEFG4"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
            <OrderInfo orderName={"ABCDEFG5"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
            <OrderInfo orderName={"ABCDEFG6"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} />
            <OrderInfo orderName={"ABCDEFG7"} selectedDroneSN={selectedDroneSN} selectedOrderList={selectedOrderList} setSelectedOrderList={setSelectedOrderList} /> */}
        </S.Wrap>
    );
}

export default OrderListView;