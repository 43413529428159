import React, { useState } from "react";
import styled from "styled-components";
import Login from "../components/main/Login";
import GCS from "../components/main/Gcs";

const S = {
    Wrap: styled.div``,
};

function Main(props) {
    const [isLogin, setIsLogin] = useState(false);

    return <S.Wrap>
        {!isLogin ? <Login setIsLogin={setIsLogin} /> : <GCS />}
    </S.Wrap>;
}

export default Main;
