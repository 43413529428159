import percent0 from "../../../gcs_image/weather/rain_percentage/0per.svg";
import percent10 from "../../../gcs_image/weather/rain_percentage/10per.svg";
import percent20 from "../../../gcs_image/weather/rain_percentage/20per.svg";
import percent30 from "../../../gcs_image/weather/rain_percentage/30per.svg";
import percent40 from "../../../gcs_image/weather/rain_percentage/40per.svg";
import percent50 from "../../../gcs_image/weather/rain_percentage/50per.svg";
import percent60 from "../../../gcs_image/weather/rain_percentage/60per.svg";
import percent70 from "../../../gcs_image/weather/rain_percentage/70per.svg";
import percent80 from "../../../gcs_image/weather/rain_percentage/80per.svg";
import percent90 from "../../../gcs_image/weather/rain_percentage/90per.svg";
import percent100 from "../../../gcs_image/weather/rain_percentage/100per.svg";

import { useEffect, useState } from "react";
import styled from "styled-components";

const Text = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #FFFFFF;
`
const ItemContainer = styled.div`
  display :flex;
`
const Item = styled.div`
  border-left: 0.2px solid #667069;
  flex-basis: 100%;
  text-align: center;
  &:nth-child(1){
    border-left: none;
  }
`
const PercentImage = styled.img`

`
function PrecipitationData({data}){
  const [infos,setInfos] = useState([
    {percent:0, img:percent0, precipitation:0, time:'00'},
    {percent:0, img:percent0, precipitation:0, time:'00'},
    {percent:0, img:percent0, precipitation:0, time:'00'},
    {percent:0, img:percent0, precipitation:0, time:'00'},
    {percent:0, img:percent0, precipitation:0, time:'00'},
    {percent:0, img:percent0, precipitation:0, time:'00'},
  ]);
  useEffect(()=>{
    let tempInfo = data.map((info)=>{
      const percentImg = [percent0,percent10,percent20,percent30,percent40,percent50,percent60,percent70,percent80,percent90,percent100];
      let per = parseInt(Number(info.POP)/10);
      if(per===NaN) per = 0;
      let precData = info.PCP;
      let prec;
      if (precData === '강수없음') prec=0;
      else prec = parseFloat(info.PCP.match(/[0-9.]+/)[0]); 
      return {percent:info.POP, img:percentImg[per], precipitation:prec, time:info.fcstTime.substring(0,2)};
    });
    setInfos(tempInfo);
  },[data]);
  return(
    <>
      <ItemContainer>
      {infos.map((info,idx)=>(
        <Item key={idx}>
          <PercentImage src={info.img}/>
          <Text>{info.percent}%</Text>
          <Text isWeight={true}>{info.precipitation}</Text>
          <Text key={idx}>{info.time}:00</Text>
        </Item>
      ))}
      </ItemContainer>

    </>
      
  )
}
export default PrecipitationData;

{/* {infos.map((info,idx)=>(
  <InfoItem key={idx}>
    <PercentImageBox>
      <PercentImage src={info.img}/>
    </PercentImageBox>
    <Text>{info.percent}%</Text>
  </InfoItem>
))}
<InfoContainer marginTop={33} marginBottom={22}>
{infos.map((info,idx)=>(
  <InfoItem key={idx}>
    <PrecipitationImageBox isZero={info.precipitation===0}>
      <Text isWeight={true}>{info.precipitation}</Text>
    </PrecipitationImageBox>
  </InfoItem>
))}
</InfoContainer> */}