const SET_SOCKET = 'drone/SET_SOCKET'
const SET_STATUS = 'drone/SET_STATUS'
const SET_FLIGTMODE = 'drone/SET_FLIGTMODE'
const SET_MESSAGE = 'drone/SET_MESSAGE'
const SEND_MSG = 'drone/SEND_MSG'
const UPDATE_DATA = 'drone/UPDATE_DATA'
const SET_FOLLOW_VIEW = 'drone/FOLLOW_VIEW '
// const SET_SAVED_DRONE = 'drone/SAVED_DRONE'

export const setSocket = socket =>({type:SET_SOCKET, socket});
export const setStatus = status =>({type:SET_STATUS, status});
export const setFlightMode = flightMode =>({type:SET_FLIGTMODE, flightMode});
export const setMessage = message =>({type:SET_MESSAGE, message});
export const sendMsg = msg =>({type:SEND_MSG, msg});
export const updateData = msg => ({ type: UPDATE_DATA });
export const setFollowView = bool =>({type:SET_FOLLOW_VIEW, bool})

// export const setSavedDrone = msg => ({ type: SET_SAVED_DRONE, msg });

const initialState={
  socket:'',
  updateSwitch:false,
  flightMode:'STABILIZE',
  message:[''],
  droneStatus:{
    satellite:'',
    voltage: '',
    yaw:'',
    airSpeed:'',
    altitude:'',
  },
  savedDrone: '',
  follow_view: false,
}

export default function drone(state=initialState, action){
  switch(action.type){
    case SET_SOCKET:
      return{
        ...state,
        socket : action.socket
      }
    case SET_STATUS:
      return{
        ...state,
        droneStatus : action.status
      }
    case SET_FLIGTMODE:
      return{
        ...state,
        flightMode : action.flightMode
      }
    case SET_MESSAGE:
      let reverse = action.message.slice(0).reverse();
      return{
        ...state,
        message : reverse
      }
    case SEND_MSG:
      state.socket.send(action.msg);
      return{
        ...state,
      }
    case UPDATE_DATA:
      return{
        ...state,
        updateSwitch:!state.updateSwitch,
      }
    case SET_FOLLOW_VIEW:
      return{
        ...state,
        follow_view:action.bool
      }
    // case SET_SAVED_DRONE:
    //   return {
    //     ...state,
    //     savedDrone: action.savedDrone,
    //   }
    default:
      return state;
  }

}