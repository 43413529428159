const SET_SELECTMODENUM = 'video/SET_SELECTMODENUM';
const SET_IS_ACTIVE_AI = 'video/SET_IS_ACTIVE_AI';
const SET_IS_DRONE_DETECT = 'video/SET_IS_DRONE_DETECT';
export const set_selectModeNum = num =>({type:SET_SELECTMODENUM, num})
export const set_is_active_ai = bool =>({type:SET_IS_ACTIVE_AI, bool})
export const set_is_drone_detect = bool =>({type:SET_IS_DRONE_DETECT, bool})
const initialState = {
  selectModeNum: 0,
  is_active_ai: false,
  is_drone_detect: false,
}


export default function video(state = initialState, action){
  switch (action.type){
    case SET_SELECTMODENUM:
      return{
        ...state,
        selectModeNum:action.num
      }
    case SET_IS_ACTIVE_AI:
      return{
        ...state,
        is_active_ai:action.bool
      }
    case SET_IS_DRONE_DETECT:
      return{
        ...state,
        is_drone_detect:action.bool
      }
    default:
      return state;
  }
}