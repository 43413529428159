import React, {useMemo, useEffect, useCallback} from 'react';
import styled, { keyframes } from 'styled-components';
import StationDestinationInfo from './packaged/StationDestinationInfo';
import DronePackageInfo from './packaged/DronePackageInfo';
import DroneStatusInfo from './common/DroneStatusInfo';
import DroneActionBtn from "./common/DroneActionBtn";
import { useDispatch, useSelector } from 'react-redux';
import { changeOrderStatusAPI, setDeliveryLogAPI } from '../../../api/skycook_apis';
import { setLogTimer } from '../../../store/reducer/skycook';

const A = {
    fadeIn: keyframes`
        from {
            opacity: 0;
            scale: 0.8;
            /* transform: translateY(-5%); */
        } to {
            opacity: 1;
            scale: 1;
            /* transform: translateY(0%); */
        }
    `,
}

const S = {
    Wrap: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 13px;
        animation: ${A.fadeIn} 0.3s linear;

        .divide-line {
            width: 100%;
            height: 1px;
            background-color: #353535;
            margin: 15px 0;
        }
    `,
}

function DronePackaged({ drone, selectedDroneSN, setSelectedDroneSN, droneSN }) {
    const { socket, logTimer, delivery_status } = useSelector((state) => ({
        socket: state.drone.socket,
        logTimer: state.skycook.logTimer,
        delivery_status: state.skycook.delivery_status,
    }))
    const dispatch = useDispatch();

    const deliveryPackage = useMemo(() => {
        if (!!socket) {
            return socket.getDronePackage(drone.drone_serial_number);
        }
    }, [drone])

    useEffect(() => {
        setTimeout(() => {
            setDeliveryLog();
        }, 10000)
    }, [logTimer])

    const setDeliveryLog = useCallback(async () => {
        if (!!socket.getDronePackage(droneSN) && !socket.getDronePackage(droneSN).is_delivery_complete && delivery_status === true) {
            const data = {
                order_number_list: socket.getOrderNumberListInPackage(droneSN),
                latlng: `${drone.dronePosition.latitude},${drone.dronePosition.longitude}`,
                air_speed: drone.droneStatus.airSpeed.toFixed(1),
                altitude: drone.dronePosition.altitude,
            }
            const res = await setDeliveryLogAPI(data);

            if (res.status !== 200) {
                throw new Error();
            }
            dispatch(setLogTimer(!logTimer))
        }
    }, [logTimer, socket, drone, delivery_status])

    useEffect(() => {
        socket.message.map((msg) => {
            if (msg.drone_serial_num === droneSN && msg.message === "Aircraft has arrived at its destination") {
                // 도착 시 주문상태 변경
                if (delivery_status === true) {
                    changeOrderStatus();
                } else {
                    socket.setPackageDeliveryComplete(droneSN, true);
                }
                // clearInterval(logInterval);
                return;
            }
        })
    }, [socket.message, delivery_status])
    
    const changeOrderStatus = useCallback(async () => {
        try {
            const data = {
                order_number_list: socket.getOrderNumberListInPackage(droneSN),
                status: "done"
            }
            const changeRes = await changeOrderStatusAPI(data);

            if (changeRes.status !== 200) {
                throw new Error();
            } else {
                // package에 is_delivery_complete true 변경
                socket.setPackageDeliveryComplete(droneSN, true);
            }
        } catch (e) {
            console.log(e)
        }
    }, [socket, droneSN])
    
    return (
        <S.Wrap>
            <StationDestinationInfo deliveryPackage={deliveryPackage}/>
            <div className="divide-line" />
            <DronePackageInfo deliveryPackage={deliveryPackage} />
            <div className="divide-line" />
            <DroneStatusInfo drone={drone} />
            <DroneActionBtn
                droneFlightStatus={drone.droneStatus.flightStatus}
                selectedDroneSN={selectedDroneSN}
                droneMode={drone.droneStatus.mode}
                droneSN={droneSN} />
        </S.Wrap>
    );
}

export default DronePackaged;