import React from 'react';
import styled from 'styled-components';
import DroneActionBtn from '../common/DroneActionBtn';
import DroneStatusInfo from '../common/DroneStatusInfo';

const S = {
    Wrap: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 13px;

        .divide-line {
            width: 100%;
            height: 1px;
            background-color: #353535;
            margin: 13px 0;
        }
    `,
    NotPackagingTextWrap: styled.div`
        display: flex;
        
        .not-package-title-wrap {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title-text {
                font-size: 16px;
                line-height: 16px;
                font-weight: 500;
                color: #fff;
            }
            .sub-title-text {
                font-size: 10px;
                line-height: 16px;
                font-weight: 500;
                color: #fff;
            }
        }
        .weight-wrap {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 3px;
            
            .weight-text {
                font-size: 7px;
                line-height: 16px;
                font-weight: 300;
                zoom: 0.7;
                color: #898989;
            }
            .weight-value {
                font-size: 11px;
                line-height: 16px;
                font-weight: 300;
                color: #fff;
            }
        }
    `,
}

function DroneNotPackageInfo({ drone, selectedDroneSN, setSelectedDroneSN, droneSN }) {
    return (
        <S.Wrap>
            <S.NotPackagingTextWrap>
                <div className="not-package-title-wrap">
                    <div className="title-text">Waiting for packaging...</div>
                    <div className="sub-title-text">Drag and drop the order</div>
                </div>
                <div className="weight-wrap">
                    <div className="weight-text">Weight</div>
                    <div className="weight-value">- Kg</div>
                </div>
            </S.NotPackagingTextWrap>
            <div className="divide-line" />

            <DroneStatusInfo drone={drone} />
            <DroneActionBtn
                droneFlightStatus={drone.droneStatus.flightStatus}
                selectedDroneSN={selectedDroneSN}
                droneMode={drone.droneStatus.mode}
                droneSN={droneSN} />
        </S.Wrap>
    );
}

export default DroneNotPackageInfo;