import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as FireReportIcon } from "../../../gcs_image/detect_fire_warning/fire-report.svg";
import { ReactComponent as LocationIcon } from "../../../gcs_image/common/location.svg";

import { useSelector } from "react-redux";

import { createVwMarker2DLabel } from '../../Left/Mission/MissionFunctions';
import reporterPositionMaker from "../../../gcs_image/marker/reporter-position.svg";

const S = {
    Wrap: styled.div`
        width: 383px;
    `,

    TitleWrap: styled.div`
        display: flex;
        width: 383px;
        height: 52px;
        border: 1px solid #000;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;

        .title-text-wrap {
            display: flex;
            gap: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #fff;
        }

        .time-wrap {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #B5FF15;
        }
    `,

    InfoWrap: styled.div`
        display: flex;
        width: 383px;
        height: 386px;
        padding: 20px 20px;
        flex-direction: column;
        gap:16px;
    `,

    ReportStatus: styled.div`
        width: 343px;
        height: 28px;
        display: flex;
        padding: 20px 0px;
        align-items: center;
     
        .line {
            position: absolute;
            width: 192px;
            height: 0;
            border: 1px solid rgba(255, 255, 255, 0.3);
            left: 72px;
            top: 90px;
            z-index: 0;
        }
    `,

    ReportWrap: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 7px;
        margin: 0 auto;
        width: 109px;
        height: 28px;
     
        border-radius: 50px;
        z-index: 99;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        ${({ status }) => {
            switch (status) {
                case 1:
                    return css`
                        background: #4D2929;
                        border: 1px solid #FA605F;
                        color: #FB6161;
                    `;
                default:
                    return css`
                        background: #3D3D3D;
                        border: none;
                        color: #979797;
                    `;
            }
        }}
    `,
    
    Circle: styled.div`
        width: 6px;
        height: 6px;
        background: #EA5350;
        border-radius: 50%;
    `,
    
    ReporterInfo : styled.div`
        width: 343px;
        height: 100%;
        gap:12px;
        display: flex;
        flex-direction: column;

        .reporter-title {
            color: rgba(255, 255, 255, 0.7);
            font-weight: 400;
            font-size: 9px;
            line-height: 16px;
        }
    `,

    ReporterDetail : styled.div`
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;

        .detail-info {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
        }
    `,

    ReporterContents : styled.div`
        display: flex;
        flex-direction: column;
        width: 343px;
        height: 171px;
        gap: 16px;

        .content-text {
            width: 343px;
            height: 91px;

            background: #3F3F3F;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);
            padding: 16px 16px;
        }

        .image-wrap {
            display: flex;
            justify-content: space-between;
        }

        .image-list {
            display: flex;
            width: 286px;
            height: 64px;
            gap: 10px;
        }

        .view-all {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            padding:10px 5px;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.6);
            white-space: pre-line; /* 공백과 줄바꿈 문자 보존 및 자동 줄바꿈 허용 */
            width: 41px;
            height: 64px;
    }
    `,

    ReportImage : styled.img`
        width: 64px;
        height: 64px;
        border: 1px solid #6C6C6C;
        border-radius: 3px;
    `,

    RealTimePosition: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 343px;
        height: 35px;

        background: #2C2C2C;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 2px 0px 0px 2px;
        
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        color: #FFFFFF;
        padding: 10px;
        gap:12px;
        cursor: pointer;
    `,
    
};

function FireReportInfo({setIsModalOpen}) {
    const { vw, map, socket, forest_fire_report } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
        forest_fire_report: state.skycook.forest_fire_report,
    }));

    const timeAgo = (created_at) => {
        const now = new Date();
        const timeDifference = now - new Date(created_at); // 밀리초 단위의 차이
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
        if (minutes < 1) {
          return "방금 전";
        } else if (minutes < 60) {
          return `${minutes}분 전`;
        } else if (hours < 24) {
          return `${hours}시간 전`;
        } else {
          return `${days}일 전`;
        }
    }

    const onClickRealPosition = () => {
        let position = forest_fire_report.position.split(",");
        let latitude = parseFloat(position[0]);
        let longitude = parseFloat(position[1]);
    
        const movePosition = new vw.CoordZ(longitude, latitude, 800);
        const cameraPosition = new vw.CameraPosition(movePosition, new vw.Direction(0, -90, 0));
        
        map.removeObjectById(`reporterPositionMaker`);
    
        // 출발지 도착지 마커 표시
        const reporter_position = {
            latitude: latitude,
            longitude: longitude,
        }
        socket.setClickRealTimePostition(true);
        let point = createVwMarker2DLabel(vw, `reporterPositionMaker`, reporterPositionMaker, reporter_position, "");

        const markerclick = function (windowPosition, ecefPosition, cartographic, featureInfo) {
            if (featureInfo != null) {
                setIsModalOpen(true);
            }
        };

        point.addEventListener(markerclick);
        map.moveTo(cameraPosition);
    }
    const formatCoordinates  = (positionData) => {
        let position = positionData.split(",");
        let latitude = parseFloat(position[0]);
        let longitude = parseFloat(position[1]);

        let formattedLatitude = latitude.toFixed(5);
        let formattedLongitude = longitude.toFixed(5);
        
        return `${formattedLatitude}, ${formattedLongitude}`;
    }
    return (
        <S.Wrap>
            <S.TitleWrap>
                <div className='title-text-wrap'>
                    <FireReportIcon />
                    {forest_fire_report.title}
                </div>
                <div className='time-wrap'>
                    {timeAgo(forest_fire_report.created_at)}
                </div>
            </S.TitleWrap>
            <S.InfoWrap>
                <S.ReportStatus>
                    <div className='line' />
                    <S.ReportWrap status={forest_fire_report.first_status_active}>
                        {forest_fire_report.first_status_active === 1 && <S.Circle />}
                        {"상황 발생"}
                    </S.ReportWrap>
                    <S.ReportWrap status={forest_fire_report.second_status_active}>
                        {forest_fire_report.second_status_active === 1 && <S.Circle />}
                        {"수습중"}
                    </S.ReportWrap>
                    <S.ReportWrap status={forest_fire_report.third_status_active}>
                        {forest_fire_report.third_status_active === 1 && <S.Circle />}
                        {"상황 종료"}
                    </S.ReportWrap>
                </S.ReportStatus>
                <S.ReporterInfo>
                    <div className='reporter-title'>
                        {"신고자"}
                    </div>
                    <S.ReporterDetail style={{marginBottom:"4px"}}>
                        {forest_fire_report.reporter_name}
                        <div className='detail-info'>
                            {forest_fire_report.phone_number}
                        </div>
                    </S.ReporterDetail>
                    <S.ReporterDetail>
                        {"신고자 위치"}
                        <div className='detail-info'>
                            {formatCoordinates(forest_fire_report.position)}
                        </div>
                    </S.ReporterDetail>
                </S.ReporterInfo>
                <S.ReporterContents>
                    <div className='content-text'>
                        {forest_fire_report.content_text}
                    </div>
                    <div className='image-wrap'>
                        <div className='image-list'>
                            {forest_fire_report.images.slice(0, 4).map((imageData, index) => (
                                <S.ReportImage key={imageData.id} src={imageData.image} alt={`Image ${index + 1}`} />
                            ))}
                        </div>
                        <div className='view-all' onClick={() => setIsModalOpen(true)}>
                                {'전체 보기'}
                        </div>
                    </div>
                </S.ReporterContents>
                <S.RealTimePosition onClick={() => onClickRealPosition()}>
                    <LocationIcon />   
                    {"실시간 위치 확인"}         
                </S.RealTimePosition>
            </S.InfoWrap>
        </S.Wrap>
    );
}

export default FireReportInfo;