import React from 'react';
import styled from 'styled-components';

const S = {
    Wrap: styled.div`
        display: flex;
    `,
    PackageWrap: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .package-text {
            font-size: 9px;
            zoom: 0.9;
            line-height: 16px;
            color: #fff;
        }

        .package-row-wrap {
            display: flex;
        }
    `,
    PackageListWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 2px;
    `,
    PackageItemInfoWrap: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;

        .item-name {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
        .order-number {
            font-size: 9px;
            zoom: 0.9;
            line-height: 16px;
            color: #79aaf2;
        }
    `,
    WeightWrap: styled.div`
        display: flex;
        flex-direction: column;
        
        margin-top: auto;
        margin-left: auto;
        
        .weight-text {
            font-size: 7px;
            zoom: 0.7;
            line-height: 16px;
            font-weight: 300;
            color: #898989;
        }
        .weight-value {
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
            color: #fff;
        }
    `,
}

function DronePackageInfo({deliveryPackage}) {
    return (
        <S.Wrap>
            <S.PackageWrap>
                <div className="package-text">Package</div>
                <div className="package-row-wrap">
                    <S.PackageListWrap>
                        {deliveryPackage.orderList.map((order, index) => {
                            return (
                                <S.PackageItemInfoWrap key={`packageOrder${index}`}>
                                    <p className="item-name">{order.order_product}</p>
                                    <p className="order-number">{order.order_number}</p>
                                </S.PackageItemInfoWrap>
                            )
                        })}
                </S.PackageListWrap>
                <S.WeightWrap>
                    <div className="weight-text">Weight</div>
                    <div className="weight-value">{deliveryPackage.totalWeight} Kg</div>
                </S.WeightWrap>
                </div>
            </S.PackageWrap>
        </S.Wrap>
    );
}

export default DronePackageInfo;