import { useEffect, useState } from "react";
import styled from "styled-components";
import { GetWeatherAPI } from "../../../api/weather_apis";
import BaseInfo from "./BaseInfo";
import WindInfo from "./WindInfo";
import PrecipitationInfo from "./PrecipitationInfo";
import { ReactComponent as BtnFordIconSvg } from "../../../gcs_image/common/btn-fold.svg"
import { ReactComponent as BtnUnFordIconSvg } from "../../../gcs_image/common/btn-unfold.svg"
import { useSelector } from "react-redux";
const Container = styled.div`
  background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.6);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  z-index: 99;
`

const WeatherContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:32px;
  padding: 44px 40px;
`

const StyledBtnFordIcon = styled(BtnFordIconSvg)`
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const StyledBtnUnFordIcon = styled(BtnUnFordIconSvg)`
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

function Weather({ data, setData, isfold, step }) {
  const [getSwitch, setGetSwitch] = useState(true);

  const {
    fire_report_warning,
    accident_report_warning,
  } = useSelector((state) => ({
    fire_report_warning: state.skycook.fire_report_warning,
    accident_report_warning: state.skycook.accident_report_warning,
  }));

  useEffect(() => {
    const getWeather = async () => {
      let weather = await GetWeatherAPI();
      setData(weather);
    };
    getWeather();
  }, [getSwitch]);

  useEffect(() => {
    if (accident_report_warning === false && fire_report_warning === false) {
      setGetSwitch(isfold)
    } else {
      setGetSwitch(true)
    }

    if (step > 0) {
      setGetSwitch(false)
    }

  }, [isfold, fire_report_warning, accident_report_warning, step]);

  // 아이콘 클릭 핸들러
  const handleIconClick = () => {
    setGetSwitch(!getSwitch);
  };

  return (
    <Container>
      {getSwitch === true ? (
        <StyledBtnFordIcon onClick={handleIconClick} />
      ) : (
        <StyledBtnUnFordIcon onClick={handleIconClick} />
      )}
      <WeatherContainer>
        <BaseInfo data={data[0]} getSwitch={getSwitch} setGetSwitch={setGetSwitch} />
        {getSwitch === true && 
          <>
            <WindInfo data={data} />
            <PrecipitationInfo data={data} /> 
          </>       
        }

      </WeatherContainer>
    </Container>
  );
}

export default Weather;