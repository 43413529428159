import styled from "styled-components";
import StepGraph from "./StepGraph";
import { useState } from "react";
import PredictionGuide from "./PredictionGuide";

const Container = styled.div`
  background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
  backdrop-filter: blur(30px);
  border-radius: 10px;
  padding: 23px 0px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`
const ItemContainer = styled.div`
  display: flex;
  margin-bottom: 13px;
  
`
const Item = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 22px;
  box-sizing: border-box;
  padding-bottom: 7px;
  border-bottom: ${props=>props.isfocue?'0.8px solid #0C52E5':'0.2px solid rgba(255, 255, 255, 0.25)'};
  color: ${props=>props.isfocue?'#FFFFFF':'#BBC1BE'};
`
const Blank = styled.div`
  width: 20px;
  border-bottom: 0.2px solid rgba(255, 255, 255, 0.25);
`
const StepTitle = styled.span`
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;
  margin-bottom: 15px;
`
const StepText = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  margin-bottom: 24px;
`
function PredictionConsole({step,weight,setWeight}){

  const stepTexts = ['Please draw the forest fire area','Please enter the forest fire weight score','Predict forest fires by time zone']
  const [isOpenGuide,setIsOpenGiude] = useState(false);
  return(
    <Container>
      {step===2?
        <ItemContainer>
          <Item onClick={()=>{setIsOpenGiude(false)}} isfocue={!isOpenGuide}>
            Step 2
          </Item>
          <Blank />
          <Item onClick={()=>{setIsOpenGiude(true)}} isfocue={isOpenGuide}>
            Guide
          </Item>
        </ItemContainer>
      :<StepTitle>{step===1?'Step 1':'Success'}</StepTitle>}
      {step===2&&isOpenGuide?null:
        <>
          <StepText>{stepTexts[step-1]}</StepText>
          <StepGraph step={(step/3)*100} color={'#0D53E7'} width={80}/>
        </>
      }
      {step===2?
        <PredictionGuide isOpenGuide={isOpenGuide} weight={weight} setWeight={setWeight}/>:null}
    </Container>
  )
}

export default PredictionConsole;