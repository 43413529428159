import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 58%;
  background: ${props=>props.isActive?'#00E0FF':'rgba(0, 0, 0, 0.35)'};
  border-radius: 99px;
  margin-top: 3px;
  padding: 1px 2px;
  box-sizing: border-box;
  cursor: pointer;
`
const Item = styled.div`
  width: 50%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.16);
  margin-left: ${props=>props.isActive?'50%':'0'};
  transition: 0.5s;
`

function Toggle(props){
 
  return(
    <Container isActive={props.isActive} onClick={()=>{props.setIsActive()}}>
      <Item isActive={props.isActive}/>
    </Container>
  )
}

export default Toggle;