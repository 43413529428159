import styled from "styled-components";
import TempImg from "../../../gcs_image/weather/tempImg.svg"
import HumidityImg from "../../../gcs_image/weather/humidityImg.svg"

const Container = styled.div`
`
const ItemContainer = styled.div`
  display: flex;
  /* align-items: center; */
  gap:47px;
  justify-content: center;
  text-align: center;
  width:304px;
`
const Item = styled.div`

`
const Info = styled.div`
`
const InfoImage = styled.img`
`
const InfoValue = styled.span`
  font-weight: 500;
  font-size: 50px;
  color: #FFFFFF;
  margin-left:5px;
`
const InfoUnit = styled.span`
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
`
const Title = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: #BBC1BE;
`
function BaseInfo({data,getSwitch,setGetSwitch}){

  return(
    <Container onClick={()=>{setGetSwitch(!getSwitch)}}>
      <ItemContainer>
        <Item>
          <Info>
            <InfoImage src={TempImg}/>
            <InfoValue>{data?data.TMP:0}</InfoValue>
            <InfoUnit>℃</InfoUnit>
          </Info>
          <Title>Temperature</Title>
        </Item>
        <Item>
          <Info>
            <InfoImage src={HumidityImg}/>
            <InfoValue>{data?data.REH:0}</InfoValue>
            <InfoUnit>%</InfoUnit>
          </Info>
          <Title>Air humidity</Title>
        </Item>
      </ItemContainer>
    </Container>
  );
}

export default BaseInfo;