const SET_DRAG_ORDER = 'map/SET_DRAG_ORDER';
const SET_DRAG_DRONE = 'map/SET_DRAG_DRONE';

export const setDragOrder = (orderName) => ({type:SET_DRAG_ORDER, orderName});
export const setDragDrone = (droneName) => ({ type: SET_DRAG_DRONE, droneName });

const initialState = {
  dragOrder : null,
  dragDrone : null,
}

export default function drag(state = initialState, action){
  switch (action.type){
    case SET_DRAG_ORDER:
      return{
        ...state,
        dragOrder : action.orderName,
      }
    case SET_DRAG_DRONE:
      return{
        ...state,
        dragDrone : action.droneName,
      }
    default:
      return state;
  }
}